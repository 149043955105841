@import "../../shared/styles/index";

.mainContainer {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: $color-light-grey;
}

.middleContainer {
  display: flex;
  width: 100%;
  height: calc(100% - 190px);
  font-family: $ff-robo;
}

.mobileContainer {
  display: none;
  @include mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 12px;
    padding-left: 12px;
  }
}

.mobileTextContainer {
  display: flex;
}

.mobileSidePanelButton {
  margin-top: 5px;
  outline: none;
  user-select: none;
  border: none;
  background-image: url("/assets/images/arrow.png");
  width: 45px;
  height: 45px;
  background-size: contain;
  background-repeat: no-repeat;
}
