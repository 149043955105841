@import "https://fonts.googleapis.com/css?family=Roboto:100,400,300,300italic,400italic,500,700|Indie+Flower:400|";

.withripple {
  position: relative;
}
.ripple-container {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: inherit;
  pointer-events: none;
}
.ripple {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -10px;
  margin-top: -10px;
  border-radius: 100%;
  background-color: #000;
  background-color: rgba(0, 0, 0, 0.05);
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transform-origin: 50%;
  transform-origin: 50%;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  pointer-events: none;
}
.ripple.ripple-on {
  transition: opacity 0.15s ease-in 0s,
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: opacity 0.15s ease-in 0s,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  transition: opacity 0.15s ease-in 0s,
    transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s,
    -webkit-transform 0.5s cubic-bezier(0.4, 0, 0.2, 1) 0.1s;
  opacity: 0.1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=10)";
}
.ripple.ripple-out {
  transition: opacity 0.1s linear 0s !important;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
body {
  background-color: #eee;
}
body.inverse {
  background: #333;
}
body.inverse,
body.inverse .form-control {
  color: rgba(255, 255, 255, 0.84);
}
body.inverse .modal,
body.inverse .modal .form-control,
body.inverse .panel-default,
body.inverse .panel-default .form-control,
body.inverse .card,
body.inverse .card .form-control {
  background-color: initial;
  color: initial;
}
body,
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4 {
  font-family: Roboto, Noto Sans, Helvetica Neue, Helvetica, Arial, sans-serif;
  font-weight: 300;
}
h5,
h6 {
  font-weight: 400;
}
a,
a:hover,
a:focus {
  color: #333;
}
a .material-icons,
a:hover .material-icons,
a:focus .material-icons {
  vertical-align: middle;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 0;
}
.form-horizontal .radio {
  margin-bottom: 10px;
}
.form-horizontal label {
  text-align: right;
}
.form-horizontal label.control-label {
  margin: 0;
}
body .container .well.well-sm,
body .container-fluid .well.well-sm {
  padding: 10px;
}
body .container .well.well-lg,
body .container-fluid .well.well-lg {
  padding: 26px;
}
body .container .well,
body .container .jumbotron,
body .container-fluid .well,
body .container-fluid .jumbotron {
  background-color: #fff;
  padding: 19px;
  margin-bottom: 20px;
  box-shadow: 0 8px 17px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 2px;
  border: 0;
}
body .container .well p,
body .container .jumbotron p,
body .container-fluid .well p,
body .container-fluid .jumbotron p {
  font-weight: 300;
}
body .container .well,
body .container .well-default,
body .container .jumbotron,
body .container .jumbotron-default,
body .container-fluid .well,
body .container-fluid .well-default,
body .container-fluid .jumbotron,
body .container-fluid .jumbotron-default {
  background-color: #fff;
}
body .container .well-inverse,
body .container .jumbotron-inverse,
body .container-fluid .well-inverse,
body .container-fluid .jumbotron-inverse {
  background-color: #3f51b5;
}
body .container .well-primary,
body .container .jumbotron-primary,
body .container-fluid .well-primary,
body .container-fluid .jumbotron-primary {
  background-color: #8bc34a;
}
body .container .well-success,
body .container .jumbotron-success,
body .container-fluid .well-success,
body .container-fluid .jumbotron-success {
  background-color: #4caf50;
}
body .container .well-info,
body .container .jumbotron-info,
body .container-fluid .well-info,
body .container-fluid .jumbotron-info {
  background-color: #00bcd4;
}
body .container .well-warning,
body .container .jumbotron-warning,
body .container-fluid .well-warning,
body .container-fluid .jumbotron-warning {
  background-color: #ff9800;
}
body .container .well-danger,
body .container .jumbotron-danger,
body .container-fluid .well-danger,
body .container-fluid .jumbotron-danger {
  background-color: #f44336;
}
body .container .well-royal,
body .container .jumbotron-royal,
body .container-fluid .well-royal,
body .container-fluid .jumbotron-royal {
  background-color: #9c27b0;
}
.btn,
.input-group-btn .btn {
  border: none;
  border-radius: 2px;
  position: relative;
  padding: 8px 30px;
  margin: 10px 1px;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0;
  will-change: box-shadow, transform;
  transition: box-shadow 0.2s cubic-bezier(0.4, 0, 1, 1),
    background-color 0.2s cubic-bezier(0.4, 0, 0.2, 1),
    color 0.2s cubic-bezier(0.4, 0, 0.2, 1);
  outline: 0;
  cursor: pointer;
  text-decoration: none;
  background: transparent;
}
.btn::-moz-focus-inner,
.input-group-btn .btn::-moz-focus-inner {
  border: 0;
}
.btn:not(.btn-raised),
.input-group-btn .btn:not(.btn-raised) {
  box-shadow: none;
}
.btn:not(.btn-raised),
.btn:not(.btn-raised).btn-default,
.input-group-btn .btn:not(.btn-raised),
.input-group-btn .btn:not(.btn-raised).btn-default {
  color: rgba(0, 0, 0, 0.87);
}
.btn:not(.btn-raised).btn-inverse,
.input-group-btn .btn:not(.btn-raised).btn-inverse {
  color: #3f51b5;
}
.btn:not(.btn-raised).btn-primary,
.input-group-btn .btn:not(.btn-raised).btn-primary {
  color: #8bc34a;
}
.btn:not(.btn-raised).btn-success,
.input-group-btn .btn:not(.btn-raised).btn-success {
  color: #4caf50;
}
.btn:not(.btn-raised).btn-info,
.input-group-btn .btn:not(.btn-raised).btn-info {
  color: #00bcd4;
}
.btn:not(.btn-raised).btn-warning,
.input-group-btn .btn:not(.btn-raised).btn-warning {
  color: #ff9800;
}
.btn:not(.btn-raised).btn-danger,
.input-group-btn .btn:not(.btn-raised).btn-danger {
  color: #f44336;
}
.btn:not(.btn-raised).btn-royal,
.input-group-btn .btn:not(.btn-raised).btn-royal {
  color: #9c27b0;
}
.btn:not(.btn-raised):not(.btn-link):hover,
.btn:not(.btn-raised):not(.btn-link):focus,
.input-group-btn .btn:not(.btn-raised):not(.btn-link):hover,
.input-group-btn .btn:not(.btn-raised):not(.btn-link):focus {
  background-color: rgba(153, 153, 153, 0.2);
}
.theme-dark .btn:not(.btn-raised):not(.btn-link):hover,
.theme-dark .btn:not(.btn-raised):not(.btn-link):focus,
.theme-dark .input-group-btn .btn:not(.btn-raised):not(.btn-link):hover,
.theme-dark .input-group-btn .btn:not(.btn-raised):not(.btn-link):focus {
  background-color: rgba(204, 204, 204, 0.15);
}
.btn.btn-raised,
.btn.btn-raised.btn-default,
.btn.btn-fab,
.btn.btn-fab.btn-default,
.btn-group-raised .btn,
.btn-group-raised .btn.btn-default,
.input-group-btn .btn.btn-raised,
.input-group-btn .btn.btn-raised.btn-default,
.input-group-btn .btn.btn-fab,
.input-group-btn .btn.btn-fab.btn-default,
.btn-group-raised .input-group-btn .btn,
.btn-group-raised .input-group-btn .btn.btn-default {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}
.btn.btn-raised.btn-inverse,
.btn.btn-fab.btn-inverse,
.btn-group-raised .btn.btn-inverse,
.input-group-btn .btn.btn-raised.btn-inverse,
.input-group-btn .btn.btn-fab.btn-inverse,
.btn-group-raised .input-group-btn .btn.btn-inverse {
  background-color: #3f51b5;
  color: #fff;
}
.btn.btn-raised.btn-primary,
.btn.btn-fab.btn-primary,
.btn-group-raised .btn.btn-primary,
.input-group-btn .btn.btn-raised.btn-primary,
.input-group-btn .btn.btn-fab.btn-primary,
.btn-group-raised .input-group-btn .btn.btn-primary {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised.btn-success,
.btn.btn-fab.btn-success,
.btn-group-raised .btn.btn-success,
.input-group-btn .btn.btn-raised.btn-success,
.input-group-btn .btn.btn-fab.btn-success,
.btn-group-raised .input-group-btn .btn.btn-success {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised.btn-info,
.btn.btn-fab.btn-info,
.btn-group-raised .btn.btn-info,
.input-group-btn .btn.btn-raised.btn-info,
.input-group-btn .btn.btn-fab.btn-info,
.btn-group-raised .input-group-btn .btn.btn-info {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised.btn-warning,
.btn.btn-fab.btn-warning,
.btn-group-raised .btn.btn-warning,
.input-group-btn .btn.btn-raised.btn-warning,
.input-group-btn .btn.btn-fab.btn-warning,
.btn-group-raised .input-group-btn .btn.btn-warning {
  background-color: #ff9800;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised.btn-danger,
.btn.btn-fab.btn-danger,
.btn-group-raised .btn.btn-danger,
.input-group-btn .btn.btn-raised.btn-danger,
.input-group-btn .btn.btn-fab.btn-danger,
.btn-group-raised .input-group-btn .btn.btn-danger {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised.btn-royal,
.btn.btn-fab.btn-royal,
.btn-group-raised .btn.btn-royal,
.input-group-btn .btn.btn-raised.btn-royal,
.input-group-btn .btn.btn-fab.btn-royal,
.btn-group-raised .input-group-btn .btn.btn-royal {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.btn.btn-raised:not(.btn-link),
.btn-group-raised .btn:not(.btn-link),
.input-group-btn .btn.btn-raised:not(.btn-link),
.btn-group-raised .input-group-btn .btn:not(.btn-link) {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn.btn-raised:not(.btn-link):hover,
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):hover,
.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active {
  outline: 0;
}
.btn.btn-raised:not(.btn-link):hover,
.btn.btn-raised:not(.btn-link):hover.btn-default,
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link):focus.btn-default,
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link).active.btn-default,
.btn.btn-raised:not(.btn-link):active,
.btn.btn-raised:not(.btn-link):active.btn-default,
.btn-group-raised .btn:not(.btn-link):hover,
.btn-group-raised .btn:not(.btn-link):hover.btn-default,
.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link):focus.btn-default,
.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link).active.btn-default,
.btn-group-raised .btn:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):active.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):hover,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-default,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-default,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-default {
  background-color: #e4e4e4;
}
.btn.btn-raised:not(.btn-link):hover.btn-inverse,
.btn.btn-raised:not(.btn-link):focus.btn-inverse,
.btn.btn-raised:not(.btn-link).active.btn-inverse,
.btn.btn-raised:not(.btn-link):active.btn-inverse,
.btn-group-raised .btn:not(.btn-link):hover.btn-inverse,
.btn-group-raised .btn:not(.btn-link):focus.btn-inverse,
.btn-group-raised .btn:not(.btn-link).active.btn-inverse,
.btn-group-raised .btn:not(.btn-link):active.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-inverse,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-inverse,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-inverse {
  background-color: #495bc0;
}
.btn.btn-raised:not(.btn-link):hover.btn-primary,
.btn.btn-raised:not(.btn-link):focus.btn-primary,
.btn.btn-raised:not(.btn-link).active.btn-primary,
.btn.btn-raised:not(.btn-link):active.btn-primary,
.btn-group-raised .btn:not(.btn-link):hover.btn-primary,
.btn-group-raised .btn:not(.btn-link):focus.btn-primary,
.btn-group-raised .btn:not(.btn-link).active.btn-primary,
.btn-group-raised .btn:not(.btn-link):active.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-primary,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-primary,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-primary {
  background-color: #81bb3e;
}
.btn.btn-raised:not(.btn-link):hover.btn-success,
.btn.btn-raised:not(.btn-link):focus.btn-success,
.btn.btn-raised:not(.btn-link).active.btn-success,
.btn.btn-raised:not(.btn-link):active.btn-success,
.btn-group-raised .btn:not(.btn-link):hover.btn-success,
.btn-group-raised .btn:not(.btn-link):focus.btn-success,
.btn-group-raised .btn:not(.btn-link).active.btn-success,
.btn-group-raised .btn:not(.btn-link):active.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-success,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-success,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-success {
  background-color: #46a149;
}
.btn.btn-raised:not(.btn-link):hover.btn-info,
.btn.btn-raised:not(.btn-link):focus.btn-info,
.btn.btn-raised:not(.btn-link).active.btn-info,
.btn.btn-raised:not(.btn-link):active.btn-info,
.btn-group-raised .btn:not(.btn-link):hover.btn-info,
.btn-group-raised .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .btn:not(.btn-link).active.btn-info,
.btn-group-raised .btn:not(.btn-link):active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-info,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-info,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-info {
  background-color: #00aac0;
}
.btn.btn-raised:not(.btn-link):hover.btn-warning,
.btn.btn-raised:not(.btn-link):focus.btn-warning,
.btn.btn-raised:not(.btn-link).active.btn-warning,
.btn.btn-raised:not(.btn-link):active.btn-warning,
.btn-group-raised .btn:not(.btn-link):hover.btn-warning,
.btn-group-raised .btn:not(.btn-link):focus.btn-warning,
.btn-group-raised .btn:not(.btn-link).active.btn-warning,
.btn-group-raised .btn:not(.btn-link):active.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-warning,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-warning,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-warning {
  background-color: #eb8c00;
}
.btn.btn-raised:not(.btn-link):hover.btn-danger,
.btn.btn-raised:not(.btn-link):focus.btn-danger,
.btn.btn-raised:not(.btn-link).active.btn-danger,
.btn.btn-raised:not(.btn-link):active.btn-danger,
.btn-group-raised .btn:not(.btn-link):hover.btn-danger,
.btn-group-raised .btn:not(.btn-link):focus.btn-danger,
.btn-group-raised .btn:not(.btn-link).active.btn-danger,
.btn-group-raised .btn:not(.btn-link):active.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-danger,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-danger,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-danger {
  background-color: #f55549;
}
.btn.btn-raised:not(.btn-link):hover.btn-royal,
.btn.btn-raised:not(.btn-link):focus.btn-royal,
.btn.btn-raised:not(.btn-link).active.btn-royal,
.btn.btn-raised:not(.btn-link):active.btn-royal,
.btn-group-raised .btn:not(.btn-link):hover.btn-royal,
.btn-group-raised .btn:not(.btn-link):focus.btn-royal,
.btn-group-raised .btn:not(.btn-link).active.btn-royal,
.btn-group-raised .btn:not(.btn-link):active.btn-royal,
.input-group-btn .btn.btn-raised:not(.btn-link):hover.btn-royal,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.btn-royal,
.input-group-btn .btn.btn-raised:not(.btn-link).active.btn-royal,
.input-group-btn .btn.btn-raised:not(.btn-link):active.btn-royal,
.btn-group-raised .input-group-btn .btn:not(.btn-link):hover.btn-royal,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.btn-royal,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active.btn-royal,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active.btn-royal {
  background-color: #8d239f;
}
.btn.btn-raised:not(.btn-link).active,
.btn.btn-raised:not(.btn-link).active:hover,
.btn.btn-raised:not(.btn-link):active,
.btn.btn-raised:not(.btn-link):active:hover,
.btn-group-raised .btn:not(.btn-link).active,
.btn-group-raised .btn:not(.btn-link).active:hover,
.btn-group-raised .btn:not(.btn-link):active,
.btn-group-raised .btn:not(.btn-link):active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link).active,
.input-group-btn .btn.btn-raised:not(.btn-link).active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):active,
.input-group-btn .btn.btn-raised:not(.btn-link):active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active,
.btn-group-raised .input-group-btn .btn:not(.btn-link).active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):active:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.btn.btn-raised:not(.btn-link):focus,
.btn.btn-raised:not(.btn-link):focus:hover,
.btn.btn-raised:not(.btn-link):focus.active,
.btn.btn-raised:not(.btn-link):focus.active:hover,
.btn.btn-raised:not(.btn-link):focus:active,
.btn.btn-raised:not(.btn-link):focus:active:hover,
.btn-group-raised .btn:not(.btn-link):focus,
.btn-group-raised .btn:not(.btn-link):focus:hover,
.btn-group-raised .btn:not(.btn-link):focus.active,
.btn-group-raised .btn:not(.btn-link):focus.active:hover,
.btn-group-raised .btn:not(.btn-link):focus:active,
.btn-group-raised .btn:not(.btn-link):focus:active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.active,
.input-group-btn .btn.btn-raised:not(.btn-link):focus.active:hover,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:active,
.input-group-btn .btn.btn-raised:not(.btn-link):focus:active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus.active:hover,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:active,
.btn-group-raised .input-group-btn .btn:not(.btn-link):focus:active:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.btn.btn-fab,
.input-group-btn .btn.btn-fab {
  border-radius: 50%;
  font-size: 24px;
  height: 56px;
  margin: auto;
  min-width: 56px;
  width: 56px;
  padding: 0;
  overflow: hidden;
  box-shadow: 0 1px 1.5px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.24);
  position: relative;
  line-height: normal;
}
.btn.btn-fab .ripple-container,
.input-group-btn .btn.btn-fab .ripple-container {
  border-radius: 50%;
}
.btn.btn-fab.btn-fab-mini,
.btn-group-sm .btn.btn-fab,
.input-group-btn .btn.btn-fab.btn-fab-mini,
.btn-group-sm .input-group-btn .btn.btn-fab {
  height: 40px;
  min-width: 40px;
  width: 40px;
}
.btn.btn-fab.btn-fab-mini.material-icons,
.btn-group-sm .btn.btn-fab.material-icons,
.input-group-btn .btn.btn-fab.btn-fab-mini.material-icons,
.btn-group-sm .input-group-btn .btn.btn-fab.material-icons {
  top: 0;
  left: 0;
}
.btn.btn-fab i,
.input-group-btn .btn.btn-fab i {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-12px, -12px);
  transform: translate(-12px, -12px);
  line-height: 24px;
  width: 24px;
}
.btn i,
.input-group-btn .btn i {
  vertical-align: middle;
}
.btn.btn-lg,
.btn-group-lg .btn,
.input-group-btn .btn.btn-lg,
.btn-group-lg .input-group-btn .btn {
  font-size: 16px;
}
.btn.btn-sm,
.btn-group-sm .btn,
.input-group-btn .btn.btn-sm,
.btn-group-sm .input-group-btn .btn {
  padding: 5px 20px;
  font-size: 12px;
}
.btn.btn-xs,
.btn-group-xs .btn,
.input-group-btn .btn.btn-xs,
.btn-group-xs .input-group-btn .btn {
  padding: 4px 15px;
  font-size: 10px;
}
fieldset[disabled][disabled] .btn,
.btn.disabled,
.btn:disabled,
.btn[disabled][disabled],
fieldset[disabled][disabled] .input-group-btn .btn,
.input-group-btn .btn.disabled,
.input-group-btn .btn:disabled,
.input-group-btn .btn[disabled][disabled],
fieldset[disabled][disabled] .btn-group,
.btn-group.disabled,
.btn-group:disabled,
.btn-group[disabled][disabled],
fieldset[disabled][disabled] .btn-group-vertical,
.btn-group-vertical.disabled,
.btn-group-vertical:disabled,
.btn-group-vertical[disabled][disabled] {
  color: rgba(0, 0, 0, 0.26);
  background: transparent;
}
.theme-dark fieldset[disabled][disabled] .btn,
.theme-dark .btn.disabled,
.theme-dark .btn:disabled,
.theme-dark .btn[disabled][disabled],
.theme-dark fieldset[disabled][disabled] .input-group-btn .btn,
.theme-dark .input-group-btn .btn.disabled,
.theme-dark .input-group-btn .btn:disabled,
.theme-dark .input-group-btn .btn[disabled][disabled],
.theme-dark fieldset[disabled][disabled] .btn-group,
.theme-dark .btn-group.disabled,
.theme-dark .btn-group:disabled,
.theme-dark .btn-group[disabled][disabled],
.theme-dark fieldset[disabled][disabled] .btn-group-vertical,
.theme-dark .btn-group-vertical.disabled,
.theme-dark .btn-group-vertical:disabled,
.theme-dark .btn-group-vertical[disabled][disabled] {
  color: rgba(255, 255, 255, 0.3);
}
fieldset[disabled][disabled] .btn.btn-raised,
fieldset[disabled][disabled] .btn.btn-raised.active,
fieldset[disabled][disabled] .btn.btn-raised:active,
fieldset[disabled][disabled] .btn.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn.btn-group-raised,
fieldset[disabled][disabled] .btn.btn-group-raised.active,
fieldset[disabled][disabled] .btn.btn-group-raised:active,
fieldset[disabled][disabled] .btn.btn-group-raised:focus:not(:active),
.btn.disabled.btn-raised,
.btn.disabled.btn-raised.active,
.btn.disabled.btn-raised:active,
.btn.disabled.btn-raised:focus:not(:active),
.btn.disabled.btn-group-raised,
.btn.disabled.btn-group-raised.active,
.btn.disabled.btn-group-raised:active,
.btn.disabled.btn-group-raised:focus:not(:active),
.btn:disabled.btn-raised,
.btn:disabled.btn-raised.active,
.btn:disabled.btn-raised:active,
.btn:disabled.btn-raised:focus:not(:active),
.btn:disabled.btn-group-raised,
.btn:disabled.btn-group-raised.active,
.btn:disabled.btn-group-raised:active,
.btn:disabled.btn-group-raised:focus:not(:active),
.btn[disabled][disabled].btn-raised,
.btn[disabled][disabled].btn-raised.active,
.btn[disabled][disabled].btn-raised:active,
.btn[disabled][disabled].btn-raised:focus:not(:active),
.btn[disabled][disabled].btn-group-raised,
.btn[disabled][disabled].btn-group-raised.active,
.btn[disabled][disabled].btn-group-raised:active,
.btn[disabled][disabled].btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised,
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised.active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-raised:active,
fieldset[disabled][disabled]
  .input-group-btn
  .btn.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised,
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised.active,
fieldset[disabled][disabled] .input-group-btn .btn.btn-group-raised:active,
fieldset[disabled][disabled]
  .input-group-btn
  .btn.btn-group-raised:focus:not(:active),
.input-group-btn .btn.disabled.btn-raised,
.input-group-btn .btn.disabled.btn-raised.active,
.input-group-btn .btn.disabled.btn-raised:active,
.input-group-btn .btn.disabled.btn-raised:focus:not(:active),
.input-group-btn .btn.disabled.btn-group-raised,
.input-group-btn .btn.disabled.btn-group-raised.active,
.input-group-btn .btn.disabled.btn-group-raised:active,
.input-group-btn .btn.disabled.btn-group-raised:focus:not(:active),
.input-group-btn .btn:disabled.btn-raised,
.input-group-btn .btn:disabled.btn-raised.active,
.input-group-btn .btn:disabled.btn-raised:active,
.input-group-btn .btn:disabled.btn-raised:focus:not(:active),
.input-group-btn .btn:disabled.btn-group-raised,
.input-group-btn .btn:disabled.btn-group-raised.active,
.input-group-btn .btn:disabled.btn-group-raised:active,
.input-group-btn .btn:disabled.btn-group-raised:focus:not(:active),
.input-group-btn .btn[disabled][disabled].btn-raised,
.input-group-btn .btn[disabled][disabled].btn-raised.active,
.input-group-btn .btn[disabled][disabled].btn-raised:active,
.input-group-btn .btn[disabled][disabled].btn-raised:focus:not(:active),
.input-group-btn .btn[disabled][disabled].btn-group-raised,
.input-group-btn .btn[disabled][disabled].btn-group-raised.active,
.input-group-btn .btn[disabled][disabled].btn-group-raised:active,
.input-group-btn .btn[disabled][disabled].btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group.btn-raised,
fieldset[disabled][disabled] .btn-group.btn-raised.active,
fieldset[disabled][disabled] .btn-group.btn-raised:active,
fieldset[disabled][disabled] .btn-group.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group.btn-group-raised,
fieldset[disabled][disabled] .btn-group.btn-group-raised.active,
fieldset[disabled][disabled] .btn-group.btn-group-raised:active,
fieldset[disabled][disabled] .btn-group.btn-group-raised:focus:not(:active),
.btn-group.disabled.btn-raised,
.btn-group.disabled.btn-raised.active,
.btn-group.disabled.btn-raised:active,
.btn-group.disabled.btn-raised:focus:not(:active),
.btn-group.disabled.btn-group-raised,
.btn-group.disabled.btn-group-raised.active,
.btn-group.disabled.btn-group-raised:active,
.btn-group.disabled.btn-group-raised:focus:not(:active),
.btn-group:disabled.btn-raised,
.btn-group:disabled.btn-raised.active,
.btn-group:disabled.btn-raised:active,
.btn-group:disabled.btn-raised:focus:not(:active),
.btn-group:disabled.btn-group-raised,
.btn-group:disabled.btn-group-raised.active,
.btn-group:disabled.btn-group-raised:active,
.btn-group:disabled.btn-group-raised:focus:not(:active),
.btn-group[disabled][disabled].btn-raised,
.btn-group[disabled][disabled].btn-raised.active,
.btn-group[disabled][disabled].btn-raised:active,
.btn-group[disabled][disabled].btn-raised:focus:not(:active),
.btn-group[disabled][disabled].btn-group-raised,
.btn-group[disabled][disabled].btn-group-raised.active,
.btn-group[disabled][disabled].btn-group-raised:active,
.btn-group[disabled][disabled].btn-group-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group-vertical.btn-raised,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised.active,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised:active,
fieldset[disabled][disabled] .btn-group-vertical.btn-raised:focus:not(:active),
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised,
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised.active,
fieldset[disabled][disabled] .btn-group-vertical.btn-group-raised:active,
fieldset[disabled][disabled]
  .btn-group-vertical.btn-group-raised:focus:not(:active),
.btn-group-vertical.disabled.btn-raised,
.btn-group-vertical.disabled.btn-raised.active,
.btn-group-vertical.disabled.btn-raised:active,
.btn-group-vertical.disabled.btn-raised:focus:not(:active),
.btn-group-vertical.disabled.btn-group-raised,
.btn-group-vertical.disabled.btn-group-raised.active,
.btn-group-vertical.disabled.btn-group-raised:active,
.btn-group-vertical.disabled.btn-group-raised:focus:not(:active),
.btn-group-vertical:disabled.btn-raised,
.btn-group-vertical:disabled.btn-raised.active,
.btn-group-vertical:disabled.btn-raised:active,
.btn-group-vertical:disabled.btn-raised:focus:not(:active),
.btn-group-vertical:disabled.btn-group-raised,
.btn-group-vertical:disabled.btn-group-raised.active,
.btn-group-vertical:disabled.btn-group-raised:active,
.btn-group-vertical:disabled.btn-group-raised:focus:not(:active),
.btn-group-vertical[disabled][disabled].btn-raised,
.btn-group-vertical[disabled][disabled].btn-raised.active,
.btn-group-vertical[disabled][disabled].btn-raised:active,
.btn-group-vertical[disabled][disabled].btn-raised:focus:not(:active),
.btn-group-vertical[disabled][disabled].btn-group-raised,
.btn-group-vertical[disabled][disabled].btn-group-raised.active,
.btn-group-vertical[disabled][disabled].btn-group-raised:active,
.btn-group-vertical[disabled][disabled].btn-group-raised:focus:not(:active) {
  box-shadow: none;
}
.btn-group,
.btn-group-vertical {
  position: relative;
  margin: 10px 1px;
}
.btn-group.open > .dropdown-toggle.btn,
.btn-group.open > .dropdown-toggle.btn.btn-default,
.btn-group-vertical.open > .dropdown-toggle.btn,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-default {
  background-color: #eee;
}
.btn-group.open > .dropdown-toggle.btn.btn-inverse,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-inverse {
  background-color: #3f51b5;
}
.btn-group.open > .dropdown-toggle.btn.btn-primary,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-primary {
  background-color: #8bc34a;
}
.btn-group.open > .dropdown-toggle.btn.btn-success,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-success {
  background-color: #4caf50;
}
.btn-group.open > .dropdown-toggle.btn.btn-info,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-info {
  background-color: #00bcd4;
}
.btn-group.open > .dropdown-toggle.btn.btn-warning,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-warning {
  background-color: #ff9800;
}
.btn-group.open > .dropdown-toggle.btn.btn-danger,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-danger {
  background-color: #f44336;
}
.btn-group.open > .dropdown-toggle.btn.btn-royal,
.btn-group-vertical.open > .dropdown-toggle.btn.btn-royal {
  background-color: #9c27b0;
}
.btn-group .dropdown-menu,
.btn-group-vertical .dropdown-menu {
  border-radius: 0 0 2px 2px;
}
.btn-group.btn-group-raised,
.btn-group-vertical.btn-group-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.btn-group .btn + .btn,
.btn-group .btn,
.btn-group .btn:active,
.btn-group .btn-group,
.btn-group-vertical .btn + .btn,
.btn-group-vertical .btn,
.btn-group-vertical .btn:active,
.btn-group-vertical .btn-group {
  margin: 0;
}
.checkbox label,
label.checkbox-inline {
  cursor: pointer;
  padding-left: 0;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .checkbox label,
.form-group.is-focused label.checkbox-inline {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .checkbox label:hover,
.form-group.is-focused .checkbox label:focus,
.form-group.is-focused label.checkbox-inline:hover,
.form-group.is-focused label.checkbox-inline:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .checkbox label,
fieldset[disabled] .form-group.is-focused label.checkbox-inline {
  color: rgba(0, 0, 0, 0.26);
}
.checkbox input[type="checkbox"],
label.checkbox-inline input[type="checkbox"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  margin: 0;
  z-index: -1;
  width: 0;
  height: 0;
  overflow: hidden;
  left: 0;
  pointer-events: none;
}
.checkbox .checkbox-material,
label.checkbox-inline .checkbox-material {
  vertical-align: middle;
  position: relative;
  top: 3px;
}
.checkbox .checkbox-material:before,
label.checkbox-inline .checkbox-material:before {
  display: block;
  position: absolute;
  top: -5px;
  left: 0;
  content: "";
  background-color: rgba(0, 0, 0, 0.84);
  height: 20px;
  width: 20px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  margin: 0;
  -webkit-transform: scale3d(2.3, 2.3, 1);
  transform: scale3d(2.3, 2.3, 1);
}
.checkbox .checkbox-material .check,
label.checkbox-inline .checkbox-material .check {
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid rgba(0, 0, 0, 0.54);
  border-radius: 2px;
  overflow: hidden;
  z-index: 1;
}
.checkbox .checkbox-material .check:before,
label.checkbox-inline .checkbox-material .check:before {
  position: absolute;
  content: "";
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  display: block;
  margin-top: -4px;
  margin-left: 6px;
  width: 0;
  height: 0;
  box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
    0 0 0 0 inset;
}
.checkbox input[type="checkbox"]:focus + .checkbox-material .check:after,
label.checkbox-inline
  input[type="checkbox"]:focus
  + .checkbox-material
  .check:after {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
}
.checkbox input[type="checkbox"]:focus:checked + .checkbox-material:before,
label.checkbox-inline
  input[type="checkbox"]:focus:checked
  + .checkbox-material:before {
  -webkit-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.checkbox
  input[type="checkbox"]:focus:checked
  + .checkbox-material
  .check:before,
label.checkbox-inline
  input[type="checkbox"]:focus:checked
  + .checkbox-material
  .check:before {
  -webkit-animation: checkbox-on 0.3s forwards;
  animation: checkbox-on 0.3s forwards;
}
.checkbox
  input[type="checkbox"]:focus:checked
  + .checkbox-material
  .check:after,
label.checkbox-inline
  input[type="checkbox"]:focus:checked
  + .checkbox-material
  .check:after {
  -webkit-animation: rippleOn 500ms forwards;
  animation: rippleOn 500ms forwards;
}
.checkbox
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material:before,
label.checkbox-inline
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material:before {
  -webkit-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.checkbox
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material
  .check:before,
label.checkbox-inline
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material
  .check:before {
  -webkit-animation: checkbox-off 0.3s forwards;
  animation: checkbox-off 0.3s forwards;
}
.checkbox
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material
  .check:after,
label.checkbox-inline
  input[type="checkbox"]:focus:not(:checked)
  + .checkbox-material
  .check:after {
  -webkit-animation: rippleOff 500ms forwards;
  animation: rippleOff 500ms forwards;
}
.checkbox input[type="checkbox"]:checked + .checkbox-material .check,
label.checkbox-inline
  input[type="checkbox"]:checked
  + .checkbox-material
  .check {
  color: #8bc34a;
  border-color: #8bc34a;
}
.checkbox input[type="checkbox"]:checked + .checkbox-material .check:before,
label.checkbox-inline
  input[type="checkbox"]:checked
  + .checkbox-material
  .check:before {
  color: #8bc34a;
  box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
    -5px 5px 0 10px, 20px -12px 0 11px;
}
fieldset[disabled] .checkbox,
fieldset[disabled] .checkbox input[type="checkbox"],
.checkbox
  input[type="checkbox"][disabled]:not(:checked)
  ~ .checkbox-material
  .check:before,
.checkbox
  input[type="checkbox"][disabled]:not(:checked)
  ~ .checkbox-material
  .check,
.checkbox input[type="checkbox"][disabled] + .circle,
fieldset[disabled] label.checkbox-inline,
fieldset[disabled] label.checkbox-inline input[type="checkbox"],
label.checkbox-inline
  input[type="checkbox"][disabled]:not(:checked)
  ~ .checkbox-material
  .check:before,
label.checkbox-inline
  input[type="checkbox"][disabled]:not(:checked)
  ~ .checkbox-material
  .check,
label.checkbox-inline input[type="checkbox"][disabled] + .circle {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}
.checkbox input[type="checkbox"][disabled] + .checkbox-material .check:after,
label.checkbox-inline
  input[type="checkbox"][disabled]
  + .checkbox-material
  .check:after {
  background-color: rgba(0, 0, 0, 0.87);
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}
@-webkit-keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@keyframes checkbox-on {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px;
  }
  50% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px 2px 0 11px;
  }
  100% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px;
  }
}
@-webkit-keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0 0 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0 0 0 0 inset;
  }
}
@keyframes checkbox-off {
  0% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  25% {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
  }
  50% {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0 0 20px, 0 32px 0 20px,
      -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
  }
  51% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0 0 0 10px inset;
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
    margin-top: -2px;
    margin-left: -2px;
    width: 20px;
    height: 20px;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0,
      0 0 0 0 inset;
  }
}
@-webkit-keyframes rippleOn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes rippleOn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@-webkit-keyframes rippleOff {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
.togglebutton {
  vertical-align: middle;
}
.togglebutton,
.togglebutton label,
.togglebutton input,
.togglebutton .toggle {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.togglebutton label {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .togglebutton label:hover,
.form-group.is-focused .togglebutton label:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .togglebutton label {
  color: rgba(0, 0, 0, 0.26);
}
.togglebutton label input[type="checkbox"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  width: 0;
  height: 0;
}
.togglebutton label .toggle {
  text-align: left;
}
.togglebutton label .toggle,
.togglebutton label input[type="checkbox"][disabled] + .toggle {
  content: "";
  display: inline-block;
  width: 30px;
  height: 15px;
  background-color: rgba(80, 80, 80, 0.7);
  border-radius: 15px;
  margin-right: 15px;
  transition: background 0.3s ease;
  vertical-align: middle;
}
.togglebutton label .toggle:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  background-color: #f1f1f1;
  border-radius: 20px;
  position: relative;
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4);
  left: -5px;
  top: -2px;
  transition: left 0.3s ease, background 0.3s ease, box-shadow 0.1s ease;
}
.togglebutton label input[type="checkbox"][disabled] + .toggle:after,
.togglebutton label input[type="checkbox"][disabled]:checked + .toggle:after {
  background-color: #bdbdbd;
}
.togglebutton label input[type="checkbox"] + .toggle:active:after,
.togglebutton label input[type="checkbox"][disabled] + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4), 0 0 0 15px rgba(0, 0, 0, 0.1);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  left: 15px;
}
.togglebutton label input[type="checkbox"]:checked + .toggle {
  background-color: rgba(139, 195, 74, 0.5);
}
.togglebutton label input[type="checkbox"]:checked + .toggle:after {
  background-color: #8bc34a;
}
.togglebutton label input[type="checkbox"]:checked + .toggle:active:after {
  box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.4),
    0 0 0 15px rgba(139, 195, 74, 0.1);
}
.radio label,
label.radio-inline {
  cursor: pointer;
  padding-left: 45px;
  position: relative;
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .radio label,
.form-group.is-focused label.radio-inline {
  color: rgba(0, 0, 0, 0.26);
}
.form-group.is-focused .radio label:hover,
.form-group.is-focused .radio label:focus,
.form-group.is-focused label.radio-inline:hover,
.form-group.is-focused label.radio-inline:focus {
  color: rgba(0, 0, 0, 0.54);
}
fieldset[disabled] .form-group.is-focused .radio label,
fieldset[disabled] .form-group.is-focused label.radio-inline {
  color: rgba(0, 0, 0, 0.26);
}
.radio span,
label.radio-inline span {
  display: block;
  position: absolute;
  left: 10px;
  top: 2px;
  transition-duration: 0.2s;
}
.radio .circle,
label.radio-inline .circle {
  border: 2px solid rgba(0, 0, 0, 0.54);
  height: 17px;
  width: 17px;
  border-radius: 100%;
}
.radio .check,
label.radio-inline .check {
  height: 17px;
  width: 17px;
  border-radius: 100%;
  background-color: #8bc34a;
  -webkit-transform: scale3d(0, 0, 0);
  transform: scale3d(0, 0, 0);
}
.radio .check:after,
label.radio-inline .check:after {
  display: block;
  position: absolute;
  content: "";
  background-color: rgba(0, 0, 0, 0.87);
  left: -18px;
  top: -18px;
  height: 50px;
  width: 50px;
  border-radius: 100%;
  z-index: 1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  margin: 0;
  -webkit-transform: scale3d(1.5, 1.5, 1);
  transform: scale3d(1.5, 1.5, 1);
}
.radio input[type="radio"]:focus:not(:checked) ~ .check:after,
label.radio-inline input[type="radio"]:focus:not(:checked) ~ .check:after {
  -webkit-animation: rippleOff 500ms;
  animation: rippleOff 500ms;
}
.radio input[type="radio"]:focus:checked ~ .check:after,
label.radio-inline input[type="radio"]:focus:checked ~ .check:after {
  -webkit-animation: rippleOn 500ms;
  animation: rippleOn 500ms;
}
.radio input[type="radio"],
label.radio-inline input[type="radio"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  height: 0;
  width: 0;
  overflow: hidden;
}
.radio input[type="radio"]:checked ~ .check,
.radio input[type="radio"]:checked ~ .circle,
label.radio-inline input[type="radio"]:checked ~ .check,
label.radio-inline input[type="radio"]:checked ~ .circle {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.radio input[type="radio"]:checked ~ .check,
label.radio-inline input[type="radio"]:checked ~ .check {
  background-color: #8bc34a;
}
.radio input[type="radio"]:checked ~ .circle,
label.radio-inline input[type="radio"]:checked ~ .circle {
  border-color: #8bc34a;
}
.radio input[type="radio"]:checked ~ .check,
label.radio-inline input[type="radio"]:checked ~ .check {
  -webkit-transform: scale3d(0.55, 0.55, 1);
  transform: scale3d(0.55, 0.55, 1);
}
.radio input[type="radio"][disabled] ~ .check,
.radio input[type="radio"][disabled] ~ .circle,
label.radio-inline input[type="radio"][disabled] ~ .check,
label.radio-inline input[type="radio"][disabled] ~ .circle {
  opacity: 0.26;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=26)";
}
.radio input[type="radio"][disabled] ~ .check,
label.radio-inline input[type="radio"][disabled] ~ .check {
  background-color: #000;
}
.radio input[type="radio"][disabled] ~ .circle,
label.radio-inline input[type="radio"][disabled] ~ .circle {
  border-color: #000;
}
.theme-dark .radio input[type="radio"][disabled] ~ .check,
.theme-dark .radio input[type="radio"][disabled] ~ .circle,
.theme-dark label.radio-inline input[type="radio"][disabled] ~ .check,
.theme-dark label.radio-inline input[type="radio"][disabled] ~ .circle {
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
}
.theme-dark .radio input[type="radio"][disabled] ~ .check,
.theme-dark label.radio-inline input[type="radio"][disabled] ~ .check {
  background-color: #fff;
}
.theme-dark .radio input[type="radio"][disabled] ~ .circle,
.theme-dark label.radio-inline input[type="radio"][disabled] ~ .circle {
  border-color: #fff;
}
@keyframes rippleOn {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
@keyframes rippleOff {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  50% {
    opacity: 0.2;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  }
  100% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
}
legend {
  margin-bottom: 22px;
  font-size: 24px;
}
output {
  padding-top: 8px;
  font-size: 16px;
  line-height: 1.42857;
}
.form-control {
  height: 38px;
  padding: 7px 0;
  font-size: 16px;
  line-height: 1.42857;
}
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="date"].form-control,
  input[type="time"].form-control,
  input[type="datetime-local"].form-control,
  input[type="month"].form-control {
    line-height: 38px;
  }
  input[type="date"].input-sm,
  .input-group-sm input[type="date"],
  input[type="time"].input-sm,
  .input-group-sm input[type="time"],
  input[type="datetime-local"].input-sm,
  .input-group-sm input[type="datetime-local"],
  input[type="month"].input-sm,
  .input-group-sm input[type="month"] {
    line-height: 26px;
  }
  input[type="date"].input-lg,
  .input-group-lg input[type="date"],
  input[type="time"].input-lg,
  .input-group-lg input[type="time"],
  input[type="datetime-local"].input-lg,
  .input-group-lg input[type="datetime-local"],
  input[type="month"].input-lg,
  .input-group-lg input[type="month"] {
    line-height: 47px;
  }
}
.radio label,
.checkbox label {
  min-height: 22px;
}
.form-control-static {
  padding-top: 8px;
  padding-bottom: 8px;
  min-height: 38px;
}
.input-sm .input-sm {
  height: 26px;
  padding: 3px 0;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0;
}
.input-sm select.input-sm {
  height: 26px;
  line-height: 26px;
}
.input-sm textarea.input-sm,
.input-sm select[multiple].input-sm {
  height: auto;
}
.form-group-sm .form-control {
  height: 26px;
  padding: 3px 0;
  font-size: 12px;
  line-height: 1.5;
}
.form-group-sm select.form-control {
  height: 26px;
  line-height: 26px;
}
.form-group-sm textarea.form-control,
.form-group-sm select[multiple].form-control {
  height: auto;
}
.form-group-sm .form-control-static {
  height: 26px;
  min-height: 34px;
  padding: 4px 0;
  font-size: 12px;
  line-height: 1.5;
}
.input-lg .input-lg {
  height: 47px;
  padding: 9px 0;
  font-size: 20px;
  line-height: 1.33333;
  border-radius: 0;
}
.input-lg select.input-lg {
  height: 47px;
  line-height: 47px;
}
.input-lg textarea.input-lg,
.input-lg select[multiple].input-lg {
  height: auto;
}
.form-group-lg .form-control {
  height: 47px;
  padding: 9px 0;
  font-size: 20px;
  line-height: 1.33333;
}
.form-group-lg select.form-control {
  height: 47px;
  line-height: 47px;
}
.form-group-lg textarea.form-control,
.form-group-lg select[multiple].form-control {
  height: auto;
}
.form-group-lg .form-control-static {
  height: 47px;
  min-height: 42px;
  padding: 10px 0;
  font-size: 20px;
  line-height: 1.33333;
}
.form-horizontal .radio,
.form-horizontal .checkbox,
.form-horizontal .radio-inline,
.form-horizontal .checkbox-inline {
  padding-top: 8px;
}
.form-horizontal .radio,
.form-horizontal .checkbox {
  min-height: 30px;
}
@media (min-width: 768px) {
  .form-horizontal .control-label {
    padding-top: 8px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-lg .control-label {
    padding-top: 13px;
    font-size: 20px;
  }
}
@media (min-width: 768px) {
  .form-horizontal .form-group-sm .control-label {
    padding-top: 4px;
    font-size: 12px;
  }
}
.label {
  border-radius: 1px;
  padding: 0.3em 0.6em;
}
.label,
.label.label-default {
  background-color: #9e9e9e;
}
.label.label-inverse {
  background-color: #3f51b5;
}
.label.label-primary {
  background-color: #8bc34a;
}
.label.label-success {
  background-color: #4caf50;
}
.label.label-info {
  background-color: #00bcd4;
}
.label.label-warning {
  background-color: #ff9800;
}
.label.label-danger {
  background-color: #f44336;
}
.label.label-royal {
  background-color: #9c27b0;
}
.form-control,
.form-group .form-control {
  border: 0;
  background-image: linear-gradient(#8bc34a, #8bc34a),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 0 2px, 100% 1px;
  background-repeat: no-repeat;
  background-position: center bottom, center calc(100% - 1px);
  background-color: transparent;
  transition: background 0s ease-out;
  float: none;
  box-shadow: none;
  border-radius: 0;
}
.form-control::-moz-placeholder,
.form-group .form-control::-moz-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}
.form-control:-ms-input-placeholder,
.form-group .form-control:-ms-input-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}
.form-control::-webkit-input-placeholder,
.form-group .form-control::-webkit-input-placeholder {
  color: #bdbdbd;
  font-weight: 400;
}
.form-control[readonly],
.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[readonly],
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-color: transparent;
}
.form-control[disabled],
fieldset[disabled] .form-control,
.form-group .form-control[disabled],
fieldset[disabled] .form-group .form-control {
  background-image: none;
  border-bottom: 1px dotted #d2d2d2;
}
.form-group {
  position: relative;
}
.form-group.label-static label.control-label,
.form-group.label-placeholder label.control-label,
.form-group.label-floating label.control-label {
  position: absolute;
  pointer-events: none;
  transition: 0.3s ease all;
}
.form-group.label-floating label.control-label {
  will-change: left, top, contents;
}
.form-group.label-placeholder:not(.is-empty) label.control-label {
  display: none;
}
.form-group .help-block {
  position: absolute;
  display: none;
}
.form-group.is-focused .form-control {
  outline: none;
  background-image: linear-gradient(#8bc34a, #8bc34a),
    linear-gradient(#d2d2d2, #d2d2d2);
  background-size: 100% 2px, 100% 1px;
  box-shadow: none;
  transition-duration: 0.3s;
}
.form-group.is-focused .form-control .material-input:after {
  background-color: #8bc34a;
}
.form-group.is-focused label,
.form-group.is-focused label.control-label {
  color: #8bc34a;
}
.form-group.is-focused.label-placeholder label,
.form-group.is-focused.label-placeholder label.control-label {
  color: #bdbdbd;
}
.form-group.is-focused .help-block {
  display: block;
}
.form-group.has-warning .form-control {
  box-shadow: none;
}
.form-group.has-warning.is-focused .form-control {
  background-image: linear-gradient(#ff9800, #ff9800),
    linear-gradient(#d2d2d2, #d2d2d2);
}
.form-group.has-warning label.control-label,
.form-group.has-warning .help-block {
  color: #ff9800;
}
.form-group.has-error .form-control {
  box-shadow: none;
}
.form-group.has-error.is-focused .form-control {
  background-image: linear-gradient(#f44336, #f44336),
    linear-gradient(#d2d2d2, #d2d2d2);
}
.form-group.has-error label.control-label,
.form-group.has-error .help-block {
  color: #f44336;
}
.form-group.has-success .form-control {
  box-shadow: none;
}
.form-group.has-success.is-focused .form-control {
  background-image: linear-gradient(#4caf50, #4caf50),
    linear-gradient(#d2d2d2, #d2d2d2);
}
.form-group.has-success label.control-label,
.form-group.has-success .help-block {
  color: #4caf50;
}
.form-group.has-info .form-control {
  box-shadow: none;
}
.form-group.has-info.is-focused .form-control {
  background-image: linear-gradient(#00bcd4, #00bcd4),
    linear-gradient(#d2d2d2, #d2d2d2);
}
.form-group.has-info label.control-label,
.form-group.has-info .help-block {
  color: #00bcd4;
}
.form-group textarea {
  resize: none;
}
.form-group textarea ~ .form-control-highlight {
  margin-top: -11px;
}
.form-group select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.form-group select ~ .material-input:after {
  display: none;
}
.form-control {
  margin-bottom: 7px;
}
.form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.checkbox label,
.radio label,
label {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
label.control-label {
  font-size: 12px;
  line-height: 1.07143;
  font-weight: 400;
  margin: 16px 0 0;
}
.help-block {
  margin-top: 0;
  font-size: 12px;
}
.form-group {
  padding-bottom: 7px;
  margin: 28px 0 0;
}
.form-group .form-control {
  margin-bottom: 7px;
}
.form-group .form-control::-moz-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group .form-control:-ms-input-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group .form-control::-webkit-input-placeholder {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group .checkbox label,
.form-group .radio label,
.form-group label {
  font-size: 16px;
  line-height: 1.42857;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group label.control-label {
  font-size: 12px;
  line-height: 1.07143;
  font-weight: 400;
  margin: 16px 0 0;
}
.form-group .help-block {
  margin-top: 0;
  font-size: 12px;
}
.form-group.label-floating label.control-label,
.form-group.label-placeholder label.control-label {
  top: -7px;
  font-size: 16px;
  line-height: 1.42857;
}
.form-group.label-static label.control-label,
.form-group.label-floating.is-focused label.control-label,
.form-group.label-floating:not(.is-empty) label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07143;
}
.form-group.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -30px;
  left: 0;
  font-size: 12px;
  line-height: 1.07143;
}
.form-group.form-group-sm {
  padding-bottom: 3px;
  margin: 21px 0 0;
}
.form-group.form-group-sm .form-control {
  margin-bottom: 3px;
}
.form-group.form-group-sm .form-control::-moz-placeholder {
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-sm .form-control:-ms-input-placeholder {
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-sm .form-control::-webkit-input-placeholder {
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-sm .checkbox label,
.form-group.form-group-sm .radio label,
.form-group.form-group-sm label {
  font-size: 12px;
  line-height: 1.5;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-sm label.control-label {
  font-size: 9px;
  line-height: 1.125;
  font-weight: 400;
  margin: 16px 0 0;
}
.form-group.form-group-sm .help-block {
  margin-top: 0;
  font-size: 9px;
}
.form-group.form-group-sm.label-floating label.control-label,
.form-group.form-group-sm.label-placeholder label.control-label {
  top: -11px;
  font-size: 12px;
  line-height: 1.5;
}
.form-group.form-group-sm.label-static label.control-label,
.form-group.form-group-sm.label-floating.is-focused label.control-label,
.form-group.form-group-sm.label-floating:not(.is-empty) label.control-label {
  top: -26px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-sm.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -26px;
  left: 0;
  font-size: 9px;
  line-height: 1.125;
}
.form-group.form-group-lg {
  padding-bottom: 9px;
  margin: 31px 0 0;
}
.form-group.form-group-lg .form-control {
  margin-bottom: 9px;
}
.form-group.form-group-lg .form-control::-moz-placeholder {
  font-size: 20px;
  line-height: 1.33333;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-lg .form-control:-ms-input-placeholder {
  font-size: 20px;
  line-height: 1.33333;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-lg .form-control::-webkit-input-placeholder {
  font-size: 20px;
  line-height: 1.33333;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-lg .checkbox label,
.form-group.form-group-lg .radio label,
.form-group.form-group-lg label {
  font-size: 20px;
  line-height: 1.33333;
  color: #bdbdbd;
  font-weight: 400;
}
.form-group.form-group-lg label.control-label {
  font-size: 15px;
  line-height: 1;
  font-weight: 400;
  margin: 16px 0 0;
}
.form-group.form-group-lg .help-block {
  margin-top: 0;
  font-size: 15px;
}
.form-group.form-group-lg.label-floating label.control-label,
.form-group.form-group-lg.label-placeholder label.control-label {
  top: -5px;
  font-size: 20px;
  line-height: 1.33333;
}
.form-group.form-group-lg.label-static label.control-label,
.form-group.form-group-lg.label-floating.is-focused label.control-label,
.form-group.form-group-lg.label-floating:not(.is-empty) label.control-label {
  top: -34px;
  left: 0;
  font-size: 15px;
  line-height: 1;
}
.form-group.form-group-lg.label-floating
  input.form-control:-webkit-autofill
  ~ label.control-label
  label.control-label {
  top: -34px;
  left: 0;
  font-size: 15px;
  line-height: 1;
}
select.form-control {
  border: 0;
  box-shadow: none;
  border-radius: 0;
}
.form-group.is-focused select.form-control {
  box-shadow: none;
  border-color: #d2d2d2;
}
select.form-control[multiple],
.form-group.is-focused select.form-control[multiple] {
  height: 85px;
}
.input-group-btn .btn {
  margin: 0 0 7px;
}
.form-group.form-group-sm .input-group-btn .btn {
  margin: 0 0 3px;
}
.form-group.form-group-lg .input-group-btn .btn {
  margin: 0 0 9px;
}
.input-group .input-group-btn {
  padding: 0 12px;
}
.input-group .input-group-addon {
  border: 0;
  background: transparent;
}
.form-group input[type="file"] {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
}
legend {
  border-bottom: 0;
}
.list-group {
  border-radius: 0;
}
.list-group .list-group-item {
  background-color: transparent;
  overflow: hidden;
  border: 0;
  border-radius: 0;
  padding: 0 16px;
}
.list-group .list-group-item.baseline {
  border-bottom: 1px solid #cecece;
}
.list-group .list-group-item.baseline:last-child {
  border-bottom: none;
}
.list-group .list-group-item .row-picture,
.list-group .list-group-item .row-action-primary {
  display: inline-block;
  padding-right: 16px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary img,
.list-group .list-group-item .row-action-primary i,
.list-group .list-group-item .row-action-primary label {
  display: block;
  width: 56px;
  height: 56px;
}
.list-group .list-group-item .row-picture img,
.list-group .list-group-item .row-action-primary img {
  background: rgba(0, 0, 0, 0.1);
  padding: 1px;
}
.list-group .list-group-item .row-picture img.circle,
.list-group .list-group-item .row-action-primary img.circle {
  border-radius: 100%;
}
.list-group .list-group-item .row-picture i,
.list-group .list-group-item .row-action-primary i {
  background: rgba(0, 0, 0, 0.25);
  border-radius: 100%;
  text-align: center;
  line-height: 56px;
  font-size: 20px;
  color: white;
}
.list-group .list-group-item .row-picture label,
.list-group .list-group-item .row-action-primary label {
  margin-left: 7px;
  margin-right: -7px;
  margin-top: 5px;
  margin-bottom: -5px;
}
.list-group .list-group-item .row-picture label .checkbox-material,
.list-group .list-group-item .row-action-primary label .checkbox-material {
  left: -10px;
}
.list-group .list-group-item .row-content {
  display: inline-block;
  width: calc(100% - 92px);
  min-height: 66px;
}
.list-group .list-group-item .row-content .action-secondary {
  position: absolute;
  right: 16px;
  top: 16px;
}
.list-group .list-group-item .row-content .action-secondary i {
  font-size: 20px;
  color: rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.list-group .list-group-item .row-content .action-secondary ~ * {
  max-width: calc(100% - 30px);
}
.list-group .list-group-item .row-content .least-content {
  position: absolute;
  right: 16px;
  top: 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.list-group .list-group-item .list-group-item-heading {
  color: rgba(0, 0, 0, 0.77);
  font-size: 20px;
  line-height: 29px;
}
.list-group .list-group-item.active:hover,
.list-group .list-group-item.active:focus {
  background: rgba(0, 0, 0, 0.15);
  outline: 10px solid rgba(0, 0, 0, 0.15);
}
.list-group .list-group-item.active .list-group-item-heading,
.list-group .list-group-item.active .list-group-item-text {
  color: rgba(0, 0, 0, 0.87);
}
.list-group .list-group-separator {
  clear: both;
  overflow: hidden;
  margin-top: 10px;
  margin-bottom: 10px;
}
.list-group .list-group-separator:before {
  content: "";
  width: calc(100% - 90px);
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  float: right;
}
.navbar {
  background-color: #8bc34a;
  border: 0;
  border-radius: 0;
}
.navbar .navbar-brand {
  position: relative;
  height: 60px;
  line-height: 30px;
  color: inherit;
}
.navbar .navbar-brand:hover,
.navbar .navbar-brand:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-text {
  color: inherit;
  margin-top: 20px;
  margin-bottom: 20px;
}
.navbar .navbar-nav > li > a {
  color: inherit;
  padding-top: 20px;
  padding-bottom: 20px;
}
.navbar .navbar-nav > li > a:hover,
.navbar .navbar-nav > li > a:focus {
  color: inherit;
  background-color: transparent;
}
.navbar .navbar-nav > .active > a,
.navbar .navbar-nav > .active > a:hover,
.navbar .navbar-nav > .active > a:focus {
  color: inherit;
  background-color: rgba(255, 255, 255, 0.1);
}
.navbar .navbar-nav > .disabled > a,
.navbar .navbar-nav > .disabled > a:hover,
.navbar .navbar-nav > .disabled > a:focus {
  color: inherit;
  background-color: transparent;
  opacity: 0.9;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=90)";
}
.navbar .navbar-toggle {
  border: 0;
}
.navbar .navbar-toggle:hover,
.navbar .navbar-toggle:focus {
  background-color: transparent;
}
.navbar .navbar-toggle .icon-bar {
  background-color: inherit;
  border: 1px solid;
}
.navbar .navbar-default .navbar-toggle,
.navbar .navbar-inverse .navbar-toggle {
  border-color: transparent;
}
.navbar .navbar-collapse,
.navbar .navbar-form {
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar .navbar-nav > .open > a,
.navbar .navbar-nav > .open > a:hover,
.navbar .navbar-nav > .open > a:focus {
  background-color: transparent;
  color: inherit;
}
@media (max-width: 767px) {
  .navbar .navbar-nav .navbar-text {
    color: inherit;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .navbar .navbar-nav .dropdown .dropdown-toggle .caret {
    display: none;
  }
  .navbar .navbar-nav .dropdown .dropdown-toggle:after {
    content: "keyboard_arrow_right";
    font-family: Material Icons;
    font-size: 1.5em;
    float: right;
  }
  .navbar .navbar-nav .dropdown .dropdown-menu {
    margin-left: 20px;
  }
  .navbar .navbar-nav .dropdown.open .dropdown-toggle:after {
    content: "keyboard_arrow_down";
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .dropdown-header {
    border: 0;
    color: inherit;
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu .divider {
    border-bottom: 1px solid;
    opacity: 0.08;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=8)";
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu > li > a {
    color: inherit;
    font-size: inherit;
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu > li > a:hover,
  .navbar .navbar-nav .dropdown.open .dropdown-menu > li > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .active > a,
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .active > a:focus {
    color: inherit;
    background-color: transparent;
  }
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .disabled > a,
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .disabled > a:hover,
  .navbar .navbar-nav .dropdown.open .dropdown-menu > .disabled > a:focus {
    color: inherit;
    background-color: transparent;
  }
}
.navbar .navbar-link {
  color: inherit;
}
.navbar .navbar-link:hover {
  color: inherit;
}
.navbar .btn-link {
  color: inherit;
}
.navbar .btn-link:hover,
.navbar .btn-link:focus {
  color: inherit;
}
.navbar .btn-link[disabled]:hover,
.navbar .btn-link[disabled]:focus,
fieldset[disabled] .navbar .btn-link:hover,
fieldset[disabled] .navbar .btn-link:focus {
  color: inherit;
}
.navbar .navbar-form {
  margin-top: 16px;
}
.navbar .navbar-form .form-group {
  margin: 0;
  padding: 0;
}
.navbar .navbar-form .form-group .material-input:before,
.navbar .navbar-form .form-group.is-focused .material-input:after {
  background-color: inherit;
}
.navbar .navbar-form .form-group .form-control,
.navbar .navbar-form .form-control {
  border-color: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  height: 30px;
  font-size: 16px;
  line-height: 1.42857;
}
.navbar,
.navbar.navbar-default {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar .navbar-form .form-group input.form-control::-moz-placeholder,
.navbar .navbar-form input.form-control::-moz-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-default .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar .navbar-form .form-group input.form-control:-ms-input-placeholder,
.navbar .navbar-form input.form-control:-ms-input-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-default .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar .navbar-form .form-group input.form-control::-webkit-input-placeholder,
.navbar .navbar-form input.form-control::-webkit-input-placeholder,
.navbar.navbar-default
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-default
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar .dropdown-menu,
.navbar.navbar-default .dropdown-menu {
  border-radius: 2px;
}
.navbar .dropdown-menu li > a,
.navbar.navbar-default .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar .dropdown-menu li > a:hover,
.navbar .dropdown-menu li > a:focus,
.navbar.navbar-default .dropdown-menu li > a:hover,
.navbar.navbar-default .dropdown-menu li > a:focus {
  color: #8bc34a;
  background-color: #eee;
}
.navbar .dropdown-menu .active > a,
.navbar.navbar-default .dropdown-menu .active > a {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar .dropdown-menu .active > a:hover,
.navbar .dropdown-menu .active > a:focus,
.navbar.navbar-default .dropdown-menu .active > a:hover,
.navbar.navbar-default .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-inverse {
  background-color: #3f51b5;
  color: #fff;
}
.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control::-moz-placeholder {
  color: #fff;
}
.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-inverse .navbar-form input.form-control:-ms-input-placeholder {
  color: #fff;
}
.navbar.navbar-inverse
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-inverse
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: #fff;
}
.navbar.navbar-inverse .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-inverse .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-inverse .dropdown-menu li > a:hover,
.navbar.navbar-inverse .dropdown-menu li > a:focus {
  color: #3f51b5;
  background-color: #eee;
}
.navbar.navbar-inverse .dropdown-menu .active > a {
  background-color: #3f51b5;
  color: #fff;
}
.navbar.navbar-inverse .dropdown-menu .active > a:hover,
.navbar.navbar-inverse .dropdown-menu .active > a:focus {
  color: #fff;
}
.navbar.navbar-primary {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-primary .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-primary .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-primary
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-primary
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-primary .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-primary .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-primary .dropdown-menu li > a:hover,
.navbar.navbar-primary .dropdown-menu li > a:focus {
  color: #8bc34a;
  background-color: #eee;
}
.navbar.navbar-primary .dropdown-menu .active > a {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-primary .dropdown-menu .active > a:hover,
.navbar.navbar-primary .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-success .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-success .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-success
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-success .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-success .dropdown-menu li > a:hover,
.navbar.navbar-success .dropdown-menu li > a:focus {
  color: #4caf50;
  background-color: #eee;
}
.navbar.navbar-success .dropdown-menu .active > a {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-success .dropdown-menu .active > a:hover,
.navbar.navbar-success .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-info .navbar-form input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-info .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-info .dropdown-menu li > a:hover,
.navbar.navbar-info .dropdown-menu li > a:focus {
  color: #00bcd4;
  background-color: #eee;
}
.navbar.navbar-info .dropdown-menu .active > a {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-info .dropdown-menu .active > a:hover,
.navbar.navbar-info .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning {
  background-color: #ff9800;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-warning .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-warning .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-warning
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-warning .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-warning .dropdown-menu li > a:hover,
.navbar.navbar-warning .dropdown-menu li > a:focus {
  color: #ff9800;
  background-color: #eee;
}
.navbar.navbar-warning .dropdown-menu .active > a {
  background-color: #ff9800;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-warning .dropdown-menu .active > a:hover,
.navbar.navbar-warning .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-danger .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-danger .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-danger
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-danger .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-danger .dropdown-menu li > a:hover,
.navbar.navbar-danger .dropdown-menu li > a:focus {
  color: #f44336;
  background-color: #eee;
}
.navbar.navbar-danger .dropdown-menu .active > a {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-danger .dropdown-menu .active > a:hover,
.navbar.navbar-danger .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal
  .navbar-form
  .form-group
  input.form-control::-moz-placeholder,
.navbar.navbar-royal .navbar-form input.form-control::-moz-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal
  .navbar-form
  .form-group
  input.form-control:-ms-input-placeholder,
.navbar.navbar-royal .navbar-form input.form-control:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal
  .navbar-form
  .form-group
  input.form-control::-webkit-input-placeholder,
.navbar.navbar-royal
  .navbar-form
  input.form-control::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal .dropdown-menu {
  border-radius: 2px;
}
.navbar.navbar-royal .dropdown-menu li > a {
  font-size: 16px;
  padding: 13px 16px;
}
.navbar.navbar-royal .dropdown-menu li > a:hover,
.navbar.navbar-royal .dropdown-menu li > a:focus {
  color: #9c27b0;
  background-color: #eee;
}
.navbar.navbar-royal .dropdown-menu .active > a {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.navbar.navbar-royal .dropdown-menu .active > a:hover,
.navbar.navbar-royal .dropdown-menu .active > a:focus {
  color: rgba(255, 255, 255, 0.84);
}
.navbar-inverse {
  background-color: #3f51b5;
}
@media (max-width: 1199px) {
  .navbar .navbar-brand {
    height: 50px;
    padding: 10px 15px;
  }
  .navbar .navbar-form {
    margin-top: 10px;
  }
  .navbar .navbar-nav > li > a {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}
.dropdown-menu {
  border: 0;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.26);
}
.dropdown-menu .divider {
  background-color: rgba(0, 0, 0, 0.12);
}
.dropdown-menu li {
  overflow: hidden;
  position: relative;
}
.dropdown-menu li a:hover {
  background-color: transparent;
  color: #8bc34a;
}
.alert {
  border: 0;
  border-radius: 0;
}
.alert,
.alert.alert-default {
  background-color: rgba(255, 255, 255, 0.84);
  color: rgba(255, 255, 255, 0.84);
}
.alert a,
.alert .alert-link,
.alert.alert-default a,
.alert.alert-default .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-inverse {
  background-color: #3f51b5;
  color: #fff;
}
.alert.alert-inverse a,
.alert.alert-inverse .alert-link {
  color: #fff;
}
.alert.alert-primary {
  background-color: #8bc34a;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-primary a,
.alert.alert-primary .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-success {
  background-color: #4caf50;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-success a,
.alert.alert-success .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-info {
  background-color: #00bcd4;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-info a,
.alert.alert-info .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-warning {
  background-color: #ff9800;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-warning a,
.alert.alert-warning .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-danger {
  background-color: #f44336;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-danger a,
.alert.alert-danger .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-royal {
  background-color: #9c27b0;
  color: rgba(255, 255, 255, 0.84);
}
.alert.alert-royal a,
.alert.alert-royal .alert-link {
  color: rgba(255, 255, 255, 0.84);
}
.alert-info,
.alert-danger,
.alert-warning,
.alert-success {
  color: rgba(255, 255, 255, 0.84);
}
.alert-default a,
.alert-default .alert-link {
  color: rgba(0, 0, 0, 0.87);
}
.progress {
  border-radius: 0;
  box-shadow: none;
  background: #c8c8c8;
}
.progress .progress-bar {
  box-shadow: none;
}
.progress .progress-bar,
.progress .progress-bar.progress-bar-default {
  background-color: #8bc34a;
}
.progress .progress-bar.progress-bar-inverse {
  background-color: #3f51b5;
}
.progress .progress-bar.progress-bar-primary {
  background-color: #8bc34a;
}
.progress .progress-bar.progress-bar-success {
  background-color: #4caf50;
}
.progress .progress-bar.progress-bar-info {
  background-color: #00bcd4;
}
.progress .progress-bar.progress-bar-warning {
  background-color: #ff9800;
}
.progress .progress-bar.progress-bar-danger {
  background-color: #f44336;
}
.progress .progress-bar.progress-bar-royal {
  background-color: #9c27b0;
}
.text-warning {
  color: #ff9800;
}
.text-primary {
  color: #8bc34a;
}
.text-danger {
  color: #f44336;
}
.text-success {
  color: #4caf50;
}
.text-info {
  color: #00bcd4;
}
.nav-tabs {
  background: #8bc34a;
}
.nav-tabs > li > a {
  color: #fff;
  border: 0;
  margin: 0;
}
.nav-tabs > li > a:hover {
  background-color: transparent;
  border: 0;
}
.nav-tabs > li > a,
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  background-color: transparent !important;
  border: 0 !important;
  color: #fff !important;
  font-weight: 500;
}
.nav-tabs > li.disabled > a,
.nav-tabs > li.disabled > a:hover {
  color: rgba(255, 255, 255, 0.5);
}
.popover,
.tooltip-inner {
  color: #ececec;
  line-height: 1em;
  background: rgba(101, 101, 101, 0.9);
  border: none;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.tooltip,
.tooltip.in {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.popover .arrow,
.popover .tooltip-arrow,
.tooltip .arrow,
.tooltip .tooltip-arrow {
  display: none;
}
.modal-content {
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
  border-radius: 2px;
  border: none;
}
.modal-content .modal-header {
  border-bottom: none;
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 0;
  padding-left: 24px;
}
.modal-content .modal-body {
  padding-top: 24px;
  padding-right: 24px;
  padding-bottom: 16px;
  padding-left: 24px;
}
.modal-content .modal-footer {
  border-top: none;
  padding: 7px;
}
.modal-content .modal-footer button {
  margin: 0;
  padding-left: 16px;
  padding-right: 16px;
  width: auto;
}
.modal-content .modal-footer button.pull-left {
  padding-left: 5px;
  padding-right: 5px;
  position: relative;
  left: -5px;
}
.modal-content .modal-footer button + button {
  margin-bottom: 16px;
}
.modal-content .modal-body + .modal-footer {
  padding-top: 0;
}
.modal-backdrop {
  background: rgba(0, 0, 0, 0.3);
}
.panel {
  border-radius: 2px;
  border: 0;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
}
.panel > .panel-heading,
.panel.panel-default > .panel-heading {
  background-color: #eee;
}
.panel.panel-inverse > .panel-heading {
  background-color: #3f51b5;
}
.panel.panel-primary > .panel-heading {
  background-color: #8bc34a;
}
.panel.panel-success > .panel-heading {
  background-color: #4caf50;
}
.panel.panel-info > .panel-heading {
  background-color: #00bcd4;
}
.panel.panel-warning > .panel-heading {
  background-color: #ff9800;
}
.panel.panel-danger > .panel-heading {
  background-color: #f44336;
}
.panel.panel-royal > .panel-heading {
  background-color: #9c27b0;
}
[class*="panel-"] > .panel-heading {
  color: rgba(255, 255, 255, 0.84);
  border: 0;
}
.panel-default > .panel-heading,
.panel:not([class*="panel-"]) > .panel-heading {
  color: rgba(0, 0, 0, 0.87);
}
.panel-footer {
  background-color: #eee;
}
hr.on-dark {
  color: #1a1a1a;
}
hr.on-light {
  color: white;
}
@media (-webkit-min-device-pixel-ratio: 0.75),
  (min--moz-device-pixel-ratio: 0.75),
  (-o-device-pixel-ratio: 3 / 4),
  (min-device-pixel-ratio: 0.75),
  (min-resolution: 0.75dppx),
  (-webkit-min-device-pixel-ratio: 1.25),
  (min-resolution: 120dpi) {
  hr {
    height: 0.75px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1),
  (min--moz-device-pixel-ratio: 1),
  (-o-device-pixel-ratio: 1),
  (min-device-pixel-ratio: 1),
  (min-resolution: 1dppx),
  (-webkit-min-device-pixel-ratio: 1.6666666666666667),
  (min-resolution: 160dpi) {
  hr {
    height: 1px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.33),
  (min--moz-device-pixel-ratio: 1.33),
  (-o-device-pixel-ratio: 133 / 100),
  (min-device-pixel-ratio: 1.33),
  (min-resolution: 1.33dppx),
  (-webkit-min-device-pixel-ratio: 2.21875),
  (min-resolution: 213dpi) {
  hr {
    height: 1.333px;
  }
}
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (-o-device-pixel-ratio: 3 / 2),
  (min-device-pixel-ratio: 1.5),
  (min-resolution: 1.5dppx),
  (-webkit-min-device-pixel-ratio: 2.5),
  (min-resolution: 240dpi) {
  hr {
    height: 1.5px;
  }
}
@media (-webkit-min-device-pixel-ratio: 2),
  (min--moz-device-pixel-ratio: 2),
  (-o-device-pixel-ratio: 2 / 1),
  (min-device-pixel-ratio: 2),
  (min-resolution: 2dppx),
  (-webkit-min-device-pixel-ratio: 3.9583333333333335),
  (min-resolution: 380dpi) {
  hr {
    height: 2px;
  }
}
@media (-webkit-min-device-pixel-ratio: 3),
  (min--moz-device-pixel-ratio: 3),
  (-o-device-pixel-ratio: 3 / 1),
  (min-device-pixel-ratio: 3),
  (min-resolution: 3dppx),
  (-webkit-min-device-pixel-ratio: 5),
  (min-resolution: 480dpi) {
  hr {
    height: 3px;
  }
}
@media (-webkit-min-device-pixel-ratio: 4),
  (min--moz-device-pixel-ratio: 4),
  (-o-device-pixel-ratio: 4 / 1),
  (min-device-pixel-ratio: 3),
  (min-resolution: 4dppx),
  (-webkit-min-device-pixel-ratio: 6.666666666666667),
  (min-resolution: 640dpi) {
  hr {
    height: 4px;
  }
}
* {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-tap-highlight-color: transparent;
}
*:focus {
  outline: 0;
}
.snackbar {
  background-color: #323232;
  color: rgba(255, 255, 255, 0.84);
  font-size: 14px;
  border-radius: 2px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12);
  height: 0;
  transition: opacity 0.2s ease-in, height 0s linear 0.2s,
    padding 0s linear 0.2s, height 0s linear 0.2s,
    -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, padding 0s linear 0.2s, height 0s linear 0.2s,
    -webkit-transform 0.2s ease-in-out;
  -webkit-transform: translateY(200%);
  transform: translateY(200%);
}
.snackbar.snackbar-opened {
  padding: 14px 15px;
  margin-bottom: 20px;
  height: auto;
  transition: opacity 0.2s ease-in, height 0s linear 0.2s, height 0s linear 0.2s,
    -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, height 0s linear 0.2s;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in,
    height 0s linear 0.2s, height 0s linear 0.2s,
    -webkit-transform 0.2s ease-in-out;
  -webkit-transform: none;
  transform: none;
}
.snackbar.toast {
  border-radius: 200px;
}
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -ms-touch-action: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
}
.noUi-base {
  width: 100%;
  height: 100%;
  position: relative;
}
.noUi-origin {
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
}
.noUi-handle {
  position: relative;
  z-index: 1;
  box-sizing: border-box;
}
.noUi-stacking .noUi-handle {
  z-index: 10;
}
.noUi-state-tap .noUi-origin {
  transition: left 0.3s, top 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
.noUi-horizontal {
  height: 10px;
}
.noUi-handle {
  box-sizing: border-box;
  width: 12px;
  height: 12px;
  left: -10px;
  top: -5px;
  cursor: ew-resize;
  border-radius: 100%;
  transition: all 0.2s ease-out;
  border: 1px solid;
}
.noUi-vertical .noUi-handle {
  margin-left: 5px;
  cursor: ns-resize;
}
.noUi-horizontal.noUi-extended {
  padding: 0 15px;
}
.noUi-horizontal.noUi-extended .noUi-origin {
  right: -15px;
}
.noUi-background {
  height: 2px;
  margin: 20px 0;
}
.noUi-origin {
  margin: 0;
  border-radius: 0;
  height: 2px;
  background: #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle {
  background-color: #fff;
  border: 2px solid #c8c8c8;
}
.noUi-origin[style^="left: 0"] .noUi-handle.noUi-active {
  border-width: 1px;
}
.noUi-target {
  border-radius: 2px;
}
.noUi-horizontal {
  height: 2px;
  margin: 15px 0;
}
.noUi-vertical {
  height: 100%;
  width: 2px;
  margin: 0 15px;
  display: inline-block;
}
.noUi-handle.noUi-active {
  -webkit-transform: scale3d(2.5, 2.5, 1);
  transform: scale3d(2.5, 2.5, 1);
}
[disabled].noUi-slider {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}
[disabled] .noUi-handle {
  cursor: not-allowed;
}
.slider {
  background: #c8c8c8;
}
.slider.noUi-connect,
.slider.slider-default.noUi-connect {
  background-color: #8bc34a;
}
.slider.slider-inverse.noUi-connect {
  background-color: #3f51b5;
}
.slider.slider-primary.noUi-connect {
  background-color: #8bc34a;
}
.slider.slider-success.noUi-connect {
  background-color: #4caf50;
}
.slider.slider-info.noUi-connect {
  background-color: #00bcd4;
}
.slider.slider-warning.noUi-connect {
  background-color: #ff9800;
}
.slider.slider-danger.noUi-connect {
  background-color: #f44336;
}
.slider.slider-royal.noUi-connect {
  background-color: #9c27b0;
}
.slider .noUi-connect,
.slider.slider-default .noUi-connect {
  background-color: #8bc34a;
}
.slider.slider-inverse .noUi-connect {
  background-color: #3f51b5;
}
.slider.slider-primary .noUi-connect {
  background-color: #8bc34a;
}
.slider.slider-success .noUi-connect {
  background-color: #4caf50;
}
.slider.slider-info .noUi-connect {
  background-color: #00bcd4;
}
.slider.slider-warning .noUi-connect {
  background-color: #ff9800;
}
.slider.slider-danger .noUi-connect {
  background-color: #f44336;
}
.slider.slider-royal .noUi-connect {
  background-color: #9c27b0;
}
.slider .noUi-handle,
.slider.slider-default .noUi-handle {
  background-color: #8bc34a;
}
.slider.slider-inverse .noUi-handle {
  background-color: #3f51b5;
}
.slider.slider-primary .noUi-handle {
  background-color: #8bc34a;
}
.slider.slider-success .noUi-handle {
  background-color: #4caf50;
}
.slider.slider-info .noUi-handle {
  background-color: #00bcd4;
}
.slider.slider-warning .noUi-handle {
  background-color: #ff9800;
}
.slider.slider-danger .noUi-handle {
  background-color: #f44336;
}
.slider.slider-royal .noUi-handle {
  background-color: #9c27b0;
}
.slider .noUi-handle,
.slider.slider-default .noUi-handle {
  border-color: #8bc34a;
}
.slider.slider-inverse .noUi-handle {
  border-color: #3f51b5;
}
.slider.slider-primary .noUi-handle {
  border-color: #8bc34a;
}
.slider.slider-success .noUi-handle {
  border-color: #4caf50;
}
.slider.slider-info .noUi-handle {
  border-color: #00bcd4;
}
.slider.slider-warning .noUi-handle {
  border-color: #ff9800;
}
.slider.slider-danger .noUi-handle {
  border-color: #f44336;
}
.slider.slider-royal .noUi-handle {
  border-color: #9c27b0;
}
.selectize-control.single,
.selectize-control.multi {
  padding: 0;
}
.selectize-control.single .selectize-input,
.selectize-control.single .selectize-input.input-active,
.selectize-control.multi .selectize-input,
.selectize-control.multi .selectize-input.input-active {
  cursor: text;
  background: transparent;
  box-shadow: none;
  border: 0;
  padding: 0;
  height: 100%;
  font-size: 14px;
  line-height: 30px;
}
.selectize-control.single .selectize-input .has-items,
.selectize-control.single .selectize-input.input-active .has-items,
.selectize-control.multi .selectize-input .has-items,
.selectize-control.multi .selectize-input.input-active .has-items {
  padding: 0;
}
.selectize-control.single .selectize-input:after,
.selectize-control.single .selectize-input.input-active:after,
.selectize-control.multi .selectize-input:after,
.selectize-control.multi .selectize-input.input-active:after {
  right: 5px;
  position: absolute;
  font-size: 25px;
  content: "\e5c5";
  font-family: Material Icons;
  speak: none;
  font-style: normal;
  font-weight: 400;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.selectize-control.single .selectize-input input,
.selectize-control.single .selectize-input.input-active input,
.selectize-control.multi .selectize-input input,
.selectize-control.multi .selectize-input.input-active input {
  font-size: 14px;
  outline: 0;
  border: 0;
  background: transparent;
}
.selectize-control.single .selectize-input.label-floating-fix input,
.selectize-control.single
  .selectize-input.input-active.label-floating-fix
  input,
.selectize-control.multi .selectize-input.label-floating-fix input,
.selectize-control.multi
  .selectize-input.input-active.label-floating-fix
  input {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.selectize-control.single .selectize-input > div,
.selectize-control.single .selectize-input > .item,
.selectize-control.single .selectize-input.input-active > div,
.selectize-control.single .selectize-input.input-active > .item,
.selectize-control.multi .selectize-input > div,
.selectize-control.multi .selectize-input > .item,
.selectize-control.multi .selectize-input.input-active > div,
.selectize-control.multi .selectize-input.input-active > .item {
  display: inline-block;
  margin: 0 8px 3px 0;
  padding: 0;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-input > div:after,
.selectize-control.single .selectize-input > .item:after,
.selectize-control.single .selectize-input.input-active > div:after,
.selectize-control.single .selectize-input.input-active > .item:after,
.selectize-control.multi .selectize-input > div:after,
.selectize-control.multi .selectize-input > .item:after,
.selectize-control.multi .selectize-input.input-active > div:after,
.selectize-control.multi .selectize-input.input-active > .item:after {
  content: ",";
}
.selectize-control.single .selectize-input > div:last-of-type:after,
.selectize-control.single .selectize-input > .item:last-of-type:after,
.selectize-control.single
  .selectize-input.input-active
  > div:last-of-type:after,
.selectize-control.single
  .selectize-input.input-active
  > .item:last-of-type:after,
.selectize-control.multi .selectize-input > div:last-of-type:after,
.selectize-control.multi .selectize-input > .item:last-of-type:after,
.selectize-control.multi .selectize-input.input-active > div:last-of-type:after,
.selectize-control.multi
  .selectize-input.input-active
  > .item:last-of-type:after {
  content: "";
}
.selectize-control.single .selectize-input > div.active,
.selectize-control.single .selectize-input > .item.active,
.selectize-control.single .selectize-input.input-active > div.active,
.selectize-control.single .selectize-input.input-active > .item.active,
.selectize-control.multi .selectize-input > div.active,
.selectize-control.multi .selectize-input > .item.active,
.selectize-control.multi .selectize-input.input-active > div.active,
.selectize-control.multi .selectize-input.input-active > .item.active {
  font-weight: 700;
  background: transparent;
  border: 0;
}
.selectize-control.single .selectize-dropdown,
.selectize-control.multi .selectize-dropdown {
  position: absolute;
  z-index: 1000;
  border: 0;
  width: 100% !important;
  left: 0 !important;
  height: auto;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 2px;
  padding: 0;
  margin-top: 3px;
}
.selectize-control.single .selectize-dropdown .active,
.selectize-control.multi .selectize-dropdown .active {
  background-color: inherit;
}
.selectize-control.single .selectize-dropdown .highlight,
.selectize-control.multi .selectize-dropdown .highlight {
  background-color: #d5d8ff;
}
.selectize-control.single .selectize-dropdown .selected,
.selectize-control.single .selectize-dropdown .selected.active,
.selectize-control.multi .selectize-dropdown .selected,
.selectize-control.multi .selectize-dropdown .selected.active {
  background-color: #eee;
}
.selectize-control.single .selectize-dropdown [data-selectable],
.selectize-control.single .selectize-dropdown .optgroup-header,
.selectize-control.multi .selectize-dropdown [data-selectable],
.selectize-control.multi .selectize-dropdown .optgroup-header {
  padding: 10px 20px;
  cursor: pointer;
}
.selectize-control.single .dropdown-active ~ .selectize-dropdown,
.selectize-control.multi .dropdown-active ~ .selectize-dropdown {
  display: block;
}
.dropdownjs:after {
  right: 5px;
  top: 3px;
  font-size: 25px;
  position: absolute;
  font-family: Material Icons;
  font-style: normal;
  font-weight: 400;
  content: "\e5c5";
  pointer-events: none;
  color: #757575;
}
.animation-delay-1 {
  -webkit-animation-delay: 0.1s !important;
  animation-delay: 0.1s !important;
}
.animation-delay-2 {
  -webkit-animation-delay: 0.2s !important;
  animation-delay: 0.2s !important;
}
.animation-delay-3 {
  -webkit-animation-delay: 0.3s !important;
  animation-delay: 0.3s !important;
}
.animation-delay-4 {
  -webkit-animation-delay: 0.4s !important;
  animation-delay: 0.4s !important;
}
.animation-delay-5 {
  -webkit-animation-delay: 0.5s !important;
  animation-delay: 0.5s !important;
}
.animation-delay-6 {
  -webkit-animation-delay: 0.6s !important;
  animation-delay: 0.6s !important;
}
.animation-delay-7 {
  -webkit-animation-delay: 0.7s !important;
  animation-delay: 0.7s !important;
}
.animation-delay-8 {
  -webkit-animation-delay: 0.8s !important;
  animation-delay: 0.8s !important;
}
.animation-delay-9 {
  -webkit-animation-delay: 0.9s !important;
  animation-delay: 0.9s !important;
}
.animation-delay-10 {
  -webkit-animation-delay: 1s !important;
  animation-delay: 1s !important;
}
.animation-delay-11 {
  -webkit-animation-delay: 1.1s !important;
  animation-delay: 1.1s !important;
}
.animation-delay-12 {
  -webkit-animation-delay: 1.2s !important;
  animation-delay: 1.2s !important;
}
.animation-delay-13 {
  -webkit-animation-delay: 1.3s !important;
  animation-delay: 1.3s !important;
}
.animation-delay-14 {
  -webkit-animation-delay: 1.4s !important;
  animation-delay: 1.4s !important;
}
.animation-delay-15 {
  -webkit-animation-delay: 1.5s !important;
  animation-delay: 1.5s !important;
}
.animation-delay-16 {
  -webkit-animation-delay: 1.6s !important;
  animation-delay: 1.6s !important;
}
.animation-delay-17 {
  -webkit-animation-delay: 1.7s !important;
  animation-delay: 1.7s !important;
}
.animation-delay-18 {
  -webkit-animation-delay: 1.8s !important;
  animation-delay: 1.8s !important;
}
.animation-delay-19 {
  -webkit-animation-delay: 1.9s !important;
  animation-delay: 1.9s !important;
}
.animation-delay-20 {
  -webkit-animation-delay: 2s !important;
  animation-delay: 2s !important;
}
.animation-delay-21 {
  -webkit-animation-delay: 2.1s !important;
  animation-delay: 2.1s !important;
}
.animation-delay-22 {
  -webkit-animation-delay: 2.2s !important;
  animation-delay: 2.2s !important;
}
.animation-delay-23 {
  -webkit-animation-delay: 2.3s !important;
  animation-delay: 2.3s !important;
}
.animation-delay-24 {
  -webkit-animation-delay: 2.4s !important;
  animation-delay: 2.4s !important;
}
.animation-delay-25 {
  -webkit-animation-delay: 2.5s !important;
  animation-delay: 2.5s !important;
}
.animation-delay-26 {
  -webkit-animation-delay: 2.6s !important;
  animation-delay: 2.6s !important;
}
.animation-delay-27 {
  -webkit-animation-delay: 2.6s !important;
  animation-delay: 2.6s !important;
}
.animation-delay-28 {
  -webkit-animation-delay: 2.8s !important;
  animation-delay: 2.8s !important;
}
.animation-delay-29 {
  -webkit-animation-delay: 2.9s !important;
  animation-delay: 2.9s !important;
}
.animation-delay-30 {
  -webkit-animation-delay: 3s !important;
  animation-delay: 3s !important;
}
.animation-delay-31 {
  -webkit-animation-delay: 3.1s !important;
  animation-delay: 3.1s !important;
}
.animation-delay-32 {
  -webkit-animation-delay: 3.2s !important;
  animation-delay: 3.2s !important;
}
.animation-delay-33 {
  -webkit-animation-delay: 3.3s !important;
  animation-delay: 3.3s !important;
}
.animation-delay-34 {
  -webkit-animation-delay: 3.4s !important;
  animation-delay: 3.4s !important;
}
.animation-delay-35 {
  -webkit-animation-delay: 3.5s !important;
  animation-delay: 3.5s !important;
}
.animation-delay-36 {
  -webkit-animation-delay: 3.6s !important;
  animation-delay: 3.6s !important;
}
.animation-delay-37 {
  -webkit-animation-delay: 3.7s !important;
  animation-delay: 3.7s !important;
}
.animation-delay-38 {
  -webkit-animation-delay: 3.8s !important;
  animation-delay: 3.8s !important;
}
.animation-delay-39 {
  -webkit-animation-delay: 3.9s !important;
  animation-delay: 3.9s !important;
}
.animation-delay-40 {
  -webkit-animation-delay: 4s !important;
  animation-delay: 4s !important;
}
.animation-delay-41 {
  -webkit-animation-delay: 4.1s !important;
  animation-delay: 4.1s !important;
}
.animation-delay-42 {
  -webkit-animation-delay: 4.2s !important;
  animation-delay: 4.2s !important;
}
.animation-delay-43 {
  -webkit-animation-delay: 4.3s !important;
  animation-delay: 4.3s !important;
}
.animation-delay-44 {
  -webkit-animation-delay: 4.4s !important;
  animation-delay: 4.4s !important;
}
.animation-delay-45 {
  -webkit-animation-delay: 4.5s !important;
  animation-delay: 4.5s !important;
}
.animation-delay-46 {
  -webkit-animation-delay: 4.6s !important;
  animation-delay: 4.6s !important;
}
.animation-delay-47 {
  -webkit-animation-delay: 4.7s !important;
  animation-delay: 4.7s !important;
}
.animation-delay-48 {
  -webkit-animation-delay: 4.8s !important;
  animation-delay: 4.8s !important;
}
.animation-delay-49 {
  -webkit-animation-delay: 4.9s !important;
  animation-delay: 4.9s !important;
}
.animation-delay-50 {
  -webkit-animation-delay: 5s !important;
  animation-delay: 5s !important;
}
.animation-delay-51 {
  -webkit-animation-delay: 5.1s !important;
  animation-delay: 5.1s !important;
}
.animation-delay-52 {
  -webkit-animation-delay: 5.2s !important;
  animation-delay: 5.2s !important;
}
.animation-delay-53 {
  -webkit-animation-delay: 5.3s !important;
  animation-delay: 5.3s !important;
}
.animation-delay-54 {
  -webkit-animation-delay: 5.4s !important;
  animation-delay: 5.4s !important;
}
.animation-delay-55 {
  -webkit-animation-delay: 5.5s !important;
  animation-delay: 5.5s !important;
}
.animation-delay-56 {
  -webkit-animation-delay: 5.6s !important;
  animation-delay: 5.6s !important;
}
.animation-delay-57 {
  -webkit-animation-delay: 5.7s !important;
  animation-delay: 5.7s !important;
}
.animation-delay-58 {
  -webkit-animation-delay: 5.8s !important;
  animation-delay: 5.8s !important;
}
.animation-delay-59 {
  -webkit-animation-delay: 5.9s !important;
  animation-delay: 5.9s !important;
}
.animation-delay-60 {
  -webkit-animation-delay: 6s !important;
  animation-delay: 6s !important;
}
.animated-2x {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
.animated-3x {
  -webkit-animation-duration: 0.25s;
  animation-duration: 0.25s;
}
.animated-slow {
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
@-webkit-keyframes flipCenter {
  from {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  40% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  100% {
    -webkit-transform: perspective(100000px);
    transform: perspective(100000px);
  }
}
@keyframes flipCenter {
  from {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 90deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  }
  40% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  60% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
  80% {
    -webkit-transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
    transform: perspective(100000px) rotate3d(1, 0, 0, 0deg);
  }
  100% {
    -webkit-transform: perspective(100000px);
    transform: perspective(100000px);
  }
}
.flipCenter {
  -webkit-animation-name: flipCenter;
  animation-name: flipCenter;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes desployDown {
  from {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}
@keyframes desployDown {
  from {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    -webkit-transform-origin: top;
    transform-origin: top;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    -webkit-transform-origin: top;
    transform-origin: top;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  }
}
.desployDown {
  -webkit-animation-name: desployDown;
  animation-name: desployDown;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes fadeInRightTiny {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInRightTiny {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInRightTiny {
  -webkit-animation-name: fadeInRightTiny;
  animation-name: fadeInRightTiny;
}
@-webkit-keyframes fadeInLeftTiny {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes fadeInLeftTiny {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: none;
    transform: none;
  }
}
.fadeInLeftTiny {
  -webkit-animation-name: fadeInLeftTiny;
  animation-name: fadeInLeftTiny;
}
@-webkit-keyframes slideInRightTiny {
  from {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInRightTiny {
  from {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.slideInRightTiny {
  -webkit-animation-name: slideInRightTiny;
  animation-name: slideInRightTiny;
}
@-webkit-keyframes slideInLeftTiny {
  from {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInLeftTiny {
  from {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.slideInLeftTiny {
  -webkit-animation-name: slideInLeftTiny;
  animation-name: slideInLeftTiny;
}
@-webkit-keyframes slideInUpTiny {
  from {
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes slideInUpTiny {
  from {
    -webkit-transform: translate3d(0, 5%, 0);
    transform: translate3d(0, 5%, 0);
  }
  to {
    -webkit-transform: none;
    transform: none;
  }
}
.slideInUpTiny {
  -webkit-animation-name: slideInUpTiny;
  animation-name: slideInUpTiny;
}
@-webkit-keyframes material {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes material {
  from {
    -webkit-transform: translate3d(110%, 0, 0);
    transform: translate3d(110%, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.material {
  -webkit-animation-name: material;
  animation-name: material;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes material-inverse {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes material-inverse {
  from {
    -webkit-transform: translate3d(-100%, 0, 0);
    transform: translate3d(-100%, 0, 0);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.material-inverse {
  -webkit-animation-name: material-inverse;
  animation-name: material-inverse;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes materialUp {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
@keyframes materialUp {
  from {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
  100% {
    -webkit-transform: none;
    transform: none;
  }
}
.materialUp {
  -webkit-animation-name: materialUp;
  animation-name: materialUp;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes colorStar {
  0% {
    color: #bdbdbd;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #bdbdbd;
  }
}
@keyframes colorStar {
  0% {
    color: #bdbdbd;
  }
  50% {
    color: #fff;
  }
  100% {
    color: #bdbdbd;
  }
}
.colorStar {
  -webkit-animation-name: colorStar;
  animation-name: colorStar;
  -webkit-animation-duration: 5s;
  animation-duration: 5s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: ease;
  animation-timing-function: ease;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}
@-webkit-keyframes img-browser-left {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
}
@keyframes img-browser-left {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-20%, 0, 0);
    transform: translate3d(-20%, 0, 0);
  }
}
@-webkit-keyframes img-browser-left-responsive {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
}
@keyframes img-browser-left-responsive {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(-15%, 0, 0);
    transform: translate3d(-15%, 0, 0);
  }
}
.img-browser-left {
  -webkit-animation-name: img-browser-left;
  animation-name: img-browser-left;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes img-browser-right {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }
}
@keyframes img-browser-right {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(20%, 0, 0);
    transform: translate3d(20%, 0, 0);
  }
}
@-webkit-keyframes img-browser-right-responsive {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(15%, 0, 0);
    transform: translate3d(15%, 0, 0);
  }
}
@keyframes img-browser-right-responsive {
  0% {
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
    visibility: visible;
  }
  50% {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  100% {
    -webkit-transform: translate3d(15%, 0, 0);
    transform: translate3d(15%, 0, 0);
  }
}
.img-browser-right {
  -webkit-animation-name: img-browser-right;
  animation-name: img-browser-right;
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@media (max-width: 991px) {
  .img-browser-right {
    -webkit-animation-name: img-browser-right-responsive;
    animation-name: img-browser-right-responsive;
  }
  .img-browser-left {
    -webkit-animation-name: img-browser-left-responsive;
    animation-name: img-browser-left-responsive;
  }
}
@-webkit-keyframes img-phone-left {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
  100% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
@keyframes img-phone-left {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
  100% {
    -webkit-transform: translate3d(-30%, 0, 0);
    transform: translate3d(-30%, 0, 0);
  }
}
.img-phone-left {
  -webkit-animation-name: img-phone-left;
  animation-name: img-phone-left;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
@-webkit-keyframes img-phone-right {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
@keyframes img-phone-right {
  0% {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    transform: scale3d(0.1, 0.1, 0.1) translate3d(0, -1000px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }
  50% {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    transform: scale3d(0.475, 0.475, 0.475) translate3d(0, 60px, 0);
    -webkit-animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);
  }
  100% {
    -webkit-transform: translate3d(30%, 0, 0);
    transform: translate3d(30%, 0, 0);
  }
}
.img-phone-right {
  -webkit-animation-name: img-phone-right;
  animation-name: img-phone-right;
  -webkit-animation-duration: 2.2s;
  animation-duration: 2.2s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.no-m {
  margin: 0 !important;
}
.no-mt {
  margin-top: 0 !important;
}
.no-mb {
  margin-bottom: 0 !important;
}
.no-ml {
  margin-left: 0 !important;
}
.no-mr {
  margin-right: 0 !important;
}
.no-p {
  padding: 0 !important;
}
.no-pt {
  padding-top: 0 !important;
}
.no-pb {
  padding-bottom: 0 !important;
}
.no-pl {
  padding-left: 0 !important;
}
.no-pr {
  padding-right: 0 !important;
}
.mb-1 {
  margin-bottom: 10px !important;
  margin-bottom: 1rem !important;
}
.mb-2 {
  margin-bottom: 20px !important;
  margin-bottom: 2rem !important;
}
.mb-3 {
  margin-bottom: 30px !important;
  margin-bottom: 3rem !important;
}
.mb-4 {
  margin-bottom: 40px !important;
  margin-bottom: 4rem !important;
}
.mb-6 {
  margin-bottom: 60px !important;
  margin-bottom: 6rem !important;
}
.mt-1 {
  margin-top: 10px !important;
  margin-top: 1rem !important;
}
.mt-2 {
  margin-top: 20px !important;
  margin-top: 2rem !important;
}
.mt-4 {
  margin-top: 40px !important;
  margin-top: 4rem !important;
}
.mt-6 {
  margin-top: 60px !important;
  margin-top: 6rem !important;
}
.mr-1 {
  margin-right: 10px !important;
  margin-right: 1rem !important;
}
.mr-2 {
  margin-right: 20px !important;
  margin-right: 2rem !important;
}
.mr-4 {
  margin-right: 40px !important;
  margin-right: 4rem !important;
}
.mr-6 {
  margin-right: 60px !important;
  margin-right: 6rem !important;
}
.ml-1 {
  margin-left: 10px !important;
  margin-left: 1rem !important;
}
.ml-2 {
  margin-left: 20px !important;
  margin-left: 2rem !important;
}
.ml-4 {
  margin-left: 40px !important;
  margin-left: 4rem !important;
}
.ml-6 {
  margin-left: 60px !important;
  margin-left: 6rem !important;
}
.p-05 {
  padding: 5px !important;
  padding: 0.5rem !important;
}
.p-1 {
  padding: 10px !important;
  padding: 1rem !important;
}
.pl-1 {
  padding-left: 10px !important;
  padding-left: 1rem !important;
}
.pr-1 {
  padding-right: 10px !important;
  padding-right: 1rem !important;
}
.pt-2 {
  padding-top: 20px !important;
  padding-top: 2rem !important;
}
.pb-2 {
  padding-bottom: 20px !important;
  padding-bottom: 2rem !important;
}
.pt-1 {
  padding-top: 10px !important;
  padding-top: 1rem !important;
}
.pb-1 {
  padding-bottom: 10px !important;
  padding-bottom: 1rem !important;
}
.pr-2 {
  padding-right: 20px !important;
  padding-right: 2rem !important;
}
.pl-2 {
  padding-left: 20px !important;
  padding-left: 2rem !important;
}
.pt-4 {
  padding-top: 40px !important;
  padding-top: 4rem !important;
}
.pb-4 {
  padding-bottom: 40px !important;
  padding-bottom: 4rem !important;
}
.pr-4 {
  padding-right: 40px !important;
  padding-right: 4rem !important;
}
.pl-4 {
  padding-left: 40px !important;
  padding-left: 4rem !important;
}
.pt-6 {
  padding-top: 60px !important;
  padding-top: 6rem !important;
}
.pb-6 {
  padding-bottom: 60px !important;
  padding-bottom: 6rem !important;
}
.pr-6 {
  padding-right: 60px !important;
  padding-right: 6rem !important;
}
.pl-6 {
  padding-left: 60px !important;
  padding-left: 6rem !important;
}
.mt--40 {
  margin-top: -40px;
}
.handwriting {
  font-family: Indie Flower, cursive;
}
.small-caps {
  font-variant: small-caps;
}
.uppercase {
  text-transform: uppercase;
}
.right-line {
  position: relative;
  z-index: 1;
  overflow: hidden;
  margin: 30px 0;
}
.right-line:after {
  position: absolute;
  top: 55%;
  overflow: hidden;
  width: 100%;
  height: 1px;
  content: "\a0";
  border-left: solid 20px #fff;
  background-color: #ddd;
}
.imgborder {
  padding: 5px;
  border: solid 1px #ddd;
}
.alignleft {
  float: left;
  margin: 0 10px 10px 0;
}
.alignright {
  float: right;
  margin: 0 0 10px 10px;
}
.aligncenter {
  display: block;
  margin: 10px auto;
}
.primary-color {
  color: #8bc34a;
}
.color-light {
  color: #f5f5f5;
}
.color-primary-light {
  color: #9ccc65;
}
.color-light-transparent {
  color: rgba(255, 255, 255, 0.6);
}
.color-medium {
  color: #bdbdbd;
}
.color-medium strong {
  color: #fff;
}
.no-shadow {
  box-shadow: none !important;
}
.font-smoothing {
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}
.separate-icons .btn-circle {
  margin: 5px;
}
.text-light {
  font-weight: 200 !important;
}
.text-light strong {
  font-weight: 300 !important;
}
.text-normal,
.fw-400 {
  font-weight: 400 !important;
}
.text-normal strong,
.fw-400 strong {
  font-weight: 500 !important;
}
.text-right {
  text-align: right !important;
}
.text-small {
  font-size: 12px;
  font-size: 1.2rem;
}
.mr-05 {
  margin-right: 5px;
}
.mt-05 {
  margin-top: 5px;
}
.fw-300 {
  font-weight: 300 !important;
}
.fw-300 strong {
  font-weight: 400;
}
.fw-500 {
  font-weight: 500;
}
.color-white {
  color: #fff !important;
}
.shadow-2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.shadow-3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.shadow-6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.zoom-img {
  overflow: hidden;
}
.zoom-img img {
  transition: all ease 0.6s;
}
.zoom-img:hover img,
.zoom-img:focus img {
  -webkit-transform: scale(1.15);
  transform: scale(1.15);
}
.shadow-2dp {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.shadow-3dp {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.shadow-6dp {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.mw-800 {
  max-width: 800px;
}
.font-big {
  font-size: 60px;
  font-size: 6rem;
}
@media (min-width: 992px) and (max-width: 1199px) {
  .no-mr-md {
    margin-right: 0 !important;
  }
  .mb-4-md {
    margin-bottom: 4rem !important;
  }
}
.bg-alt {
  background-color: #607d8b;
  color: #fff;
}
.index-1 {
  position: relative;
  z-index: 1 !important;
}
.index-2 {
  position: relative;
  z-index: 2 !important;
}
.bg-transparent {
  background-color: transparent !important;
}
.img-avatar-circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: solid 5px #fff;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  display: block;
  -webkit-transform: translateY(40px) rotate(0deg);
  transform: translateY(40px) rotate(0deg);
  transition: all ease 0.5s;
  position: relative;
  z-index: 1;
}
.img-avatar-circle:hover {
  -webkit-transform: translateY(40px) rotate(360deg);
  transform: translateY(40px) rotate(360deg);
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.ms-tag {
  background-color: #607d8b;
  border-radius: 50px;
  color: #fff;
  padding: 1px 12px;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 4px 2px;
  display: inline-block;
  transition: all ease 0.5s;
  font-weight: 500;
}
.ms-tag:hover {
  color: #fff;
  background-color: #566f7c;
}
.ms-tag.ms-tag-light {
  background-color: #f5f5f5;
}
.ms-tag.ms-tag-light:hover {
  background-color: gainsboro;
}
.ms-tag.ms-tag-dark {
  background-color: #424242;
}
.ms-tag.ms-tag-dark:hover {
  background-color: #292929;
}
.ms-tag.ms-tag-primary {
  background-color: #8bc34a;
}
.ms-tag.ms-tag-primary:hover {
  background-color: #71a436;
}
.ms-tag.ms-tag-info {
  background-color: #00bcd4;
}
.ms-tag.ms-tag-info:hover {
  background-color: #008fa1;
}
.ms-tag.ms-tag-royal {
  background-color: #9c27b0;
}
.ms-tag.ms-tag-royal:hover {
  background-color: #771e86;
}
.ms-tag.ms-tag-warning {
  background-color: #ff9800;
}
.ms-tag.ms-tag-warning:hover {
  background-color: #cc7a00;
}
.ms-tag.ms-tag-danger {
  background-color: #f44336;
}
.ms-tag.ms-tag-danger:hover {
  background-color: #ea1c0d;
}
.ms-tag.ms-tag-success {
  background-color: #4caf50;
}
.ms-tag.ms-tag-success:hover {
  background-color: #3d8b40;
}
.animate-icon i {
  transition: all ease 0.5s;
}
.animate-icon:hover i {
  -webkit-transform: translateX(10px);
  transform: translateX(10px);
}
.bs-glyphicons-list {
  list-style-type: none;
  padding-left: 0;
}
.bs-glyphicons-list li {
  display: inline-block;
  text-align: center;
  max-width: 100px;
  margin: 10px 5px;
  vertical-align: top;
}
.bs-glyphicons-list li .glyphicon {
  display: block;
  font-size: 30px;
  font-size: 3rem;
  margin-bottom: 10px;
  margin-bottom: 1rem;
}
.ms-avatar {
  border-radius: 100%;
  width: 100px;
  height: 100px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: solid 3px #fff;
}
.bg-opaque-1 {
  background-color: rgba(0, 0, 0, 0.1);
}
.bg-opaque-2 {
  background-color: rgba(0, 0, 0, 0.2);
}
.bg-opaque-3 {
  background-color: rgba(0, 0, 0, 0.3);
}
.bg-opaque-4 {
  background-color: rgba(0, 0, 0, 0.4);
}
.bg-opaque-5 {
  background-color: rgba(0, 0, 0, 0.5);
}
.bg-opaque-7 {
  background-color: rgba(0, 0, 0, 0.7);
}
.bg-opaque-8 {
  background-color: rgba(0, 0, 0, 0.8);
}
.bg-opaque-light-1 {
  background-color: rgba(255, 255, 255, 0.1);
}
.bg-opaque-light-2 {
  background-color: rgba(255, 255, 255, 0.2);
}
.bg-opaque-light-3 {
  background-color: rgba(255, 255, 255, 0.3);
}
.bg-opaque-light-4 {
  background-color: rgba(255, 255, 255, 0.4);
}
.bg-opaque-light-5 {
  background-color: rgba(255, 255, 255, 0.5);
}
.bg-opaque-light-7 {
  background-color: rgba(255, 255, 255, 0.7);
}
.bg-opaque-light-8 {
  background-color: rgba(255, 255, 255, 0.8);
}
.intro-hero-full {
  position: relative;
  width: 100%;
}
.intro-hero-full .intro-hero-full-content {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}
html {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
}
body {
  color: #757575;
  background-color: #f5f5f5;
  font-family: Roboto, sans-serif;
  font-size: 15px;
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 26px;
  line-height: 2.6rem;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highcolor-light: transparent;
}
body.bd-scroll {
  padding-top: 50px;
}
body .sb-site-container {
  -webkit-transform: none;
  transform: none;
  background-color: #f5f5f5;
  min-height: 0;
}
:focus {
  outline: none !important;
}
::-moz-focus-inner {
  border: 0 !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  transition: background-color 50000s ease-in-out 0s !important;
}
iframe {
  border: 0;
  display: table;
}
.material-background {
  background-color: #607d8b;
  display: block;
  position: absolute;
  width: 100%;
  height: 500px;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all ease 1s;
}
.bd-scroll .material-background {
  height: 800px;
}
.ms-component-section {
  margin-bottom: 60px;
  margin-bottom: 6rem;
}
.section-title {
  margin-bottom: 10px;
  margin-bottom: 1rem;
}
.color-primary {
  color: #8bc34a !important;
}
.color-info {
  color: #00bcd4 !important;
}
.color-success {
  color: #4caf50 !important;
}
.color-warning {
  color: #ff9800 !important;
}
.color-danger {
  color: #f44336 !important;
}
.color-royal {
  color: #9c27b0 !important;
}
.color-light {
  color: #f5f5f5 !important;
}
.color-medium {
  color: #bdbdbd !important;
}
.color-dark {
  color: #424242 !important;
}
.bg-primary {
  background-color: #8bc34a !important;
  color: #fff;
}
.bg-primary-dark {
  background-color: #7cb342 !important;
  color: #fff;
}
.bg-primary-light {
  background-color: #9ccc65 !important;
  color: #fff;
}
.bg-info {
  background-color: #00bcd4 !important;
  color: #fff;
}
.bg-success {
  background-color: #4caf50 !important;
  color: #fff;
}
.bg-warning {
  background-color: #ff9800 !important;
  color: #fff;
}
.bg-danger {
  background-color: #f44336 !important;
  color: #fff;
}
.bg-royal {
  background-color: #9c27b0 !important;
  color: #fff;
}
.bg-light {
  background-color: #f5f5f5;
}
.bg-medium {
  background-color: #bdbdbd;
}
.bg-dark {
  background-color: #424242;
  color: #fff;
}
.bg-white {
  background-color: #fff;
}
.color-dark li a {
  color: #424242;
}
.color-dark li a:hover,
.color-dark li a:focus {
  color: #8bc34a;
}
.ms-site-title {
  vertical-align: middle;
  display: inline-block;
  font-weight: 200;
  word-spacing: -4px;
}
.ms-site-title span {
  font-weight: 300;
}
.btn-back-top {
  display: inline-block;
  position: fixed;
  left: auto;
  top: auto;
  bottom: -100px;
  right: 20px;
  z-index: 999999999;
  transition: all ease 0.5s;
}
.btn-back-top.back-show {
  bottom: 20px;
}
.ms-nav-fixed {
  padding-top: 90px;
}
.ms-nav-fixed .ms-navbar {
  -webkit-animation: none !important;
  animation: none !important;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 20px 0 15px;
  margin: 2rem 0 1.5rem;
}
h6,
h5 {
  font-weight: 700;
}
.page-title {
  border-bottom: solid 1px gainsboro;
  margin: 20px 0 40px;
  margin: 2rem 0 4rem;
  padding: 0 0 15px;
  padding: 0 0 1.5rem;
  position: relative;
}
.page-title .breadcrumb {
  position: absolute;
  right: 0;
  bottom: 15px;
  bottom: 1.5rem;
}
.page-header {
  font-weight: 300;
  border-top: none;
  line-height: 1;
  margin: 0;
  padding: 0;
  display: inline-block;
}
.section-title {
  color: #8bc34a;
}
.breadcrumb {
  display: inline-block;
  background: none;
  padding: 0;
  margin: 0;
  line-height: 1;
  font-weight: 300;
  font-size: 14px;
  font-size: 1.4rem;
  color: #616161;
}
.breadcrumb a {
  color: #616161;
}
.breadcrumb > li + li:before {
  font-size: 16px;
  font-size: 1.6rem;
  padding: 0 8px;
  color: #616161;
  content: "\00bb";
}
a {
  color: #8bc34a;
  transition: all ease 0.1s;
}
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: #000;
}
.lead {
  font-weight: 300;
  font-size: 20px;
  font-size: 2rem;
}
.lead.lead-xl {
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: 300;
}
.lead.lead-lg {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 400;
}
.lead.lead-md {
  font-size: 17px;
  font-size: 1.7rem;
  line-height: 27px;
  line-height: 2.7rem;
}
.lead.lead-sm {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 26px;
  line-height: 2.6rem;
}
dt {
  color: #8bc34a;
}
dd {
  margin-bottom: 5px;
  margin-bottom: 0.5rem;
}
.service-list li {
  counter-increment: myIndex;
  position: relative;
  margin-bottom: 10px;
  margin-bottom: 1rem;
  padding: 10px 0 10px 80px;
}
.service-list li:before {
  content: counter(myIndex);
  font-size: 2em;
  padding: 10px 15px;
  color: #fff;
  background-color: #8bc34a;
  border-radius: 500px;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  left: 0;
}
.service-list.service-list-border li:before {
  background-color: transparent;
  color: #8bc34a;
  border: solid 1px #8bc34a;
}
.service-list.service-list-white li:before {
  color: #8bc34a;
  background-color: #fff;
}
.service-list.service-list-white.service-list-border li:before {
  background-color: transparent;
  border-color: #fff;
  color: #fff;
}
hr {
  display: block;
  border-top: 1px solid #ddd;
  margin: 25px 0;
}
hr.color {
  border-color: #8bc34a !important;
}
hr.dotted {
  border-style: dotted !important;
}
hr.dashed {
  border-style: dashed !important;
}
hr.double {
  height: 5px;
  border-bottom-width: 1px;
  border-bottom-color: #ddd;
  border-bottom-style: solid;
}
.dropcaps:first-letter {
  float: left;
  color: #8bc34a;
  font-size: 3em;
  font-weight: 400;
  padding: 10px 10px 10px 5px;
  margin-top: 2px;
}
.dropcaps-bg:first-letter {
  background-color: #8bc34a;
  color: #fff;
  padding-bottom: 12px;
  margin-right: 10px;
  border-radius: 2px;
  margin-top: 2px;
  padding: 10px 5px;
  font-size: 2.8em;
  margin-top: 4px;
}
.dropcaps-circle:first-letter {
  border: solid 1px #8bc34a;
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}
.dropcaps-circle-bg:first-letter {
  background-color: #8bc34a;
  color: #fff;
  border-radius: 30px;
  padding: 10px 8px;
  font-size: 2.3em;
  margin-right: 10px;
  margin-top: 2px;
}
.border-box {
  background-color: #f9f9f9;
  border: solid 3px #eee;
  border-left-color: #8bc34a;
  padding: 20px;
  margin-bottom: 20px;
}
.color-primary {
  color: #8bc34a;
}
.color-success {
  color: #4caf50;
}
.color-info {
  color: #00bcd4;
}
.color-warning {
  color: #ff9800;
}
.color-danger {
  color: #f44336;
}
.color-royal {
  color: #9c27b0;
}
.color-primary-inverse {
  background-color: #8bc34a;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
.color-success-inverse {
  background-color: #4caf50;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
.color-info-inverse {
  background-color: #00bcd4;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
.color-warning-inverse {
  background-color: #ff9800;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
.color-danger-inverse {
  background-color: #f44336;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
.color-royal-inverse {
  background-color: #9c27b0;
  color: #fff;
  padding: 0 5px;
  border-radius: 2px;
}
blockquote {
  margin: 0;
  padding: 10px 30px;
  padding: 1rem 3rem;
  font-style: oblique;
}
blockquote.blockquote-color {
  border-color: #8bc34a;
}
blockquote.blockquote-color-bg {
  background-color: #f5f5f5;
  padding: 20px 40px;
  padding: 2rem 4rem;
  border: 0;
  font-weight: 300;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
blockquote.blockquote-big {
  font-size: 20px;
  font-size: 2rem;
  line-height: 32px;
  line-height: 3.2rem;
}
blockquote.blockquote-color-bg-primary,
blockquote.blockquote-color-bg-light,
blockquote.blockquote-color-bg-dark {
  background-color: #8bc34a;
  color: #fff;
  padding: 20px 40px 20px 80px;
  padding: 2rem 4rem 2rem 8rem;
  border: 0;
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: 400;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
blockquote.blockquote-color-bg-primary footer,
blockquote.blockquote-color-bg-light footer,
blockquote.blockquote-color-bg-dark footer {
  color: #e0e0e0;
}
blockquote.blockquote-color-bg-primary:after,
blockquote.blockquote-color-bg-light:after,
blockquote.blockquote-color-bg-dark:after {
  font-family: FontAwesome;
  content: "\f10e";
  position: absolute;
  left: 15px;
  bottom: 15px;
  color: #fff;
  font-size: 2.6em;
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}
blockquote.blockquote-color-bg-dark {
  background-color: #424242;
}
blockquote.blockquote-color-bg-light {
  background-color: #eee;
  color: #424242;
  text-shadow: 1px 1px 1px white;
}
blockquote.blockquote-color-bg-light footer {
  color: #9e9e9e;
}
blockquote.blockquote-color-bg-light:after {
  color: #212121;
}
.card blockquote {
  border: none;
  color: #616161;
  font-size: 16px;
  font-size: 1.6rem;
  padding: 30px;
  padding: 3rem;
  position: relative;
  font-weight: 400;
}
.card blockquote .avatar {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
  transition: all ease 0.3s;
}
.card blockquote:hover .avatar {
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  -webkit-transform: translate(-25%, -25%);
  transform: translate(-25%, -25%);
  width: 90px;
  height: 90px;
  z-index: 2;
}
.card blockquote footer {
  text-align: right;
}
.card blockquote.blockquote-avatar {
  padding: 30px 20px 30px 70px;
  padding: 3rem 2rem 3rem 7rem;
  margin-top: 40px;
  margin-top: 4rem;
}
.card blockquote.blockquote-avatar.right {
  padding: 30px 70px 30px 20px;
  padding: 3rem 7rem 3rem 2rem;
}
.card blockquote.blockquote-avatar.right footer {
  text-align: right;
  margin-right: -50px;
  margin-right: -5rem;
}
.card blockquote.blockquote-avatar.right .avatar {
  left: auto;
  right: 0;
  -webkit-transform: translate(25%, -25%);
  transform: translate(25%, -25%);
}
blockquote.ms-blockquote {
  padding-top: 0;
  padding-left: 80px;
  position: relative;
}
blockquote.ms-blockquote:after {
  font-family: FontAwesome;
  content: "\f10d";
  position: absolute;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 100%;
  background-color: #8bc34a;
  left: 0;
  top: 0;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
}
blockquote.ms-blockquote footer {
  text-align: left;
  font-size: 15px;
  font-size: 1.5rem;
}
.ms-list-arrow {
  list-style-type: none;
  padding: 0;
}
.ms-list-arrow li {
  font-size: 20px;
  font-size: 2rem;
  font-weight: 300;
  margin-bottom: 20px;
  margin-bottom: 2rem;
  padding-left: 55px;
}
.ms-list-arrow li:before {
  font-family: Material-Design-Iconic-Font;
  content: "\f301";
  background-color: #8bc34a;
  border-radius: 100%;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  display: inline-block;
  margin-right: 20px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-left: -55px;
  vertical-align: middle;
}
.btn {
  font-weight: 400;
  letter-spacing: 0.14px;
  white-space: nowrap;
  -ms-flex-item-align: center;
  -ms-grid-row-align: center;
  align-self: center;
  outline: rgba(0, 0, 0, 0.870588) none 0;
}
.btn.btn-square {
  border-radius: 0 !important;
}
.btn.btn-white {
  color: #fff;
}
.btn.btn-white:hover,
.btn.btn-white:focus,
.btn.btn-white:active {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.btn.btn-white.btn-raised {
  color: #000;
  background-color: #fff;
}
.btn.btn-white.btn-raised:hover,
.btn.btn-white.btn-raised:focus,
.btn.btn-white.btn-raised:active {
  background-color: #fff !important;
}
.btn.btn-xlg {
  padding: 10px 40px;
  font-size: 16px;
}
.btn.btn-xxlg {
  padding: 16px 40px;
  font-size: 16px;
  font-weight: 300;
  letter-spacing: 0.3px;
}
.btn > i {
  margin-right: 10px;
  margin-right: 1rem;
}
.btn > i.right {
  margin-left: 10px;
  margin-left: 1rem;
  margin-right: 0;
}
.btn > i.only {
  margin-left: 3px;
  margin-left: 0.3rem;
  margin-right: 3px;
  margin-right: 0.3rem;
}
.btn.btn-app {
  padding: 12px 20px;
  padding: 1.2rem 2rem;
  min-width: 180px;
}
.btn.btn-app .btn-container {
  display: table;
  text-transform: none;
  text-align: left;
}
.btn.btn-app i {
  font-size: 40px !important;
  font-size: 4rem !important;
  display: table-cell;
  padding-right: 20px;
  padding-right: 2rem;
}
.btn.btn-app span {
  font-size: 12px;
  font-size: 1.2rem;
}
.btn.btn-app strong {
  font-size: 18px;
  font-size: 1.8rem;
}
.btn.btn-fab.btn-default:hover,
.btn.btn-fab.btn-default:focus,
.btn.btn-fab.btn-default:active {
  background-color: #dfdfdf;
}
.btn.btn-fab.btn-primary:hover,
.btn.btn-fab.btn-primary:focus,
.btn.btn-fab.btn-primary:active {
  background-color: #7cb342;
}
.btn.btn-fab.btn-info:hover,
.btn.btn-fab.btn-info:focus,
.btn.btn-fab.btn-info:active {
  background-color: #00acc1;
}
.btn.btn-fab.btn-success:hover,
.btn.btn-fab.btn-success:focus,
.btn.btn-fab.btn-success:active {
  background-color: #43a047;
}
.btn.btn-fab.btn-warning:hover,
.btn.btn-fab.btn-warning:focus,
.btn.btn-fab.btn-warning:active {
  background-color: #fb8c00;
}
.btn.btn-fab.btn-danger:hover,
.btn.btn-fab.btn-danger:focus,
.btn.btn-fab.btn-danger:active {
  background-color: #e53935;
}
.btn.btn-fab.btn-royal:hover,
.btn.btn-fab.btn-royal:focus,
.btn.btn-fab.btn-royal:active {
  background-color: #8e24aa;
}
.btn.btn-raised:hover,
.btn.btn-raised:focus,
.btn.btn-raised:active {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.btn.btn-raised.btn-facebook {
  color: #fff;
  background-color: #3b5998;
}
.btn.btn-raised.btn-facebook:hover,
.btn.btn-raised.btn-facebook:focus {
  background-color: #3b5998;
}
.btn.btn-raised.btn-twitter {
  color: #fff;
  background-color: #00aced;
}
.btn.btn-raised.btn-twitter:hover,
.btn.btn-raised.btn-twitter:focus {
  background-color: #00aced;
}
.btn.btn-raised.btn-google {
  color: #fff;
  background-color: #dd4b39;
}
.btn.btn-raised.btn-google:hover,
.btn.btn-raised.btn-google:focus {
  background-color: #dd4b39;
}
.btn.btn-raised.btn-rss {
  color: #fff;
  background-color: #ef922f;
}
.btn.btn-raised.btn-rss:hover,
.btn.btn-raised.btn-rss:focus {
  background-color: #ef922f;
}
.btn.btn-raised.btn-youtube {
  color: #fff;
  background-color: #b00;
}
.btn.btn-raised.btn-youtube:hover,
.btn.btn-raised.btn-youtube:focus {
  background-color: #b00;
}
.btn.btn-raised.btn-linkedin {
  color: #fff;
  background-color: #007bb6;
}
.btn.btn-raised.btn-linkedin:hover,
.btn.btn-raised.btn-linkedin:focus {
  background-color: #007bb6;
}
.btn.btn-raised.btn-instagram {
  color: #fff;
  background-color: #e4405f;
}
.btn.btn-raised.btn-instagram:hover,
.btn.btn-raised.btn-instagram:focus {
  background-color: #e4405f;
}
.btn.btn-raised.btn-pinterest {
  color: #fff;
  background-color: #cb2027;
}
.btn.btn-raised.btn-pinterest:hover,
.btn.btn-raised.btn-pinterest:focus {
  background-color: #cb2027;
}
.btn.btn-raised.btn-wordpress {
  color: #fff;
  background-color: #4597be;
}
.btn.btn-raised.btn-wordpress:hover,
.btn.btn-raised.btn-wordpress:focus {
  background-color: #4597be;
}
.btn.btn-raised.btn-git {
  color: #fff;
  background-color: #f54c27;
}
.btn.btn-raised.btn-git:hover,
.btn.btn-raised.btn-git:focus {
  background-color: #f54c27;
}
.btn.btn-raised.btn-github {
  color: #fff;
  background-color: #333;
}
.btn.btn-raised.btn-github:hover,
.btn.btn-raised.btn-github:focus {
  background-color: #333;
}
.btn.btn-raised.btn-flickr {
  color: #fff;
  background-color: #ff0084;
}
.btn.btn-raised.btn-flickr:hover,
.btn.btn-raised.btn-flickr:focus {
  background-color: #ff0084;
}
.btn.btn-raised.btn-vine {
  color: #fff;
  background-color: #00b48a;
}
.btn.btn-raised.btn-vine:hover,
.btn.btn-raised.btn-vine:focus {
  background-color: #00b48a;
}
.btn.btn-raised.btn-dribbble {
  color: #fff;
  background-color: #f26798;
}
.btn.btn-raised.btn-dribbble:hover,
.btn.btn-raised.btn-dribbble:focus {
  background-color: #f26798;
}
.btn.btn-raised.btn-foursquare {
  color: #fff;
  background-color: #0072b1;
}
.btn.btn-raised.btn-foursquare:hover,
.btn.btn-raised.btn-foursquare:focus {
  background-color: #0072b1;
}
.btn.btn-raised.btn-tumblr {
  color: #fff;
  background-color: #32506d;
}
.btn.btn-raised.btn-tumblr:hover,
.btn.btn-raised.btn-tumblr:focus {
  background-color: #32506d;
}
.btn.btn-raised.btn-vk {
  color: #fff;
  background-color: #4c75a3;
}
.btn.btn-raised.btn-vk:hover,
.btn.btn-raised.btn-vk:focus {
  background-color: #4c75a3;
}
.btn.btn-raised.btn-reddit {
  color: #fff;
  background-color: #ff5700;
}
.btn.btn-raised.btn-reddit:hover,
.btn.btn-raised.btn-reddit:focus {
  background-color: #ff5700;
}
.btn.btn-raised.btn-whatsapp {
  color: #fff;
  background-color: #25d366;
}
.btn.btn-raised.btn-whatsapp:hover,
.btn.btn-raised.btn-whatsapp:focus {
  background-color: #25d366;
}
.btn.btn-raised.btn-soundcloud {
  color: #fff;
  background-color: #f30;
}
.btn.btn-raised.btn-soundcloud:hover,
.btn.btn-raised.btn-soundcloud:focus {
  background-color: #f30;
}
.btn.btn-raised.btn-dropbox {
  color: #fff;
  background-color: #007ee5;
}
.btn.btn-raised.btn-dropbox:hover,
.btn.btn-raised.btn-dropbox:focus {
  background-color: #007ee5;
}
.btn.btn-raised.btn-vimeo {
  color: #fff;
  background-color: #1ab7ea;
}
.btn.btn-raised.btn-vimeo:hover,
.btn.btn-raised.btn-vimeo:focus {
  background-color: #1ab7ea;
}
.btn.btn-raised.btn-slack {
  color: #fff;
  background-color: #3aaf85;
}
.btn.btn-raised.btn-slack:hover,
.btn.btn-raised.btn-slack:focus {
  background-color: #3aaf85;
}
.btn.btn-raised.btn-skype {
  color: #fff;
  background-color: #00aff0;
}
.btn.btn-raised.btn-skype:hover,
.btn.btn-raised.btn-skype:focus {
  background-color: #00aff0;
}
.btn.btn-facebook {
  color: #3b5998;
}
.btn.btn-facebook:hover,
.btn.btn-facebook:focus {
  color: #fff;
  background-color: #3b5998 !important;
}
.btn.btn-twitter {
  color: #00aced;
}
.btn.btn-twitter:hover,
.btn.btn-twitter:focus {
  color: #fff;
  background-color: #00aced !important;
}
.btn.btn-google {
  color: #dd4b39;
}
.btn.btn-google:hover,
.btn.btn-google:focus {
  color: #fff;
  background-color: #dd4b39 !important;
}
.btn.btn-rss {
  color: #ef922f;
}
.btn.btn-rss:hover,
.btn.btn-rss:focus {
  color: #fff;
  background-color: #ef922f !important;
}
.btn.btn-youtube {
  color: #b00;
}
.btn.btn-youtube:hover,
.btn.btn-youtube:focus {
  color: #fff;
  background-color: #b00 !important;
}
.btn.btn-linkedin {
  color: #007bb6;
}
.btn.btn-linkedin:hover,
.btn.btn-linkedin:focus {
  color: #fff;
  background-color: #007bb6 !important;
}
.btn.btn-instagram {
  color: #e4405f;
}
.btn.btn-instagram:hover,
.btn.btn-instagram:focus {
  color: #fff;
  background-color: #e4405f !important;
}
.btn.btn-pinterest {
  color: #cb2027;
}
.btn.btn-pinterest:hover,
.btn.btn-pinterest:focus {
  color: #fff;
  background-color: #cb2027 !important;
}
.btn.btn-wordpress {
  color: #4597be;
}
.btn.btn-wordpress:hover,
.btn.btn-wordpress:focus {
  color: #fff;
  background-color: #4597be !important;
}
.btn.btn-git {
  color: #f54c27;
}
.btn.btn-git:hover,
.btn.btn-git:focus {
  color: #fff;
  background-color: #f54c27 !important;
}
.btn.btn-github {
  color: #333;
}
.btn.btn-github:hover,
.btn.btn-github:focus {
  color: #fff;
  background-color: #333 !important;
}
.btn.btn-flickr {
  color: #ff0084;
}
.btn.btn-flickr:hover,
.btn.btn-flickr:focus {
  color: #fff;
  background-color: #ff0084 !important;
}
.btn.btn-vine {
  color: #00b48a;
}
.btn.btn-vine:hover,
.btn.btn-vine:focus {
  color: #fff;
  background-color: #00b48a !important;
}
.btn.btn-dribbble {
  color: #f26798;
}
.btn.btn-dribbble:hover,
.btn.btn-dribbble:focus {
  color: #fff;
  background-color: #f26798 !important;
}
.btn.btn-foursquare {
  color: #0072b1;
}
.btn.btn-foursquare:hover,
.btn.btn-foursquare:focus {
  color: #fff;
  background-color: #0072b1 !important;
}
.btn.btn-tumblr {
  color: #32506d;
}
.btn.btn-tumblr:hover,
.btn.btn-tumblr:focus {
  color: #fff;
  background-color: #32506d !important;
}
.btn.btn-vk {
  color: #4c75a3;
}
.btn.btn-vk:hover,
.btn.btn-vk:focus {
  color: #fff;
  background-color: #4c75a3 !important;
}
.btn.btn-reddit {
  color: #ff5700;
}
.btn.btn-reddit:hover,
.btn.btn-reddit:focus {
  color: #fff;
  background-color: #ff5700 !important;
}
.btn.btn-whatsapp {
  color: #25d366;
}
.btn.btn-whatsapp:hover,
.btn.btn-whatsapp:focus {
  color: #fff;
  background-color: #25d366 !important;
}
.btn.btn-soundcloud {
  color: #f30;
}
.btn.btn-soundcloud:hover,
.btn.btn-soundcloud:focus {
  color: #fff;
  background-color: #f30 !important;
}
.btn.btn-dropbox {
  color: #007ee5;
}
.btn.btn-dropbox:hover,
.btn.btn-dropbox:focus {
  color: #fff;
  background-color: #007ee5 !important;
}
.btn.btn-vimeo {
  color: #1ab7ea;
}
.btn.btn-vimeo:hover,
.btn.btn-vimeo:focus {
  color: #fff;
  background-color: #1ab7ea !important;
}
.btn.btn-slack {
  color: #3aaf85;
}
.btn.btn-slack:hover,
.btn.btn-slack:focus {
  color: #fff;
  background-color: #3aaf85 !important;
}
.btn.btn-skype {
  color: #00aff0;
}
.btn.btn-skype:hover,
.btn.btn-skype:focus {
  color: #fff;
  background-color: #00aff0 !important;
}
.btn-circle {
  height: 50px;
  width: 50px;
  color: #616161;
  display: inline-block;
  border-radius: 200rem;
  text-align: center;
  line-height: 50px;
  position: relative;
  z-index: 1;
  transition: color ease 0.5s;
  transition: box-shadow ease-in-out 0s;
  border: 0;
  background-color: transparent;
}
.btn-circle:before {
  transition: all ease 0.5s;
  border-radius: 150px;
  z-index: -1;
  content: "";
  position: absolute;
  display: block;
  background-color: #eee;
  height: 0;
  width: 0;
  top: 25px;
  left: 25px;
}
.btn-circle:hover,
.btn-circle:focus {
  color: #616161;
  transition: box-shadow ease-in-out 0.8s;
}
.btn-circle:hover:before,
.btn-circle:focus:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.btn-circle i.zmdi {
  font-size: 20px;
  vertical-align: middle;
}
.btn-circle.btn-circle-xlg {
  width: 70px;
  height: 70px;
  line-height: 70px;
  font-size: 20px;
}
.btn-circle.btn-circle-xlg:before {
  top: 35px;
  left: 35px;
}
.btn-circle.btn-circle-xlg:hover:before,
.btn-circle.btn-circle-xlg:focus:before {
  height: 70px;
  width: 70px;
  top: 0;
  left: 0;
}
.btn-circle.btn-circle-xlg i.zmdi {
  font-size: 26px;
}
.btn-circle.btn-circle-lg {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 18px;
}
.btn-circle.btn-circle-lg:before {
  top: 30px;
  left: 30px;
}
.btn-circle.btn-circle-lg:hover:before,
.btn-circle.btn-circle-lg:focus:before {
  height: 60px;
  width: 60px;
  top: 0;
  left: 0;
}
.btn-circle.btn-circle-lg i.zmdi {
  font-size: 24px;
}
.btn-circle.btn-circle-sm {
  width: 40px;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
}
.btn-circle.btn-circle-sm:before {
  top: 20px;
  left: 20px;
}
.btn-circle.btn-circle-sm:hover:before,
.btn-circle.btn-circle-sm:focus:before {
  height: 40px;
  width: 40px;
  top: 0;
  left: 0;
}
.btn-circle.btn-circle-sm i.zmdi {
  font-size: 18px;
}
.btn-circle.btn-circle-xs {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 10px;
}
.btn-circle.btn-circle-xs:before {
  top: 15px;
  left: 15px;
}
.btn-circle.btn-circle-xs:hover:before,
.btn-circle.btn-circle-xs:focus:before {
  height: 30px;
  width: 30px;
  top: 0;
  left: 0;
}
.btn-circle.btn-circle-xs i.zmdi {
  font-size: 14px;
}
.btn-circle.btn-circle-default {
  color: #616161;
}
.btn-circle.btn-circle-default:before {
  background-color: #eee;
}
.btn-circle.btn-circle-default:hover,
.btn-circle.btn-circle-default:focus {
  color: #616161;
}
.btn-circle.btn-circle-white {
  color: #fff;
}
.btn-circle.btn-circle-white:before {
  background-color: #fff;
}
.btn-circle.btn-circle-white:hover,
.btn-circle.btn-circle-white:focus {
  color: #8bc34a;
}
.btn-circle.btn-circle-black {
  color: #000;
}
.btn-circle.btn-circle-black:before {
  background-color: #eee;
}
.btn-circle.btn-circle-black:hover,
.btn-circle.btn-circle-black:focus {
  color: #000;
}
.btn-circle.btn-circle-primary {
  color: #8bc34a;
}
.btn-circle.btn-circle-primary:before {
  background-color: #8bc34a;
}
.btn-circle.btn-circle-primary:hover,
.btn-circle.btn-circle-primary:focus {
  color: #fff;
}
.btn-circle.btn-circle-success {
  color: #4caf50;
}
.btn-circle.btn-circle-success:before {
  background-color: #4caf50;
}
.btn-circle.btn-circle-success:hover,
.btn-circle.btn-circle-success:focus {
  color: #fff;
}
.btn-circle.btn-circle-info {
  color: #00bcd4;
}
.btn-circle.btn-circle-info:before {
  background-color: #00bcd4;
}
.btn-circle.btn-circle-info:hover,
.btn-circle.btn-circle-info:focus {
  color: #fff;
}
.btn-circle.btn-circle-warning {
  color: #ff9800;
}
.btn-circle.btn-circle-warning:before {
  background-color: #ff9800;
}
.btn-circle.btn-circle-warning:hover,
.btn-circle.btn-circle-warning:focus {
  color: #fff;
}
.btn-circle.btn-circle-danger {
  color: #f44336;
}
.btn-circle.btn-circle-danger:before {
  background-color: #f44336;
}
.btn-circle.btn-circle-danger:hover,
.btn-circle.btn-circle-danger:focus {
  color: #fff;
}
.btn-circle.btn-circle-royal {
  color: #9c27b0;
}
.btn-circle.btn-circle-royal:before {
  background-color: #9c27b0;
}
.btn-circle.btn-circle-royal:hover,
.btn-circle.btn-circle-royal:focus {
  color: #fff;
}
.btn-circle.btn-facebook {
  color: #3b5998;
}
.btn-circle.btn-facebook:before {
  background-color: #3b5998;
}
.btn-circle.btn-facebook:hover,
.btn-circle.btn-facebook:focus {
  color: #fff;
}
.btn-circle.btn-twitter {
  color: #00aced;
}
.btn-circle.btn-twitter:before {
  background-color: #00aced;
}
.btn-circle.btn-twitter:hover,
.btn-circle.btn-twitter:focus {
  color: #fff;
}
.btn-circle.btn-google {
  color: #dd4b39;
}
.btn-circle.btn-google:before {
  background-color: #dd4b39;
}
.btn-circle.btn-google:hover,
.btn-circle.btn-google:focus {
  color: #fff;
}
.btn-circle.btn-rss {
  color: #ef922f;
}
.btn-circle.btn-rss:before {
  background-color: #ef922f;
}
.btn-circle.btn-rss:hover,
.btn-circle.btn-rss:focus {
  color: #fff;
}
.btn-circle.btn-youtube {
  color: #b00;
}
.btn-circle.btn-youtube:before {
  background-color: #b00;
}
.btn-circle.btn-youtube:hover,
.btn-circle.btn-youtube:focus {
  color: #fff;
}
.btn-circle.btn-linkedin {
  color: #007bb6;
}
.btn-circle.btn-linkedin:before {
  background-color: #007bb6;
}
.btn-circle.btn-linkedin:hover,
.btn-circle.btn-linkedin:focus {
  color: #fff;
}
.btn-circle.btn-instagram {
  color: #e4405f;
}
.btn-circle.btn-instagram:before {
  background-color: #e4405f;
}
.btn-circle.btn-instagram:hover,
.btn-circle.btn-instagram:focus {
  color: #fff;
}
.btn-circle.btn-pinterest {
  color: #cb2027;
}
.btn-circle.btn-pinterest:before {
  background-color: #cb2027;
}
.btn-circle.btn-pinterest:hover,
.btn-circle.btn-pinterest:focus {
  color: #fff;
}
.btn-circle.btn-wordpress {
  color: #4597be;
}
.btn-circle.btn-wordpress:before {
  background-color: #4597be;
}
.btn-circle.btn-wordpress:hover,
.btn-circle.btn-wordpress:focus {
  color: #fff;
}
.btn-circle.btn-git {
  color: #f54c27;
}
.btn-circle.btn-git:before {
  background-color: #f54c27;
}
.btn-circle.btn-git:hover,
.btn-circle.btn-git:focus {
  color: #fff;
}
.btn-circle.btn-github {
  color: #333;
}
.btn-circle.btn-github:before {
  background-color: #333;
}
.btn-circle.btn-github:hover,
.btn-circle.btn-github:focus {
  color: #fff;
}
.btn-circle.btn-flickr {
  color: #ff0084;
}
.btn-circle.btn-flickr:before {
  background-color: #ff0084;
}
.btn-circle.btn-flickr:hover,
.btn-circle.btn-flickr:focus {
  color: #fff;
}
.btn-circle.btn-vine {
  color: #00b48a;
}
.btn-circle.btn-vine:before {
  background-color: #00b48a;
}
.btn-circle.btn-vine:hover,
.btn-circle.btn-vine:focus {
  color: #fff;
}
.btn-circle.btn-dribbble {
  color: #f26798;
}
.btn-circle.btn-dribbble:before {
  background-color: #f26798;
}
.btn-circle.btn-dribbble:hover,
.btn-circle.btn-dribbble:focus {
  color: #fff;
}
.btn-circle.btn-foursquare {
  color: #0072b1;
}
.btn-circle.btn-foursquare:before {
  background-color: #0072b1;
}
.btn-circle.btn-foursquare:hover,
.btn-circle.btn-foursquare:focus {
  color: #fff;
}
.btn-circle.btn-tumblr {
  color: #32506d;
}
.btn-circle.btn-tumblr:before {
  background-color: #32506d;
}
.btn-circle.btn-tumblr:hover,
.btn-circle.btn-tumblr:focus {
  color: #fff;
}
.btn-circle.btn-vk {
  color: #4c75a3;
}
.btn-circle.btn-vk:before {
  background-color: #4c75a3;
}
.btn-circle.btn-vk:hover,
.btn-circle.btn-vk:focus {
  color: #fff;
}
.btn-circle.btn-reddit {
  color: #ff5700;
}
.btn-circle.btn-reddit:before {
  background-color: #ff5700;
}
.btn-circle.btn-reddit:hover,
.btn-circle.btn-reddit:focus {
  color: #fff;
}
.btn-circle.btn-whatsapp {
  color: #25d366;
}
.btn-circle.btn-whatsapp:before {
  background-color: #25d366;
}
.btn-circle.btn-whatsapp:hover,
.btn-circle.btn-whatsapp:focus {
  color: #fff;
}
.btn-circle.btn-soundcloud {
  color: #f30;
}
.btn-circle.btn-soundcloud:before {
  background-color: #f30;
}
.btn-circle.btn-soundcloud:hover,
.btn-circle.btn-soundcloud:focus {
  color: #fff;
}
.btn-circle.btn-dropbox {
  color: #007ee5;
}
.btn-circle.btn-dropbox:before {
  background-color: #007ee5;
}
.btn-circle.btn-dropbox:hover,
.btn-circle.btn-dropbox:focus {
  color: #fff;
}
.btn-circle.btn-vimeo {
  color: #1ab7ea;
}
.btn-circle.btn-vimeo:before {
  background-color: #1ab7ea;
}
.btn-circle.btn-vimeo:hover,
.btn-circle.btn-vimeo:focus {
  color: #fff;
}
.btn-circle.btn-slack {
  color: #3aaf85;
}
.btn-circle.btn-slack:before {
  background-color: #3aaf85;
}
.btn-circle.btn-slack:hover,
.btn-circle.btn-slack:focus {
  color: #fff;
}
.btn-circle.btn-skype {
  color: #00aff0;
}
.btn-circle.btn-skype:before {
  background-color: #00aff0;
}
.btn-circle.btn-skype:hover,
.btn-circle.btn-skype:focus {
  color: #fff;
}
.btn-circle.btn-circle-raised {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #fff;
}
.btn-circle.btn-circle-raised.btn-circle-default {
  background-color: #eee;
  color: rgba(0, 0, 0, 0.87);
}
.btn-circle.btn-circle-raised.btn-circle-default:before {
  background-color: #e1e1e1;
}
.btn-circle.btn-circle-raised.btn-circle-white {
  background-color: #fff !important;
  color: #8bc34a;
}
.btn-circle.btn-circle-raised.btn-circle-white:before {
  background-color: #f2f2f2 !important;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-primary {
  color: #8bc34a;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-success {
  color: #4caf50;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-default {
  color: #000;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-info {
  color: #00bcd4;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-warning {
  color: #ff9800;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-danger {
  color: #f44336;
}
.btn-circle.btn-circle-raised.btn-circle-white.btn-circle-royal {
  color: #9c27b0;
}
.btn-circle.btn-circle-raised.btn-circle-primary {
  background-color: #8bc34a;
}
.btn-circle.btn-circle-raised.btn-circle-primary:before {
  background-color: #7eb73d;
}
.btn-circle.btn-circle-raised.btn-circle-info {
  background-color: #00bcd4;
}
.btn-circle.btn-circle-raised.btn-circle-info:before {
  background-color: #00a5bb;
}
.btn-circle.btn-circle-raised.btn-circle-success {
  background-color: #4caf50;
}
.btn-circle.btn-circle-raised.btn-circle-success:before {
  background-color: #449d48;
}
.btn-circle.btn-circle-raised.btn-circle-warning {
  background-color: #ff9800;
}
.btn-circle.btn-circle-raised.btn-circle-warning:before {
  background-color: #e68900;
}
.btn-circle.btn-circle-raised.btn-circle-royal {
  background-color: #9c27b0;
}
.btn-circle.btn-circle-raised.btn-circle-royal:before {
  background-color: #89229b;
}
.btn-circle.btn-circle-raised.btn-circle-danger {
  background-color: #f44336;
}
.btn-circle.btn-circle-raised.btn-circle-danger:before {
  background-color: #f32c1e;
}
.btn-circle.btn-circle-raised.btn-facebook {
  background-color: #3b5998;
}
.btn-circle.btn-circle-raised.btn-facebook:before {
  background-color: #344e86;
}
.btn-circle.btn-circle-raised.btn-twitter {
  background-color: #00aced;
}
.btn-circle.btn-circle-raised.btn-twitter:before {
  background-color: #0099d4;
}
.btn-circle.btn-circle-raised.btn-google {
  background-color: #dd4b39;
}
.btn-circle.btn-circle-raised.btn-google:before {
  background-color: #d73925;
}
.btn-circle.btn-circle-raised.btn-rss {
  background-color: #ef922f;
}
.btn-circle.btn-circle-raised.btn-rss:before {
  background-color: #ed8617;
}
.btn-circle.btn-circle-raised.btn-youtube {
  background-color: #b00;
}
.btn-circle.btn-circle-raised.btn-youtube:before {
  background-color: #a20000;
}
.btn-circle.btn-circle-raised.btn-linkedin {
  background-color: #007bb6;
}
.btn-circle.btn-circle-raised.btn-linkedin:before {
  background-color: #006a9d;
}
.btn-circle.btn-circle-raised.btn-instagram {
  background-color: #e4405f;
}
.btn-circle.btn-circle-raised.btn-instagram:before {
  background-color: #e12a4c;
}
.btn-circle.btn-circle-raised.btn-pinterest {
  background-color: #cb2027;
}
.btn-circle.btn-circle-raised.btn-pinterest:before {
  background-color: #b51d23;
}
.btn-circle.btn-circle-raised.btn-wordpress {
  background-color: #4597be;
}
.btn-circle.btn-circle-raised.btn-wordpress:before {
  background-color: #3c89ad;
}
.btn-circle.btn-circle-raised.btn-git {
  background-color: #f54c27;
}
.btn-circle.btn-circle-raised.btn-git:before {
  background-color: #f4380f;
}
.btn-circle.btn-circle-raised.btn-github {
  background-color: #333;
}
.btn-circle.btn-circle-raised.btn-github:before {
  background-color: #262626;
}
.btn-circle.btn-circle-raised.btn-flickr {
  background-color: #ff0084;
}
.btn-circle.btn-circle-raised.btn-flickr:before {
  background-color: #e60077;
}
.btn-circle.btn-circle-raised.btn-vine {
  background-color: #00b48a;
}
.btn-circle.btn-circle-raised.btn-vine:before {
  background-color: #009b76;
}
.btn-circle.btn-circle-raised.btn-dribbble {
  background-color: #f26798;
}
.btn-circle.btn-circle-raised.btn-dribbble:before {
  background-color: #f05088;
}
.btn-circle.btn-circle-raised.btn-foursquare {
  background-color: #0072b1;
}
.btn-circle.btn-circle-raised.btn-foursquare:before {
  background-color: #006298;
}
.btn-circle.btn-circle-raised.btn-tumblr {
  background-color: #32506d;
}
.btn-circle.btn-circle-raised.btn-tumblr:before {
  background-color: #2a435c;
}
.btn-circle.btn-circle-raised.btn-vk {
  background-color: #4c75a3;
}
.btn-circle.btn-circle-raised.btn-vk:before {
  background-color: #446992;
}
.btn-circle.btn-circle-raised.btn-reddit {
  background-color: #ff5700;
}
.btn-circle.btn-circle-raised.btn-reddit:before {
  background-color: #e64e00;
}
.btn-circle.btn-circle-raised.btn-whatsapp {
  background-color: #25d366;
}
.btn-circle.btn-circle-raised.btn-whatsapp:before {
  background-color: #21bd5c;
}
.btn-circle.btn-circle-raised.btn-soundcloud {
  background-color: #f30;
}
.btn-circle.btn-circle-raised.btn-soundcloud:before {
  background-color: #e62e00;
}
.btn-circle.btn-circle-raised.btn-dropbox {
  background-color: #007ee5;
}
.btn-circle.btn-circle-raised.btn-dropbox:before {
  background-color: #0070cc;
}
.btn-circle.btn-circle-raised.btn-vimeo {
  background-color: #1ab7ea;
}
.btn-circle.btn-circle-raised.btn-vimeo:before {
  background-color: #14a7d7;
}
.btn-circle.btn-circle-raised.btn-slack {
  background-color: #3aaf85;
}
.btn-circle.btn-circle-raised.btn-slack:before {
  background-color: #349c76;
}
.btn-circle.btn-circle-raised.btn-skype {
  background-color: #00aff0;
}
.btn-circle.btn-circle-raised.btn-skype:before {
  background-color: #009cd7;
}
.dropdown-toggle {
  border-radius: 0;
  padding-left: 8px;
  padding-right: 8px;
}
.label {
  border-radius: 2px;
  padding: 3px 6px;
  vertical-align: middle;
}
.label.label-default {
  background-color: #607d8b;
}
.label.pull-right {
  margin-top: 5px;
}
.label.label-white {
  background-color: #fff;
  color: #424242;
}
.active > .label {
  background-color: #fff;
  color: #8bc34a;
}
.badge {
  background-color: #607d8b;
  margin: 0 0 0 2px;
  padding: 0 5px;
  font-size: 11px;
  font-weight: 400;
  height: 25px;
  min-width: 25px;
  border-radius: 25px;
  line-height: 25px;
  vertical-align: middle;
  text-align: center;
  transition: all ease 0.3s;
}
.badge.badge-square {
  border-radius: 0;
}
.badge.badge-round {
  border-radius: 5px;
}
.badge:hover {
  -webkit-transform: translateY(-2px);
  transform: translateY(-2px);
}
.badge.badge-primary {
  background-color: #8bc34a;
}
.badge.badge-info {
  background-color: #00bcd4;
}
.badge.badge-royal {
  background-color: #9c27b0;
}
.badge.badge-warning {
  background-color: #ff9800;
}
.badge.badge-danger {
  background-color: #f44336;
}
.badge.badge-success {
  background-color: #4caf50;
}
.badge.badge-orange {
  background-color: #f57c00;
}
.badge.badge-pink {
  background-color: #f50057;
}
.btn-circle .badge {
  position: absolute;
  top: 0;
}
.nav.nav-stacked li a {
  color: #424242;
}
.nav.nav-stacked li a:hover,
.nav.nav-stacked li a:focus {
  background-color: #f5f5f5;
  color: #8bc34a;
}
.nav.nav-stacked li.active a {
  color: #fff;
  background-color: #8bc34a;
}
.nav.nav-stacked li.active a .badge {
  color: #7cb342;
  background-color: #fff;
}
.nav.nav-stacked li.active a:hover,
.nav.nav-stacked li.active a:focus {
  background-color: #7cb342;
}
.list-group {
  border-left: solid 1px #eee;
  border-right: solid 1px #eee;
}
.list-group .list-group-item,
.list-group a.list-group-item {
  border: 0;
  padding: 10px 15px;
  margin: 0;
  transition: all ease 0.3s;
  border-bottom: solid 1px #eee;
  vertical-align: middle;
}
.list-group .list-group-item:first-child,
.list-group a.list-group-item:first-child {
  border-top: solid 1px #eee;
}
.list-group .list-group-item > i,
.list-group a.list-group-item > i {
  margin-right: 15px;
  margin-right: 1.5rem;
}
.list-group a.list-group-item:hover,
.list-group a.list-group-item:focus {
  color: #8bc34a;
  background-color: #f5f5f5;
}
.list-group a.list-group-item.active {
  background-color: #8bc34a;
  color: #fff;
  border-color: #8bc34a;
}
.list-group a.list-group-item.active:hover,
.list-group a.list-group-item.active:focus {
  background-color: #7cb342;
}
.ms-media-list .media-object {
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: solid 2px #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 10px;
  margin-bottom: 1rem;
}
.list-line {
  list-style-type: none;
  padding: 0;
}
.list-line li {
  margin: 0;
  padding: 0;
}
.list-line li a {
  display: block;
  color: #424242;
  padding: 10px 0;
  border-bottom: dotted 1px #e0e0e0;
  transition: all ease 0.3s;
  padding-left: 10px;
}
.list-line li a:hover,
.list-line li a:focus {
  background-color: #f5f5f5;
  color: #8bc34a;
}
.list-line li:first-child a {
  border-top: dotted 1px #e0e0e0;
}
.checkbox label {
  margin-right: 20px;
  margin-right: 2rem;
}
.checkbox label .checkbox-material {
  margin-right: 10px;
  margin-right: 1rem;
}
.bootstrap-select .dropdown-toggle:focus {
  outline: none !important;
  background-color: transparent;
}
.bootstrap-select button {
  background-color: transparent !important;
  box-shadow: none !important;
}
.bootstrap-select .dropdown-menu {
  top: 0;
  padding: 0;
}
.bootstrap-select .dropdown-menu ul li a {
  padding: 10px 20px;
  padding: 1rem 2rem;
  transition: all ease 0.5s;
}
.bootstrap-select .dropdown-menu ul li a:hover,
.bootstrap-select .dropdown-menu ul li a:focus,
.bootstrap-select .dropdown-menu ul li a:active {
  background-color: #eee;
  color: #000;
}
.bootstrap-select .dropdown-menu ul li.selected a {
  background-color: #eee;
  color: #000;
  position: relative;
}
.bootstrap-select .dropdown-menu ul li.selected a .check-mark {
  display: none !important;
}
.bootstrap-select .dropdown-menu ul li.selected a:after {
  content: "\f26b";
  font-family: Material-Design-Iconic-Font;
  text-align: right;
  display: inline-block;
  float: right;
  top: 10px;
  right: 20px;
  position: absolute;
}
.form-group .input-group-addon {
  padding-left: 2px;
}
.form-group .input-group-addon i {
  font-size: 18px;
}
.form-group label {
  color: #9e9e9e !important;
}
.form-group.is-focused .radio label {
  transition: all ease 0.3s;
}
.form-group.is-focused .radio label:hover,
.form-group.is-focused .radio label:focus {
  color: #616161 !important;
}
.form-inverse .radio label span {
  border-color: #f5f5f5;
}
.form-inverse input[type="checkbox"] + .checkbox-material .check {
  border-color: #f5f5f5;
}
.form-inverse input[type="checkbox"]:checked + .checkbox-material .check {
  background-color: #f5f5f5;
}
.form-control-number {
  width: 60px !important;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px;
  display: inline-block;
  margin-top: -30px;
}
.form-inline {
  display: inline-block;
}
label.color-white {
  color: #fff !important;
}
.display-block {
  display: block !important;
}
.datepicker {
  padding: 20px;
  padding: 2rem;
}
.datepicker .datepicker-days table td {
  cursor: pointer;
  width: 35px;
  text-align: center;
}
.datepicker .datepicker-days table td.cw {
  cursor: default;
}
.datepicker .datepicker-days table thead th {
  text-align: center;
}
.datepicker .datepicker-days table thead th.prev,
.datepicker .datepicker-days table thead th.next {
  cursor: pointer;
  transition: all ease 0.3s;
  border-radius: 100%;
}
.datepicker .datepicker-days table thead th.prev:hover,
.datepicker .datepicker-days table thead th.next:hover {
  background-color: #8bc34a;
  color: #fff;
}
.datepicker .datepicker-days table tbody td {
  transition: all ease 0.3s;
  border-radius: 100%;
}
.datepicker .datepicker-days table tbody td.highlighted,
.datepicker .datepicker-days table tbody td.today {
  color: #8bc34a;
}
.datepicker .datepicker-days table tbody td.old {
  color: #bdbdbd;
}
.datepicker .datepicker-days table tbody td:hover,
.datepicker .datepicker-days table tbody td.active {
  background-color: #8bc34a;
  color: #fff;
}
.datepicker .datepicker-days table tbody td.cw {
  background-color: transparent;
  color: #00bcd4;
}
.pagination li a {
  margin: 0 8px;
  border-radius: 100%;
  color: #8bc34a;
  border-color: #e0e0e0;
  position: relative;
  z-index: 2;
  transition: all ease 0.5s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: 0;
  padding: 8px 14px;
}
.pagination li:first-child a,
.pagination li:last-child a {
  border-radius: 100%;
}
.pagination li.active a {
  background-color: #8bc34a;
  border-color: #8bc34a;
}
.pagination li.active a:hover,
.pagination li.active a:focus {
  background-color: #8bc34a;
}
.pagination li a:hover,
.pagination li a:focus {
  background-color: #8bc34a;
  border-color: #8bc34a;
  color: #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-4px);
  transform: translateY(-4px);
}
.pagination.pagination-round li a {
  border-radius: 5px;
}
.pagination.pagination-square li a {
  border-radius: 0;
}
.pagination.pagination-plain li a {
  box-shadow: none;
}
.pagination.pagination-plain li a:hover,
.pagination.pagination-plain li a:focus {
  -webkit-transform: none;
  transform: none;
}
.pagination.pagination-light li a {
  color: #f5f5f5;
}
.pagination.pagination-light li.active a {
  color: #fff;
  background-color: #f5f5f5;
}
.pagination.pagination-light li a:hover,
.pagination.pagination-light li a:focus {
  color: #fff;
  background-color: #f5f5f5;
}
.pagination.pagination-light .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-light .disabled a:hover,
.pagination.pagination-light .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-dark li a {
  color: #424242;
}
.pagination.pagination-dark li.active a {
  color: #fff;
  background-color: #424242;
}
.pagination.pagination-dark li a:hover,
.pagination.pagination-dark li a:focus {
  color: #fff;
  background-color: #424242;
}
.pagination.pagination-dark .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-dark .disabled a:hover,
.pagination.pagination-dark .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-primary li a {
  color: #8bc34a;
}
.pagination.pagination-primary li.active a {
  color: #fff;
  background-color: #8bc34a;
}
.pagination.pagination-primary li a:hover,
.pagination.pagination-primary li a:focus {
  color: #fff;
  background-color: #8bc34a;
}
.pagination.pagination-primary .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-primary .disabled a:hover,
.pagination.pagination-primary .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-info li a {
  color: #00bcd4;
}
.pagination.pagination-info li.active a {
  color: #fff;
  background-color: #00bcd4;
}
.pagination.pagination-info li a:hover,
.pagination.pagination-info li a:focus {
  color: #fff;
  background-color: #00bcd4;
}
.pagination.pagination-info .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-info .disabled a:hover,
.pagination.pagination-info .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-royal li a {
  color: #9c27b0;
}
.pagination.pagination-royal li.active a {
  color: #fff;
  background-color: #9c27b0;
}
.pagination.pagination-royal li a:hover,
.pagination.pagination-royal li a:focus {
  color: #fff;
  background-color: #9c27b0;
}
.pagination.pagination-royal .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-royal .disabled a:hover,
.pagination.pagination-royal .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-warning li a {
  color: #ff9800;
}
.pagination.pagination-warning li.active a {
  color: #fff;
  background-color: #ff9800;
}
.pagination.pagination-warning li a:hover,
.pagination.pagination-warning li a:focus {
  color: #fff;
  background-color: #ff9800;
}
.pagination.pagination-warning .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-warning .disabled a:hover,
.pagination.pagination-warning .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-danger li a {
  color: #f44336;
}
.pagination.pagination-danger li.active a {
  color: #fff;
  background-color: #f44336;
}
.pagination.pagination-danger li a:hover,
.pagination.pagination-danger li a:focus {
  color: #fff;
  background-color: #f44336;
}
.pagination.pagination-danger .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-danger .disabled a:hover,
.pagination.pagination-danger .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pagination.pagination-success li a {
  color: #4caf50;
}
.pagination.pagination-success li.active a {
  color: #fff;
  background-color: #4caf50;
}
.pagination.pagination-success li a:hover,
.pagination.pagination-success li a:focus {
  color: #fff;
  background-color: #4caf50;
}
.pagination.pagination-success .disabled a {
  color: #9e9e9e;
}
.pagination.pagination-success .disabled a:hover,
.pagination.pagination-success .disabled a:focus {
  background-color: transparent;
  color: #9e9e9e;
}
.pager li a {
  color: #8bc34a;
  border: 0;
  transition: all ease 0.5s;
  border-radius: 5px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.pager li a:hover,
.pager li a:focus {
  background-color: #8bc34a;
  color: #fff;
}
.pager.pager-flat li a {
  box-shadow: none;
}
.pager.pager-light li a {
  color: #f5f5f5;
}
.pager.pager-light li a:hover,
.pager.pager-light li a:focus {
  background-color: #f5f5f5;
  color: #fff;
}
.pager.pager-dark li a {
  color: #424242;
}
.pager.pager-dark li a:hover,
.pager.pager-dark li a:focus {
  background-color: #424242;
  color: #fff;
}
.pager.pager-primary li a {
  color: #8bc34a;
}
.pager.pager-primary li a:hover,
.pager.pager-primary li a:focus {
  background-color: #8bc34a;
  color: #fff;
}
.pager.pager-info li a {
  color: #00bcd4;
}
.pager.pager-info li a:hover,
.pager.pager-info li a:focus {
  background-color: #00bcd4;
  color: #fff;
}
.pager.pager-royal li a {
  color: #9c27b0;
}
.pager.pager-royal li a:hover,
.pager.pager-royal li a:focus {
  background-color: #9c27b0;
  color: #fff;
}
.pager.pager-warning li a {
  color: #ff9800;
}
.pager.pager-warning li a:hover,
.pager.pager-warning li a:focus {
  background-color: #ff9800;
  color: #fff;
}
.pager.pager-danger li a {
  color: #f44336;
}
.pager.pager-danger li a:hover,
.pager.pager-danger li a:focus {
  background-color: #f44336;
  color: #fff;
}
.pager.pager-success li a {
  color: #4caf50;
}
.pager.pager-success li a:hover,
.pager.pager-success li a:focus {
  background-color: #4caf50;
  color: #fff;
}
.table-striped-primary > tbody > tr:nth-child(odd) > td,
.table-striped-primary > tbody > tr:nth-child(odd) > th {
  background-color: #8bc34a;
  color: #fff;
}
.table tr {
  transition: all ease 0.3s;
}
.table tr td,
.table tr th {
  padding: 14px !important;
  border-color: #e0e0e0 !important;
}
.table tr:hover {
  background-color: #fafafa;
}
.table tr.success td {
  background-color: #d9eeda !important;
}
.table tr.info td {
  background-color: #d4faff !important;
}
.table tr.warning td {
  background-color: #fff5e6 !important;
}
.table tr.danger td {
  background-color: #fde1df !important;
}
.table tr.royal td {
  background-color: #f4def8 !important;
}
.table tr.primary td {
  background-color: #e1efd0 !important;
}
.table.table-no-border {
  border: none !important;
}
.table.table-no-border tr {
  border: none !important;
}
.table.table-no-border tr td,
.table.table-no-border tr th {
  border: none !important;
}
.card table {
  margin-bottom: 0;
}
@media (max-width: 767px) {
  .table tr td,
  .table tr th {
    padding: 8px !important;
  }
}
.vertical-center > tbody > tr > td {
  vertical-align: middle;
}
.progress {
  background-color: #eee;
}
.progress.progress-sm {
  height: 10px;
}
.progress.progress-xs {
  height: 5px;
}
.progress.progress-lg {
  height: 30px;
}
.progress.progress-lg .progress-bar {
  line-height: 30px;
}
.modal {
  color: #424242;
  background-color: rgba(0, 0, 0, 0.3);
}
.modal .modal-content {
  border-radius: 2px;
}
.modal .modal-content .modal-header {
  border-radius: 2px 2px 0 0;
  padding: 0;
}
.modal .modal-content .modal-header .close {
  color: #616161;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  font-size: 14px;
  padding: 5px;
  padding: 0.5rem;
  margin: 14px 24px 0 0;
  margin: 1.4rem 2.4rem 0 0;
}
.modal .modal-content .modal-header .close:hover,
.modal .modal-content .modal-header .close:focus {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.modal .modal-content .modal-header .modal-title {
  margin: 0;
  padding: 30px 24px 0;
  padding: 3rem 2.4rem 0;
}
.modal .modal-content .modal-body {
  padding: 24px;
  padding: 2.4rem;
}
.modal .modal-content .modal-body p:last-child {
  margin-bottom: 0;
}
.modal .modal-content .modal-footer {
  padding: 10px 20px;
  padding: 1rem 2rem;
}
.modal.modal-light .modal-content {
  border-bottom: solid 3px #f5f5f5;
}
.modal.modal-light .modal-header {
  background-color: #f5f5f5;
  color: #fff;
}
.modal.modal-light .modal-header .close {
  color: #fff;
}
.modal.modal-light .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-light .modal-header .modal-header-tabs .nav-tabs-underline:before {
  background-color: #f5f5f5;
}
.modal.modal-light .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-light
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-dark .modal-content {
  border-bottom: solid 3px #424242;
}
.modal.modal-dark .modal-header {
  background-color: #424242;
  color: #fff;
}
.modal.modal-dark .modal-header .close {
  color: #fff;
}
.modal.modal-dark .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-dark .modal-header .modal-header-tabs .nav-tabs-underline:before {
  background-color: #424242;
}
.modal.modal-dark .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-dark
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-primary .modal-content {
  border-bottom: solid 3px #8bc34a;
}
.modal.modal-primary .modal-header {
  background-color: #8bc34a;
  color: #fff;
}
.modal.modal-primary .modal-header .close {
  color: #fff;
}
.modal.modal-primary .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-primary
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline:before {
  background-color: #8bc34a;
}
.modal.modal-primary .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-primary
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-info .modal-content {
  border-bottom: solid 3px #00bcd4;
}
.modal.modal-info .modal-header {
  background-color: #00bcd4;
  color: #fff;
}
.modal.modal-info .modal-header .close {
  color: #fff;
}
.modal.modal-info .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-info .modal-header .modal-header-tabs .nav-tabs-underline:before {
  background-color: #00bcd4;
}
.modal.modal-info .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-info
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-royal .modal-content {
  border-bottom: solid 3px #9c27b0;
}
.modal.modal-royal .modal-header {
  background-color: #9c27b0;
  color: #fff;
}
.modal.modal-royal .modal-header .close {
  color: #fff;
}
.modal.modal-royal .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-royal .modal-header .modal-header-tabs .nav-tabs-underline:before {
  background-color: #9c27b0;
}
.modal.modal-royal .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-royal
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-warning .modal-content {
  border-bottom: solid 3px #ff9800;
}
.modal.modal-warning .modal-header {
  background-color: #ff9800;
  color: #fff;
}
.modal.modal-warning .modal-header .close {
  color: #fff;
}
.modal.modal-warning .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-warning
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline:before {
  background-color: #ff9800;
}
.modal.modal-warning .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-warning
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-danger .modal-content {
  border-bottom: solid 3px #f44336;
}
.modal.modal-danger .modal-header {
  background-color: #f44336;
  color: #fff;
}
.modal.modal-danger .modal-header .close {
  color: #fff;
}
.modal.modal-danger .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-danger
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline:before {
  background-color: #f44336;
}
.modal.modal-danger .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-danger
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-success .modal-content {
  border-bottom: solid 3px #4caf50;
}
.modal.modal-success .modal-header {
  background-color: #4caf50;
  color: #fff;
}
.modal.modal-success .modal-header .close {
  color: #fff;
}
.modal.modal-success .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-success
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline:before {
  background-color: #4caf50;
}
.modal.modal-success .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-success
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal.modal-primary .modal-content {
  border-bottom: solid 3px #8bc34a;
}
.modal.modal-primary .modal-header {
  background-color: #8bc34a;
  color: #fff;
}
.modal.modal-primary .modal-header .close {
  color: #fff;
}
.modal.modal-primary .modal-header .modal-title {
  padding: 14px 24px;
  padding: 1.4rem 2.4rem;
}
.modal.modal-primary
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline:before {
  background-color: #8bc34a;
}
.modal.modal-primary .modal-header .modal-header-tabs .nav-tabs-underline li a {
  color: #fff !important;
}
.modal.modal-primary
  .modal-header
  .modal-header-tabs
  .nav-tabs-underline
  .ms-tabs-indicator {
  background-color: #fff;
}
.modal-open .sb-site-container {
  position: static !important;
  overflow: hidden !important;
  width: auto !important;
  height: auto !important;
  -webkit-transform: none !important;
  transform: none !important;
}
.modal-open {
  position: static !important;
  overflow: hidden !important;
  width: auto !important;
  height: auto !important;
  padding-right: 0 !important;
}
.ms-header {
  min-height: 100px;
  background-color: #fff;
  position: relative;
  z-index: 2;
}
.ms-header .ms-title {
  padding-top: 0;
  display: inline-block;
}
.ms-header .ms-title img {
  margin: 15px 20px 0 0;
}
.ms-header .ms-title .ms-logo {
  margin-top: 20px;
  margin-right: 10px;
  vertical-align: middle;
}
.ms-header .ms-title h1 {
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  font-weight: 300;
  word-spacing: -6px;
  font-size: 32px;
  padding-top: 0;
  margin-top: 35px;
  color: #717171;
}
.ms-header .ms-title h1 span {
  font-weight: 400;
}
.ms-header .header-right {
  margin-top: 25px;
  float: right;
}
.ms-header .header-right .share-menu {
  display: inline-block;
}
.ms-header .header-right .share-menu .share-menu-button {
  display: inline-block;
}
.ms-header .header-right .share-menu .share-menu-list {
  display: none;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.ms-header .header-right .share-menu .share-menu-list li {
  display: inline-block;
}
.ms-header .header-right .share-menu:focus .share-menu-list,
.ms-header .header-right .share-menu:hover .share-menu-list {
  display: inline-block;
}
.ms-header .header-right .btn-ms-menu i {
  font-size: 21px;
  font-size: 2.1rem;
  margin-top: -2px;
}
.ms-header .header-right .search-form {
  vertical-align: top;
  display: inline-block;
  position: relative;
  height: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;
  transition: width ease 1s;
  z-index: 0;
}
.ms-header .header-right .search-form #search-submit {
  position: relative;
  left: -5000px;
  width: 0;
  padding: 0;
  border: 0;
  height: 0;
}
.ms-header .header-right .search-form label {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #8bc34a;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  transition-delay: 0.2s;
}
.ms-header .header-right .search-form label:before {
  transition: all ease 0.5s;
  border-radius: 150px;
  z-index: -1;
  content: "";
  position: absolute;
  display: block;
  background-color: #8bc34a;
  height: 0;
  width: 0;
  top: 25px;
  left: 25px;
}
.ms-header .header-right .search-form label:hover {
  color: #fff;
}
.ms-header .header-right .search-form label:hover:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.ms-header .header-right .search-form .search-input {
  height: 50px;
  border: 0;
  width: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  transition: width ease 0.5s;
  vertical-align: top;
  padding-left: 50px;
  border-radius: #616161;
  color: #000;
}
.ms-header .header-right .search-form .search-input::-webkit-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form .search-input::-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form .search-input:-ms-input-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form .search-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-header .header-right .search-form .search-input:focus {
  width: 250px;
  border-bottom: solid 3px #8bc34a;
  border-radius: 0;
}
.ms-header .header-right .search-form .search-input:focus + label {
  -webkit-transform: ScaleX(-1);
  transform: ScaleX(-1);
  color: #8bc34a;
}
.ms-header .header-right .search-form .search-input:focus + label:before {
  transition: none;
  display: none;
}
.ms-header.ms-header-dark {
  background-color: #424242;
  color: #fff;
}
.ms-header.ms-header-dark .ms-title h1 {
  color: #fff;
}
.ms-header.ms-header-dark .search-form label {
  color: #fff;
}
.ms-header.ms-header-dark .search-form label:before {
  background-color: #8bc34a;
}
.ms-header.ms-header-dark .search-form label:hover {
  color: #fff;
}
.ms-header.ms-header-dark .search-form .search-input {
  color: #fff;
  font-weight: 400;
}
.ms-header.ms-header-dark
  .search-form
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form .search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-dark .search-form .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-header.ms-header-dark .search-form .search-input:focus {
  border-bottom: solid 3px #8bc34a;
}
.ms-header.ms-header-dark .search-form .search-input:focus + label {
  color: #fff;
}
.ms-header.ms-header-dark .btn-circle-primary {
  color: #fff;
}
.ms-header.ms-header-dark .btn-circle-primary:before {
  background-color: #8bc34a;
}
.ms-header.ms-header-dark .btn-circle-primary:hover,
.ms-header.ms-header-dark .btn-circle-primary:focus {
  color: #fff;
}
.ms-header.ms-header-primary {
  background-color: #8bc34a;
  color: #fff;
}
.ms-header.ms-header-primary .btn-circle {
  color: #fff;
}
.ms-header.ms-header-primary .btn-circle:before {
  background-color: #fff;
}
.ms-header.ms-header-primary .btn-circle:hover,
.ms-header.ms-header-primary .btn-circle:focus {
  color: #8bc34a;
}
.ms-header.ms-header-primary .btn-circle.no-focus:focus {
  color: #fff;
}
.ms-header.ms-header-primary .btn-circle.no-focus:focus:before {
  height: 0;
  width: 0;
  top: 25px;
  left: 25px;
}
.ms-header.ms-header-primary .btn-circle.no-focus:hover {
  color: #8bc34a;
}
.ms-header.ms-header-primary .btn-circle.no-focus:hover:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.ms-header.ms-header-primary .ms-title h1 {
  color: #fff;
}
.ms-header.ms-header-primary .ms-logo {
  color: #8bc34a;
  background-color: #fff;
  text-shadow: 1px 1px 0 rgba(139, 195, 74, 0.04),
    1px 2px 0 rgba(139, 195, 74, 0.04), 1px 3px 0 rgba(139, 195, 74, 0.04),
    2px 4px 0 rgba(139, 195, 74, 0.04), 3px 5px 0 rgba(139, 195, 74, 0.04),
    4px 6px 0 rgba(139, 195, 74, 0.04), 5px 7px 0 rgba(139, 195, 74, 0.04),
    6px 8px 0 rgba(139, 195, 74, 0.04), 7px 9px 0 rgba(139, 195, 74, 0.04),
    8px 10px 0 rgba(139, 195, 74, 0.04), 9px 11px 0 rgba(139, 195, 74, 0.04),
    10px 12px 0 rgba(139, 195, 74, 0.04), 11px 13px 0 rgba(139, 195, 74, 0.04),
    12px 14px 0 rgba(139, 195, 74, 0.04), 13px 15px 0 rgba(139, 195, 74, 0.04),
    14px 16px 0 rgba(139, 195, 74, 0.04), 15px 17px 0 rgba(139, 195, 74, 0.04),
    16px 18px 0 rgba(139, 195, 74, 0.04), 17px 19px 0 rgba(139, 195, 74, 0.04),
    18px 20px 0 rgba(139, 195, 74, 0.04), 19px 21px 0 rgba(139, 195, 74, 0.04),
    20px 22px 0 rgba(139, 195, 74, 0.04), 21px 23px 0 rgba(139, 195, 74, 0.04);
}
.ms-header.ms-header-primary .search-form label {
  color: #fff;
}
.ms-header.ms-header-primary .search-form label:before {
  background-color: #fff;
}
.ms-header.ms-header-primary .search-form label:hover {
  color: #8bc34a;
}
.ms-header.ms-header-primary .search-form .search-input {
  color: #fff;
  font-weight: 400;
}
.ms-header.ms-header-primary
  .search-form
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form .search-input:-moz-placeholder {
  color: rgba(255, 255, 255, 0.5);
}
.ms-header.ms-header-primary .search-form .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-header.ms-header-primary .search-form .search-input:focus {
  border-bottom: solid 3px #fff;
}
.ms-header.ms-header-primary .search-form .search-input:focus + label {
  color: #fff;
}
.ms-logo {
  width: 50px;
  height: 50px;
  display: inline-block;
  font-size: 30px;
  line-height: 50px;
  font-weight: 400;
  vertical-align: middle;
  background-color: #8bc34a;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  overflow: hidden;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05), 1px 2px 0 rgba(0, 0, 0, 0.05),
    1px 3px 0 rgba(0, 0, 0, 0.05), 2px 4px 0 rgba(0, 0, 0, 0.05),
    3px 5px 0 rgba(0, 0, 0, 0.05), 4px 6px 0 rgba(0, 0, 0, 0.05),
    5px 7px 0 rgba(0, 0, 0, 0.05), 6px 8px 0 rgba(0, 0, 0, 0.05),
    7px 9px 0 rgba(0, 0, 0, 0.05), 8px 10px 0 rgba(0, 0, 0, 0.05),
    9px 11px 0 rgba(0, 0, 0, 0.05), 10px 12px 0 rgba(0, 0, 0, 0.05),
    11px 13px 0 rgba(0, 0, 0, 0.05), 12px 14px 0 rgba(0, 0, 0, 0.05),
    13px 15px 0 rgba(0, 0, 0, 0.05), 14px 16px 0 rgba(0, 0, 0, 0.05),
    15px 17px 0 rgba(0, 0, 0, 0.05), 16px 18px 0 rgba(0, 0, 0, 0.05),
    17px 19px 0 rgba(0, 0, 0, 0.05), 18px 20px 0 rgba(0, 0, 0, 0.05),
    19px 21px 0 rgba(0, 0, 0, 0.05), 20px 22px 0 rgba(0, 0, 0, 0.05),
    21px 23px 0 rgba(0, 0, 0, 0.05);
}
.ms-logo.ms-logo-white {
  color: #8bc34a;
  background-color: #fff;
  text-shadow: 1px 1px 0 rgba(139, 195, 74, 0.04),
    1px 2px 0 rgba(139, 195, 74, 0.04), 1px 3px 0 rgba(139, 195, 74, 0.04),
    2px 4px 0 rgba(139, 195, 74, 0.04), 3px 5px 0 rgba(139, 195, 74, 0.04),
    4px 6px 0 rgba(139, 195, 74, 0.04), 5px 7px 0 rgba(139, 195, 74, 0.04),
    6px 8px 0 rgba(139, 195, 74, 0.04), 7px 9px 0 rgba(139, 195, 74, 0.04),
    8px 10px 0 rgba(139, 195, 74, 0.04), 9px 11px 0 rgba(139, 195, 74, 0.04),
    10px 12px 0 rgba(139, 195, 74, 0.04), 11px 13px 0 rgba(139, 195, 74, 0.04),
    12px 14px 0 rgba(139, 195, 74, 0.04), 13px 15px 0 rgba(139, 195, 74, 0.04),
    14px 16px 0 rgba(139, 195, 74, 0.04), 15px 17px 0 rgba(139, 195, 74, 0.04),
    16px 18px 0 rgba(139, 195, 74, 0.04), 17px 19px 0 rgba(139, 195, 74, 0.04),
    18px 20px 0 rgba(139, 195, 74, 0.04), 19px 21px 0 rgba(139, 195, 74, 0.04),
    20px 22px 0 rgba(139, 195, 74, 0.04), 21px 23px 0 rgba(139, 195, 74, 0.04);
}
.ms-logo.ms-logo-sm {
  height: 35px;
  width: 35px;
  line-height: 35px;
  font-size: 18px;
}
.ms-logo.ms-logo-lg {
  height: 70px;
  width: 70px;
  line-height: 70px;
  font-size: 36px;
}
.ms-navbar .ms-logo {
  color: #8bc34a;
  background-color: #fff;
  text-shadow: 1px 1px 0 rgba(139, 195, 74, 0.04),
    1px 2px 0 rgba(139, 195, 74, 0.04), 1px 3px 0 rgba(139, 195, 74, 0.04),
    2px 4px 0 rgba(139, 195, 74, 0.04), 3px 5px 0 rgba(139, 195, 74, 0.04),
    4px 6px 0 rgba(139, 195, 74, 0.04), 5px 7px 0 rgba(139, 195, 74, 0.04),
    6px 8px 0 rgba(139, 195, 74, 0.04), 7px 9px 0 rgba(139, 195, 74, 0.04),
    8px 10px 0 rgba(139, 195, 74, 0.04), 9px 11px 0 rgba(139, 195, 74, 0.04),
    10px 12px 0 rgba(139, 195, 74, 0.04), 11px 13px 0 rgba(139, 195, 74, 0.04),
    12px 14px 0 rgba(139, 195, 74, 0.04), 13px 15px 0 rgba(139, 195, 74, 0.04),
    14px 16px 0 rgba(139, 195, 74, 0.04), 15px 17px 0 rgba(139, 195, 74, 0.04),
    16px 18px 0 rgba(139, 195, 74, 0.04), 17px 19px 0 rgba(139, 195, 74, 0.04),
    18px 20px 0 rgba(139, 195, 74, 0.04), 19px 21px 0 rgba(139, 195, 74, 0.04),
    20px 22px 0 rgba(139, 195, 74, 0.04), 21px 23px 0 rgba(139, 195, 74, 0.04);
}
.ms-navbar.ms-navbar-white .ms-logo,
.ms-navbar.ms-navbar-dark .ms-logo {
  color: #fff;
  background-color: #8bc34a;
  text-shadow: 1px 1px 0 rgba(0, 0, 0, 0.05), 1px 2px 0 rgba(0, 0, 0, 0.05),
    1px 3px 0 rgba(0, 0, 0, 0.05), 2px 4px 0 rgba(0, 0, 0, 0.05),
    3px 5px 0 rgba(0, 0, 0, 0.05), 4px 6px 0 rgba(0, 0, 0, 0.05),
    5px 7px 0 rgba(0, 0, 0, 0.05), 6px 8px 0 rgba(0, 0, 0, 0.05),
    7px 9px 0 rgba(0, 0, 0, 0.05), 8px 10px 0 rgba(0, 0, 0, 0.05),
    9px 11px 0 rgba(0, 0, 0, 0.05), 10px 12px 0 rgba(0, 0, 0, 0.05),
    11px 13px 0 rgba(0, 0, 0, 0.05), 12px 14px 0 rgba(0, 0, 0, 0.05),
    13px 15px 0 rgba(0, 0, 0, 0.05), 14px 16px 0 rgba(0, 0, 0, 0.05),
    15px 17px 0 rgba(0, 0, 0, 0.05), 16px 18px 0 rgba(0, 0, 0, 0.05),
    17px 19px 0 rgba(0, 0, 0, 0.05), 18px 20px 0 rgba(0, 0, 0, 0.05),
    19px 21px 0 rgba(0, 0, 0, 0.05), 20px 22px 0 rgba(0, 0, 0, 0.05),
    21px 23px 0 rgba(0, 0, 0, 0.05);
}
.ms-navbar {
  background-color: #8bc34a;
  color: #fff;
  border: none;
  height: 50px;
  margin-bottom: 40px;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.29);
}
.ms-navbar .navbar-brand {
  font-weight: 300;
  word-spacing: -4px;
  font-size: 20px;
  padding-top: 9px;
  margin: 0 20px;
  color: #f5f5f5;
  display: none;
  height: 50px;
  white-space: nowrap;
}
.ms-navbar .navbar-brand img {
  display: inline-block;
  margin-right: 10px;
}
.ms-navbar .navbar-brand .ms-title strong {
  display: inline-block;
  font-weight: 400;
}
.ms-navbar .navbar-brand .ms-logo {
  margin-top: -5px;
  margin-right: 10px;
}
.ms-navbar .btn-navbar-menu {
  display: inline-block;
  color: #fff;
  height: 50px;
  width: 50px;
  line-height: 50px;
  text-align: center;
  position: absolute;
  right: 15px;
  top: 0;
  font-size: 21px;
  display: none;
}
@-webkit-keyframes fadeInDownPerso {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDownPerso {
  from {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(0, -100%, 0);
    transform: translate3d(0, -100%, 0);
  }
  to {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.ms-navbar .fadeInDownPerso {
  -webkit-animation-name: fadeInDownPerso;
  animation-name: fadeInDownPerso;
}
.ms-navbar.shrink {
  -webkit-animation: fadeInDownPerso;
  animation: fadeInDownPerso;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.ms-navbar.shrink .btn-navbar-menu {
  display: block;
}
.ms-navbar.shrink .navbar-brand {
  display: inline-block;
}
.ms-navbar.shrink .btn-menu {
  display: none;
}
.ms-navbar .navbar-nav.navbar-right {
  margin-right: 50px;
}
.ms-navbar .navbar-nav li {
  font-size: 16px;
}
.ms-navbar .navbar-nav li a {
  font-weight: 400;
  color: #fff;
  padding: 15px 20px;
}
.ms-navbar .navbar-nav li a i {
  transition: all ease 0.5s;
  margin-left: 0;
}
.ms-navbar .navbar-nav li a:hover,
.ms-navbar .navbar-nav li a:focus {
  background-color: #79af3a;
  transition: background 0.3s;
}
.ms-navbar .navbar-nav li.active > a,
.ms-navbar .navbar-nav li.active > a:hover,
.ms-navbar .navbar-nav li.active > a:focus {
  background-color: #79af3a;
}
.ms-navbar .navbar-nav li.dropdown {
  position: relative;
}
.ms-navbar .navbar-nav li.dropdown > a i {
  padding: 0;
  vertical-align: middle;
  margin-left: 7px;
  font-size: 16px;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu {
  padding: 0;
  background-color: #424242;
  border-radius: 0;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.29), 0 3px 3px rgba(0, 0, 0, 0.29);
  display: block;
  -webkit-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
  left: 0;
  right: auto;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu:focus,
.ms-navbar .navbar-nav li.dropdown .dropdown-menu:hover {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li a {
  color: #f5f5f5;
  padding: 10px 35px 10px 20px;
  font-weight: 400;
  font-size: 15px;
  transition: none;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li a.with-label {
  position: relative;
  padding-right: 60px;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li a.with-label .label {
  position: absolute;
  right: 10px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li a i {
  margin-right: 15px;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li .ms-menu-double {
  display: table;
  width: 100%;
  transition: all ease 0.3s;
  min-width: 500px;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li .ms-menu-double li a {
  background-color: transparent;
  display: block;
  padding: 8px 20px;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu li .ms-menu-double li a:hover,
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  li
  a:focus {
  background-color: #8bc34a;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  li.active
  a {
  background-color: #8bc34a;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-main-menu {
  width: 50%;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: table-cell;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a {
  position: relative;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:after {
  font-family: Material-Design-Iconic-Font;
  content: "\f2fb";
  float: right;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-submenu-container {
  width: 50%;
  display: inline-block;
  margin: 0;
  padding: 0;
  list-style-type: none;
  vertical-align: top;
  height: 100%;
  background-color: #3a3a3a;
  display: table-cell;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-submenu-container
  .ms-menu-double-submenu {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  li
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a {
  cursor: not-allowed;
  color: #bdbdbd;
  background-color: none;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu .dropdown-link:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: #424242;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu .dropdown-submenu {
  position: relative;
  overflow: visible;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu .dropdown-submenu > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: -1;
  height: 100%;
  background-color: #424242;
}
.ms-navbar .navbar-nav li.dropdown .dropdown-menu .dropdown-submenu > a:after {
  margin-right: -15px;
  font-family: Material-Design-Iconic-Font;
  content: "\f2fb";
  float: right;
  vertical-align: middle;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu {
  top: 0;
  left: 99%;
  margin-top: 0;
  margin-left: 0;
  z-index: 0;
  background-color: #3a3a3a;
  display: none;
  z-index: -2;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu.open_t,
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu:hover,
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu:focus {
  display: block;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  a {
  background-color: transparent;
}
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  a:hover,
.ms-navbar
  .navbar-nav
  li.dropdown
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  a:focus {
  background-color: #8bc34a;
}
.ms-navbar .navbar-nav li.dropdown.open > a,
.ms-navbar .navbar-nav li.dropdown.open > a:hover,
.ms-navbar .navbar-nav li.dropdown.open > a:focus {
  background-color: #424242;
}
.ms-navbar .navbar-nav li.dropdown.open .dropdown-menu {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all 0.4s ease;
}
.ms-navbar .navbar-nav li.dropdown.yamm-fw {
  position: static;
}
.ms-navbar .navbar-nav li.dropdown.yamm-fw .dropdown-megamenu {
  background-color: transparent;
  box-shadow: none;
  padding: 0 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  display: none;
  right: 0;
  left: 0;
}
.ms-navbar .navbar-nav li.dropdown.yamm-fw .dropdown-megamenu > li {
  background-color: #424242;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.29), 0 3px 3px rgba(0, 0, 0, 0.29);
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col {
  border-right: solid 1px #3a3a3a;
  padding: 0;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col:first-child {
  padding-left: 15px;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col:last-child {
  border: none;
  padding-right: 15px;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title {
  margin: 0;
  font-size: 17px;
  font-weight: 400;
  text-transform: uppercase;
  padding: 30px 20px 20px;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title
  i {
  margin: 0 12px 0 0;
  color: #8bc34a;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-list
  li
  a {
  display: block;
  padding: 8px 20px;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-list
  li
  a
  i {
  transition: all ease 0.3s;
}
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-list
  li
  a:hover
  i,
.ms-navbar
  .navbar-nav
  li.dropdown.yamm-fw
  .dropdown-megamenu
  > li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-list
  li
  a:focus
  i {
  margin-right: 25px;
}
.ms-navbar .navbar-nav li.dropdown.yamm-fw.open .dropdown-megamenu {
  display: block;
}
.ms-navbar.ms-navbar-white {
  background-color: #fff;
}
.ms-navbar.ms-navbar-white .navbar-brand {
  color: #424242;
}
.ms-navbar.ms-navbar-white .btn-navbar-menu {
  color: #424242;
}
.ms-navbar.ms-navbar-white .navbar-nav > li > a {
  color: #616161;
}
.ms-navbar.ms-navbar-white .navbar-nav > li > a:hover,
.ms-navbar.ms-navbar-white .navbar-nav > li > a:focus {
  color: #fff;
  background-color: #424242;
}
.ms-navbar.ms-navbar-white .navbar-nav > li.active > a,
.ms-navbar.ms-navbar-white .navbar-nav > li.open > a {
  background-color: #424242 !important;
  color: #fff;
}
.ms-navbar.ms-navbar-dark {
  background-color: #424242;
}
.ms-navbar.ms-navbar-dark .navbar-nav > li > a {
  color: #fff;
}
.ms-navbar.ms-navbar-dark .navbar-nav > li > a:hover,
.ms-navbar.ms-navbar-dark .navbar-nav > li > a:focus {
  color: #8bc34a;
  background-color: #fff;
}
.ms-navbar.ms-navbar-dark .navbar-nav > li.active > a,
.ms-navbar.ms-navbar-dark .navbar-nav > li.open > a {
  background-color: #fff;
  color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li,
.ms-navbar-dark.navbar-mode .navbar-nav > li,
.ms-navbar-primary.navbar-mode .navbar-nav > li,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li,
.ms-header-white + .ms-navbar-white .navbar-nav > li,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li {
  position: relative;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a {
  background-color: transparent;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a i,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a i,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a i,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a i,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a i,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a i {
  transition: all ease 0.5s;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a:before,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:before,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:before,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a:before {
  position: absolute;
  display: block;
  content: "";
  background-color: #fff;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 0;
  transition: height ease 0.3s;
  z-index: -1;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.active a:before,
.ms-navbar-dark.navbar-mode .navbar-nav > li.active a:before,
.ms-navbar-primary.navbar-mode .navbar-nav > li.active a:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.active a:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li.active a:before,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li.active a:before {
  height: 4px;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.active a:focus,
.ms-navbar-dark.navbar-mode .navbar-nav > li.active a:focus,
.ms-navbar-primary.navbar-mode .navbar-nav > li.active a:focus,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.active a:focus,
.ms-header-white + .ms-navbar-white .navbar-nav > li.active a:focus,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li.active a:focus {
  background-color: transparent;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a:hover,
.ms-navbar-white.navbar-mode .navbar-nav > li > a:focus,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:hover,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:focus,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a:hover,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a:focus,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:hover,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:focus,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:hover,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:focus,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a:hover,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a:focus {
  color: #8bc34a !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a:hover:before,
.ms-navbar-white.navbar-mode .navbar-nav > li > a:focus:before,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:hover:before,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:focus:before,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a:hover:before,
.ms-navbar-primary.navbar-mode .navbar-nav > li > a:focus:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:hover:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:focus:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:hover:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:focus:before,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a:hover:before,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li > a:focus:before {
  height: 100%;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.open > a,
.ms-navbar-dark.navbar-mode .navbar-nav > li.open > a,
.ms-navbar-primary.navbar-mode .navbar-nav > li.open > a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.open > a,
.ms-header-white + .ms-navbar-white .navbar-nav > li.open > a,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li.open > a {
  background-color: transparent !important;
  color: #8bc34a !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.open > a:before,
.ms-navbar-dark.navbar-mode .navbar-nav > li.open > a:before,
.ms-navbar-primary.navbar-mode .navbar-nav > li.open > a:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.open > a:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li.open > a:before,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li.open > a:before {
  height: 100%;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.open > a i,
.ms-navbar-dark.navbar-mode .navbar-nav > li.open > a i,
.ms-navbar-primary.navbar-mode .navbar-nav > li.open > a i,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.open > a i,
.ms-header-white + .ms-navbar-white .navbar-nav > li.open > a i,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li.open > a i {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li .dropdown-menu {
  background-color: #fff;
  color: #616161;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu li a,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu li a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu li a,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li .dropdown-menu li a {
  color: #616161;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a:hover,
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a:focus,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu li a:hover,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu li a:focus,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu li a:hover,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu li a:focus,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu li a:hover,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu li a:focus,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a:hover,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a:focus,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  li
  a:hover,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  li
  a:focus {
  color: #8bc34a;
  background-color: #eee;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li.active a,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu li.active a,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu li.active a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu li.active a,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li.active a,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  a {
  color: #8bc34a;
  background-color: #eee;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li.divider,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu li.divider,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-menu li.divider,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-menu li.divider,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li.divider,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  li.divider {
  background-color: #e9e9e9;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus {
  color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a {
  color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container {
  background-color: #eee !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a {
  color: #616161;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:hover,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li
  a:focus {
  background-color: #e1e1e1;
  color: #8bc34a;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-submenu-container
  li.disable
  a {
  cursor: not-allowed;
  color: #bdbdbd;
  background-color: none;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before {
  background-color: #fff;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu .dropdown-submenu,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-menu .dropdown-submenu,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu {
  background-color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu {
  background-color: #f5f5f5;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus {
  background-color: #e1e1e1;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-megamenu li,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-megamenu li,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-megamenu li,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-megamenu li,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-megamenu li,
.ms-header-primary + .ms-navbar-primary .navbar-nav > li .dropdown-megamenu li {
  background-color: #fff !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-megamenu li a,
.ms-navbar-dark.navbar-mode .navbar-nav > li .dropdown-megamenu li a,
.ms-navbar-primary.navbar-mode .navbar-nav > li .dropdown-megamenu li a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li .dropdown-megamenu li a,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-megamenu li a,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  a {
  color: #616161;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col {
  border-color: #eee !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-navbar-dark.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-navbar-primary.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-header-dark
  + .ms-navbar-dark
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-header-primary
  + .ms-navbar-primary
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title {
  color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a:before,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:before {
  background-color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li > a:hover,
.ms-navbar-white.navbar-mode .navbar-nav > li > a:focus,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:hover,
.ms-header-white + .ms-navbar-white .navbar-nav > li > a:focus {
  color: #fff !important;
  background-color: transparent;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.open > a,
.ms-header-white + .ms-navbar-white .navbar-nav > li.open > a {
  color: #fff !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li.active > a,
.ms-header-white + .ms-navbar-white .navbar-nav > li.active > a {
  background-color: transparent !important;
  color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu {
  background-color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a {
  color: #fff;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a:hover,
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li a:focus,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a:hover,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu li a:focus {
  background-color: #424242;
  color: #fff;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu li.active a,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  li.active
  a {
  background-color: #424242;
  color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-link:before {
  background-color: #8bc34a;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu .divider,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-menu .divider {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-menu .dropdown-submenu,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu {
  background-color: #8bc34a;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu {
  background-color: #7cb342;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:hover,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li
  a:focus {
  background-color: #424242;
  color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li.divider,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .dropdown-submenu
  .dropdown-menu
  li.divider {
  background-color: #77ac3f !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:hover,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li
  a:focus {
  background-color: #424242 !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-menu
  .ms-menu-double
  .ms-menu-double-main-menu
  li.active
  a {
  background-color: #424242 !important;
}
.ms-navbar-white.navbar-mode .navbar-nav > li .dropdown-megamenu li,
.ms-header-white + .ms-navbar-white .navbar-nav > li .dropdown-megamenu li {
  background-color: #8bc34a !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col {
  border-color: #7cb342 !important;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title {
  color: #fff;
}
.ms-navbar-white.navbar-mode
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title
  i,
.ms-header-white
  + .ms-navbar-white
  .navbar-nav
  > li
  .dropdown-megamenu
  li
  .row
  .megamenu-col
  .megamenu-block
  .megamenu-block-title
  i {
  color: #fff !important;
}
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:before,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:before {
  background-color: #fff;
}
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:hover,
.ms-navbar-dark.navbar-mode .navbar-nav > li > a:focus,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:hover,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li > a:focus {
  color: #8bc34a !important;
  background-color: transparent;
}
.ms-navbar-dark.navbar-mode .navbar-nav > li.open > a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.open > a {
  color: #8bc34a !important;
}
.ms-navbar-dark.navbar-mode .navbar-nav > li.active > a,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.active > a {
  background-color: transparent !important;
  color: #fff;
}
.ms-navbar-dark.navbar-mode .navbar-nav > li.divider,
.ms-header-dark + .ms-navbar-dark .navbar-nav > li.divider {
  background-color: #9ccc65 !important;
}
.navbar-mode .navbar-brand {
  display: block !important;
}
.navbar-mode .btn-navbar-menu {
  display: block;
}
.navbar-mode .navbar-nav {
  float: right;
  margin-right: 50px;
}
@media (max-width: 991px) {
  .dropdown-megamenu .megamenu-block .megamenu-block-title {
    font-size: 15px !important;
    margin: 0 !important;
    font-weight: 500;
    padding: 25px 10px 10px !important;
  }
  .dropdown-megamenu .megamenu-block .megamenu-block-title i {
    display: none;
  }
  .dropdown-megamenu .megamenu-block .megamenu-block-list li a {
    font-size: 14px;
    padding: 6px 10px !important;
  }
  .dropdown-megamenu .megamenu-block .megamenu-block-list li a i {
    display: none;
  }
}
.panel {
  border: 0;
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.panel .panel-body {
  padding: 20px;
  padding: 2rem;
}
.panel .panel-body > p:last-child {
  margin-bottom: 0;
}
.panel .panel-heading {
  padding: 15px;
}
.panel .panel-heading .panel-title {
  color: #fff;
}
.panel .panel-heading .panel-title i {
  margin-right: 10px;
  margin-right: 1rem;
}
.panel.panel-flat {
  box-shadow: none;
  background-color: transparent;
}
.panel.panel-black .panel-heading {
  background-color: #424242;
}
.panel.panel-dark.panel-default .panel-heading,
.panel.panel-dark.panel-default .panel-footer {
  background-color: #e1e1e1;
  color: #424242;
}
.panel.panel-dark.panel-default .panel-body {
  background-color: #eee;
  color: #424242;
}
.panel.panel-dark.panel-black .panel-heading,
.panel.panel-dark.panel-black .panel-footer {
  background-color: #353535;
  color: #fff;
}
.panel.panel-dark.panel-black .panel-body {
  background-color: #424242;
  color: #f5f5f5;
}
.panel.panel-dark.panel-primary .panel-heading,
.panel.panel-dark.panel-primary .panel-footer {
  background-color: #7eb73d;
}
.panel.panel-dark.panel-primary .panel-body {
  background-color: #8bc34a;
  color: #fff;
}
.panel.panel-dark.panel-info .panel-heading,
.panel.panel-dark.panel-info .panel-footer {
  background-color: #00a5bb;
}
.panel.panel-dark.panel-info .panel-body {
  background-color: #00bcd4;
  color: #fff;
}
.panel.panel-dark.panel-success .panel-heading,
.panel.panel-dark.panel-success .panel-footer {
  background-color: #449d48;
  color: #fff;
}
.panel.panel-dark.panel-success .panel-body {
  background-color: #4caf50;
  color: #fff;
}
.panel.panel-dark.panel-warning .panel-heading,
.panel.panel-dark.panel-warning .panel-footer {
  background-color: #e68900;
}
.panel.panel-dark.panel-warning .panel-body {
  background-color: #ff9800;
  color: #fff;
}
.panel.panel-dark.panel-danger .panel-heading,
.panel.panel-dark.panel-danger .panel-footer {
  background-color: #f32c1e;
}
.panel.panel-dark.panel-danger .panel-body {
  background-color: #f44336;
  color: #fff;
}
.panel.panel-dark.panel-royal .panel-heading,
.panel.panel-dark.panel-royal .panel-footer {
  background-color: #89229b;
}
.panel.panel-dark.panel-royal .panel-body {
  background-color: #9c27b0;
  color: #fff;
}
.panel.panel-default .panel-heading .panel-title {
  color: #424242;
}
.panel .panel-footer {
  border: 0;
}
.panel .panel-footer > p:last-child {
  margin-bottom: 0;
}
.panel.panel-primary .panel-footer {
  background-color: #8bc34a;
  color: #fff;
}
.panel.panel-info .panel-footer {
  background-color: #00bcd4;
  color: #fff;
}
.panel.panel-succ .panel-footer {
  background-color: #4caf50;
  color: #fff;
}
.panel.panel-royal .panel-footer {
  background-color: #9c27b0;
  color: #fff;
}
.panel.panel-warning .panel-footer {
  background-color: #ff9800;
  color: #fff;
}
.panel.panel-danger .panel-footer {
  background-color: #f44336;
  color: #fff;
}
.panel-code-heading {
  padding: 0 !important;
  margin: 0;
  border-radius: 0;
}
.panel-code-heading .panel-code-title a {
  display: block;
  color: #fff;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  line-height: 1rem;
  padding: 14px 15px;
  padding: 1.4rem 1.5rem;
  position: relative;
}
.panel-code-heading .panel-code-title a i {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 10px;
  margin-right: 1rem;
  transition: all ease 0.4s;
}
.panel-code-heading .panel-code-title a:hover,
.panel-code-heading .panel-code-title a:focus {
  text-decoration: none;
}
.panel-code-heading .panel-code-title a:after {
  position: absolute;
  right: 25px;
  right: 2.5rem;
  top: 17px;
  top: 1.7rem;
  font-family: FontAwesome;
  content: "\f107";
  -webkit-transform: rotate(0);
  transform: rotate(0);
  transition: all ease 0.4s;
}
.panel-code-heading .panel-code-title a.collapsed i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.panel-code-heading .panel-code-title a.collapsed:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.panel-code .panel-collapse {
  margin: 0;
}
.panel-code .panel-collapse .panel-code-body pre {
  margin: 0;
  border: 0;
  border-radius: 0;
}
.ms-paper {
  -webkit-animation-name: shadow-retard, slideInLeftTiny;
  animation-name: shadow-retard, slideInLeftTiny;
  -webkit-animation-duration: 5s, 1.4s;
  animation-duration: 5s, 1.4s;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), ease;
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1), ease;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin-bottom: 30px;
  margin-bottom: 3rem;
  will-change: box-shadow;
}
@-webkit-keyframes shadow-retard {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  }
}
@keyframes shadow-retard {
  0% {
    box-shadow: none;
  }
  100% {
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.24);
  }
}
.ms-paper > .row {
  overflow: hidden;
}
.ms-paper > .row > [class*="col-"] {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.ms-paper > .row .ms-paper-menu-left-container {
  padding-right: 0;
  -webkit-animation-name: slideInRight;
  animation-name: slideInRight;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}
.ms-paper > .row .ms-paper-menu-left-container .ms-paper-menu-left {
  background-color: #eee;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  position: relative;
}
.ms-paper
  > .row
  .ms-paper-menu-left-container
  .ms-paper-menu-left
  .ms-paper-menu-title {
  margin: 0;
  padding: 30px 10px;
  text-align: center;
  font-size: 17px;
  font-size: 1.7rem;
  background-color: #8bc34a;
  color: #fff;
  text-transform: uppercase;
  position: relative;
}
.ms-paper
  > .row
  .ms-paper-menu-left-container
  .ms-paper-menu-left
  .ms-paper-menu-title
  i {
  margin-right: 10px;
}
.ms-paper
  > .row
  .ms-paper-menu-left-container
  .ms-paper-menu-left
  .ms-paper-menu-title
  a {
  display: none;
  color: #fff;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.ms-paper
  > .row
  .ms-paper-menu-left-container
  .ms-paper-menu-left
  .ms-paper-menu-title
  a
  i {
  position: absolute;
  top: 36%;
  right: 7px;
  right: 0.7rem;
  font-size: 21px;
  font-size: 2.1rem;
}
.ms-paper > .row .ms-paper-content-container {
  padding-left: 0;
}
.ms-paper > .row .ms-paper-content-container .ms-paper-content {
  padding: 30px;
  padding: 3rem;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  background-color: #fff;
  margin-bottom: -99999px;
  padding-bottom: 99999px;
}
.ms-paper > .row .ms-paper-content-container .ms-paper-content > *:first-child {
  margin-top: 0;
}
.ms-collapse-nav {
  margin: 0;
  padding: 0;
}
.ms-collapse-nav li {
  list-style-type: none;
  list-style-position: outside;
  padding: 0;
  margin: 0;
}
.ms-collapse-nav li a {
  display: block;
  padding: 10px 15px;
  text-decoration: none;
  color: #424242;
  background-color: #eee;
}
.ms-collapse-nav li a:after {
  font-family: Material-Design-Iconic-Font;
  font-size: 1.2em;
  float: right;
  content: "\f2fb";
}
.ms-collapse-nav li a i {
  margin-right: 10px;
}
.ms-collapse-nav li a:hover,
.ms-collapse-nav li a:focus,
.ms-collapse-nav li a.active {
  background-color: #8bc34a;
  color: #fff;
}
.ms-collapse-nav li.panel {
  background-color: transparent;
  box-shadow: none;
}
.ms-collapse-nav li.panel a {
  background-color: #8bc34a;
  color: #fff;
}
.ms-collapse-nav li.panel a:after {
  content: "\f273";
}
.ms-collapse-nav li.panel a.collapsed {
  background-color: #eee;
  color: #424242;
}
.ms-collapse-nav li.panel a.collapsed:after {
  content: "\f278";
}
.ms-collapse-nav li.panel a.collapsed:hover {
  background-color: #8bc34a;
  color: #fff;
}
.ms-collapse-nav li.panel ul {
  box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.2) !important;
  background-color: #e0e0e0;
  padding: 0;
}
.ms-collapse-nav li.panel ul a {
  color: #424242;
  background-color: transparent;
}
.ms-collapse-nav li.panel ul a:after {
  content: "\f2fb";
}
.ms-collapse-nav li.panel ul a:hover,
.ms-collapse-nav li.panel ul a:focus,
.ms-collapse-nav li.panel ul a.active {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
}
.ms-collapse-nav li.panel + .panel {
  margin: 0;
}
.ms-component-section .wrap-inverse {
  background-color: #8bc34a;
  color: #fff;
  margin: 40px -30px;
  margin: 4rem -3rem;
  padding: 20px 40px;
  padding: 2rem 4rem;
}
.ms-component-section .wrap-inverse .section-title {
  color: #fff;
}
.ms-component-section .wrap-inverse h1,
.ms-component-section .wrap-inverse h2,
.ms-component-section .wrap-inverse h3,
.ms-component-section .wrap-inverse h4,
.ms-component-section .wrap-inverse h5,
.ms-component-section .wrap-inverse h6 {
  color: #fff;
}
.ms-component-section .wrap-light {
  background-color: #f5f5f5;
  margin: 40px -30px;
  margin: 4rem -3rem;
  padding: 20px 40px;
  padding: 2rem 4rem;
}
.tooltip-primary + .tooltip .tooltip-inner {
  background-color: #8bc34a;
}
.tooltip-light + .tooltip .tooltip-inner {
  background-color: #f5f5f5;
}
.tooltip-dark + .tooltip .tooltip-inner {
  background-color: #424242;
}
.tooltip-primary + .tooltip .tooltip-inner {
  background-color: #8bc34a;
}
.tooltip-info + .tooltip .tooltip-inner {
  background-color: #00bcd4;
}
.tooltip-royal + .tooltip .tooltip-inner {
  background-color: #9c27b0;
}
.tooltip-warning + .tooltip .tooltip-inner {
  background-color: #ff9800;
}
.tooltip-danger + .tooltip .tooltip-inner {
  background-color: #f44336;
}
.tooltip-success + .tooltip .tooltip-inner {
  background-color: #4caf50;
}
.tooltip-light + .tooltip .tooltip-inner {
  color: #424242;
}
.popover {
  line-height: 21px;
  line-height: 2.1rem;
  background-color: #fff;
  color: #424242;
  border: none !important;
  padding: 0;
}
.popover .popover-title {
  background-color: transparent;
  border: 0;
  border-radius: 0;
  font-size: 17px;
  font-weight: 400;
  padding: 15px;
  margin: 0;
  background-color: #8bc34a;
  color: #fff;
}
.popover .tooltip-inner {
  border: 0;
  box-shadow: none;
  background-color: transparent;
}
.btn-group .dropdown-menu {
  top: 0;
  margin: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
  display: block;
  -webkit-transform-origin: 40% 0;
  transform-origin: 40% 0;
  transition: all ease 0.2s;
  padding: 0;
}
.btn-group .dropdown-menu li {
  padding: 0;
}
.btn-group .dropdown-menu li.dropdown-header {
  font-size: 14px;
  font-weight: 400;
  padding: 10px 20px;
  color: #424242;
  text-transform: uppercase;
}
.btn-group .dropdown-menu li a {
  padding-top: 10px;
  padding-bottom: 10px;
}
.btn-group .dropdown-menu li a:hover,
.btn-group .dropdown-menu li a:focus {
  background-color: #f5f5f5;
  color: #8bc34a;
}
.btn-group .dropdown-menu li a i {
  margin-right: 15px;
}
.btn-group .dropdown-menu.dropdown-menu-right {
  -webkit-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.btn-group .dropdown-menu.dropdown-menu-left {
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
.btn-group .dropdown-menu.dropdown-menu-light {
  background-color: #f5f5f5;
}
.btn-group .dropdown-menu.dropdown-menu-light li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-light li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-light li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-light li a:hover,
.btn-group .dropdown-menu.dropdown-menu-light li a:focus {
  background-color: #e8e8e8;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-dark {
  background-color: #424242;
}
.btn-group .dropdown-menu.dropdown-menu-dark li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-dark li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-dark li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-dark li a:hover,
.btn-group .dropdown-menu.dropdown-menu-dark li a:focus {
  background-color: #353535;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-primary {
  background-color: #8bc34a;
}
.btn-group .dropdown-menu.dropdown-menu-primary li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-primary li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-primary li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-primary li a:hover,
.btn-group .dropdown-menu.dropdown-menu-primary li a:focus {
  background-color: #7eb73d;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-info {
  background-color: #00bcd4;
}
.btn-group .dropdown-menu.dropdown-menu-info li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-info li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-info li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-info li a:hover,
.btn-group .dropdown-menu.dropdown-menu-info li a:focus {
  background-color: #00a5bb;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-royal {
  background-color: #9c27b0;
}
.btn-group .dropdown-menu.dropdown-menu-royal li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-royal li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-royal li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-royal li a:hover,
.btn-group .dropdown-menu.dropdown-menu-royal li a:focus {
  background-color: #89229b;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-warning {
  background-color: #ff9800;
}
.btn-group .dropdown-menu.dropdown-menu-warning li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-warning li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-warning li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-warning li a:hover,
.btn-group .dropdown-menu.dropdown-menu-warning li a:focus {
  background-color: #e68900;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-danger {
  background-color: #f44336;
}
.btn-group .dropdown-menu.dropdown-menu-danger li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-danger li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-danger li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-danger li a:hover,
.btn-group .dropdown-menu.dropdown-menu-danger li a:focus {
  background-color: #f32c1e;
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-success {
  background-color: #4caf50;
}
.btn-group .dropdown-menu.dropdown-menu-success li.dropdown-header {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-success li.divider {
  background-color: rgba(255, 255, 255, 0.2);
}
.btn-group .dropdown-menu.dropdown-menu-success li a {
  color: #fff;
}
.btn-group .dropdown-menu.dropdown-menu-success li a:hover,
.btn-group .dropdown-menu.dropdown-menu-success li a:focus {
  background-color: #449d48;
  color: #fff;
}
.btn-group.open .dropdown-menu {
  -webkit-transform: scale(1);
  transform: scale(1);
  transition: all ease 0.3s;
}
.rotate-icon i {
  transition-duration: 0.5s;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  transition-timing-function: ease-in-out;
}
.rotate-icon:hover i {
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
}
.ms-icon {
  width: 40px;
  height: 40px;
  font-size: 18px;
  background-color: #8bc34a;
  color: #fff;
  border-radius: 2px;
  display: inline-block;
  margin: 0 5px 5px 0;
  text-align: center;
  line-height: 2.2;
}
.ms-icon.color-info {
  color: #fff !important;
  background-color: #00bcd4;
}
.ms-icon.color-warning {
  color: #fff !important;
  background-color: #ff9800;
}
.ms-icon.color-danger {
  color: #fff !important;
  background-color: #f44336;
}
.ms-icon.color-success {
  color: #fff !important;
  background-color: #4caf50;
}
.ms-icon.color-royal {
  color: #fff !important;
  background-color: #9c27b0;
}
.ms-icon.color-black {
  color: #fff !important;
  background-color: #000;
}
.ms-icon.ms-icon-inverse {
  background-color: transparent;
  color: #8bc34a;
  border: solid 1px #8bc34a;
}
.ms-icon.ms-icon-inverse.color-info {
  background-color: transparent;
  border-color: #00bcd4;
  color: #00bcd4 !important;
}
.ms-icon.ms-icon-inverse.color-warning {
  background-color: transparent;
  border-color: #ff9800;
  color: #ff9800 !important;
}
.ms-icon.ms-icon-inverse.color-danger {
  background-color: transparent;
  border-color: #f44336;
  color: #f44336 !important;
}
.ms-icon.ms-icon-inverse.color-success {
  background-color: transparent;
  border-color: #4caf50;
  color: #4caf50 !important;
}
.ms-icon.ms-icon-inverse.color-royal {
  background-color: transparent;
  border-color: #9c27b0;
  color: #9c27b0 !important;
}
.ms-icon.ms-icon-inverse.color-black {
  background-color: transparent;
  border-color: #000;
  color: #000 !important;
}
.ms-icon.ms-icon-square {
  border-radius: 0;
}
.ms-icon.ms-icon-round {
  border-radius: 5px;
}
.ms-icon.ms-icon-circle {
  border-radius: 50%;
}
.ms-icon.ms-icon-lg {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: 22px;
}
.ms-icon.ms-icon-xlg {
  width: 60px;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
}
.ms-icon.ms-icon-xxlg {
  width: 80px;
  height: 80px;
  line-height: 80px;
  font-size: 36px;
}
.ms-icon.ms-icon-sm {
  width: 35px;
  height: 35px;
  line-height: 35px;
  font-size: 14px;
}
.ms-icon.ms-icon-white {
  background-color: #fff;
  color: #8bc34a;
}
.ms-icon.ms-icon-white.ms-icon-inverse {
  background-color: transparent;
  color: #fff;
  border-color: #fff;
}
.ms-icon-feature {
  display: table;
  margin-bottom: 60px;
}
.ms-icon-feature .ms-icon-feature-icon {
  display: table-cell;
  vertical-align: top;
  padding-right: 20px;
}
.ms-icon-feature .ms-icon-feature-content {
  display: table-cell;
  vertical-align: top;
  font-size: 14px;
  font-size: 1.4rem;
}
.ms-icon-feature .ms-icon-feature-content h4 {
  font-weight: 400;
  margin: 0 0 10px;
}
.alert {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
}
.alert i {
  margin-right: 10px;
}
.alert i.zmdi {
  font-size: 16px;
}
.alert .close {
  color: #fff;
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  border: 0;
  box-shadow: none;
  text-shadow: none;
  font-size: 18px;
  transition: all ease 0.3s;
  margin: 5px 5px 0 0;
}
.alert .close:hover {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.alert.alert-default {
  background-color: #eee;
  color: #424242;
}
.alert.alert-default .close {
  color: #424242;
}
.alert.alert-black {
  background-color: #424242;
  color: #f5f5f5;
}
.alert.alert-light {
  background-color: #fafafa;
  color: #424242;
  border-left: solid 4px #b5b5b5;
  border-right: solid 4px #b5b5b5;
}
.alert.alert-light a {
  color: #8bc34a;
}
.alert.alert-light a:hover {
  color: #7cb342;
}
.alert.alert-light .close {
  color: #424242;
}
.alert.alert-light .close:hover {
  color: #000;
}
.alert.alert-light.alert-black {
  border-color: #000;
  color: #000;
}
.alert.alert-light.alert-success {
  border-color: #4caf50;
  color: #3d8b40;
}
.alert.alert-light.alert-primary {
  border-color: #8bc34a;
  color: #71a436;
}
.alert.alert-light.alert-warning {
  border-color: #ff9800;
  color: #cc7a00;
}
.alert.alert-light.alert-danger {
  border-color: #f44336;
  color: #ea1c0d;
}
.alert.alert-light.alert-royal {
  border-color: #9c27b0;
  color: #771e86;
}
.alert.alert-light.alert-info {
  border-color: #00bcd4;
  color: #008fa1;
}
.well {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24) !important;
  background-color: #fafafa !important;
}
.well.well-sm {
  font-size: 14px;
  font-size: 1.4rem;
}
code {
  color: #7eb73d;
}
code[class*="language-"],
pre[class*="language-"] {
  color: #cfd8dc;
  text-shadow: none;
  font-weight: 400;
  font-size: 13px;
  direction: ltr;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  -webkit-hyphens: none;
  -ms-hyphens: none;
  hyphens: none;
}
pre[class*="language-"]::-moz-selection,
pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection,
code[class*="language-"] ::-moz-selection {
  background: rgba(255, 255, 255, 0.2);
}
pre[class*="language-"]::selection,
pre[class*="language-"] ::selection,
code[class*="language-"]::selection,
code[class*="language-"] ::selection {
  background: rgba(255, 255, 255, 0.2);
}
pre[class*="language-"] {
  padding: 1em;
  margin: 0.5em 0;
  overflow: auto;
}
:not(pre) > code[class*="language-"],
pre[class*="language-"] {
  background: #263238;
}
:not(pre) > code[class*="language-"] {
  padding: 0.1em;
  border-radius: 0.3em;
  white-space: normal;
}
.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
  color: slategray;
}
.token.punctuation {
  color: #81d4fa;
}
.namespace {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
}
.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol,
.token.deleted {
  color: #4db6ac;
}
.token.string,
.token.char,
.token.builtin,
.token.attr-value,
.token.inserted {
  color: #fbc02d;
}
.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string {
  color: #fbc02d;
}
.token.selector,
.token.attr-name,
.token.atrule,
.token.important,
.token.keyword {
  color: #ce93d8;
}
.token.function {
  color: #4db6ac;
}
.token.regex,
.token.variable {
  color: #fbc02d;
}
.token.important,
.token.bold {
  font-weight: 700;
}
.token.italic {
  font-style: italic;
}
.token.entity {
  cursor: help;
}
pre.line-numbers {
  position: relative;
  padding-left: 3.8em;
  counter-reset: linenumber;
}
pre.line-numbers > code {
  position: relative;
}
.line-numbers .line-numbers-rows {
  position: absolute;
  pointer-events: none;
  top: 0;
  font-size: 100%;
  left: -3.8em;
  width: 3em;
  letter-spacing: -1px;
  border-right: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.line-numbers-rows > span {
  pointer-events: none;
  display: block;
  counter-increment: linenumber;
}
.line-numbers-rows > span:before {
  content: counter(linenumber);
  color: #546e7a;
  display: block;
  padding-right: 0.8em;
  text-align: right;
}
.nav.nav-tabs {
  position: relative;
  background-color: #8bc34a;
  border: 0;
}
.nav.nav-tabs li a {
  font-weight: 400;
  padding: 17px 20px;
  padding: 1.7rem 2rem;
  color: rgba(255, 255, 255, 0.8) !important;
  text-align: center;
}
.nav.nav-tabs li a i {
  margin-right: 10px;
  margin-right: 1rem;
  transition: -webkit-transform cubic-bezier(0, 0, 0.2, 1) 0.5s;
  transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s;
  transition: transform cubic-bezier(0, 0, 0.2, 1) 0.5s,
    -webkit-transform cubic-bezier(0, 0, 0.2, 1) 0.5s;
}
.nav.nav-tabs li.active a {
  cursor: pointer;
  position: relative;
  transition: all cubic-bezier(0, 0, 0.2, 1) 0.5s;
  color: #fff !important;
}
.nav.nav-tabs li.active a i {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.nav.nav-tabs.nav-tabs-full {
  width: 100%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-2 li {
  width: 50%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-3 li {
  width: 33.333%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-4 li {
  width: 25%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-5 li {
  width: 20%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-6 li {
  width: 16.666%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-7 li {
  width: 14.2857%;
}
.nav.nav-tabs.nav-tabs-full.nav-tabs-8 li {
  width: 12.5%;
}
.nav.nav-tabs .ms-tabs-indicator {
  transition: all ease 0.3s;
  display: block;
  content: "";
  width: 0;
  height: 3px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
}
.nav.nav-tabs.indicator-light .ms-tabs-indicator {
  background-color: #f5f5f5;
}
.nav.nav-tabs.indicator-dark .ms-tabs-indicator {
  background-color: #424242;
}
.nav.nav-tabs.indicator-primary .ms-tabs-indicator {
  background-color: #8bc34a;
}
.nav.nav-tabs.indicator-info .ms-tabs-indicator {
  background-color: #00bcd4;
}
.nav.nav-tabs.indicator-royal .ms-tabs-indicator {
  background-color: #9c27b0;
}
.nav.nav-tabs.indicator-warning .ms-tabs-indicator {
  background-color: #ff9800;
}
.nav.nav-tabs.indicator-danger .ms-tabs-indicator {
  background-color: #f44336;
}
.nav.nav-tabs.indicator-success .ms-tabs-indicator {
  background-color: #4caf50;
}
.nav.nav-tabs-light {
  background-color: #f5f5f5;
}
.nav.nav-tabs-dark {
  background-color: #424242;
}
.nav.nav-tabs-primary {
  background-color: #8bc34a;
}
.nav.nav-tabs-info {
  background-color: #00bcd4;
}
.nav.nav-tabs-royal {
  background-color: #9c27b0;
}
.nav.nav-tabs-warning {
  background-color: #ff9800;
}
.nav.nav-tabs-danger {
  background-color: #f44336;
}
.nav.nav-tabs-success {
  background-color: #4caf50;
}
.nav.nav-tabs-transparent {
  background-color: transparent;
  border-bottom: solid 3px #eee;
}
.nav.nav-tabs-transparent .ms-tabs-indicator {
  bottom: -3px;
}
.nav.nav-tabs-transparent.indicator-light li.active a {
  color: #f5f5f5 !important;
}
.nav.nav-tabs-transparent.indicator-dark li.active a {
  color: #424242 !important;
}
.nav.nav-tabs-transparent.indicator-primary li.active a {
  color: #8bc34a !important;
}
.nav.nav-tabs-transparent.indicator-info li.active a {
  color: #00bcd4 !important;
}
.nav.nav-tabs-transparent.indicator-royal li.active a {
  color: #9c27b0 !important;
}
.nav.nav-tabs-transparent.indicator-warning li.active a {
  color: #ff9800 !important;
}
.nav.nav-tabs-transparent.indicator-danger li.active a {
  color: #f44336 !important;
}
.nav.nav-tabs-transparent.indicator-success li.active a {
  color: #4caf50 !important;
}
.nav.nav-tabs-transparent li a {
  color: rgba(66, 66, 66, 0.7) !important;
}
.nav.nav-tabs-transparent li.active a {
  color: #424242 !important;
}
.nav.nav-tabs-transparent.color-white li a {
  color: #fff !important;
}
@media (max-width: 767px) {
  .nav.nav-tabs li a i {
    margin: 0 5px;
  }
}
.nav-tabs-ver-container {
  border-radius: 0 !important;
}
.nav-tabs-ver-container .nav-tabs-ver {
  background-color: #f5f5f5;
}
.nav-tabs-ver-container .nav-tabs-ver li a {
  color: #424242;
}
.nav-tabs-ver-container .nav-tabs-ver li a i {
  margin-right: 10px;
}
.nav-tabs-ver-container .nav-tabs-ver li a:hover {
  color: #8bc34a;
  background-color: #eee;
}
.nav-tabs-ver-container .nav-tabs-ver li a:focus {
  background-color: transparent;
}
.nav-tabs-ver-container .nav-tabs-ver li.active a {
  color: #8bc34a;
  background-color: #eee;
}
.nav-tabs-ver-container .nav-tabs-ver li.active a i {
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  -webkit-transform: rotateX(360deg);
  transform: rotateX(360deg);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-light {
  background-color: #f5f5f5;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-light li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-light li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-light li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-dark {
  background-color: #424242;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-dark li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-dark li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-dark li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-primary {
  background-color: #8bc34a;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-primary li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-primary li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-primary li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-info {
  background-color: #00bcd4;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-info li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-info li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-info li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-royal {
  background-color: #9c27b0;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-royal li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-royal li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-royal li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-warning {
  background-color: #ff9800;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-warning li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-warning li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-warning li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-danger {
  background-color: #f44336;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-danger li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-danger li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-danger li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-success {
  background-color: #4caf50;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-success li a {
  color: #fff;
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-success li a:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.nav-tabs-ver-container .nav-tabs-ver.nav-tabs-ver-success li.active a {
  background-color: rgba(0, 0, 0, 0.1);
}
@media (min-width: 992px) {
  .nav-tabs-ver-container > .row {
    overflow: hidden;
  }
  .nav-tabs-ver-container > .row > [class*="col-"] {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
  }
  .nav-tabs-ver-container .nav-tabs-ver {
    margin-bottom: -99999px;
    padding-bottom: 99999px;
    position: relative;
  }
  .nav-tabs-ver-container .nav-tabs-ver:after {
    content: "";
    display: block;
    width: 1px;
    position: absolute;
    z-index: 10;
    box-shadow: -1px 0 1px 0 rgba(0, 0, 0, 0.3);
    height: 100%;
    top: 0;
    right: 0;
  }
  .nav-tabs-ver-container-content {
    padding-left: 0;
    padding-right: 30px;
  }
}
.card {
  box-shadow: none;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  background-color: #fff;
  margin-bottom: 20px;
  margin-bottom: 2rem;
}
.card .plyr__controls {
  border-left: 0;
  border-right: 0;
}
.card .card-block,
.card.card-block {
  padding: 20px;
  padding: 2rem;
}
.card .card-block p:last-child,
.card.card-block p:last-child {
  margin-bottom: 0;
}
.card .card-footer {
  padding: 10px 20px 7px;
  padding: 1rem 2rem 0.7rem;
  background-color: #f5f5f5;
}
.card .card-footer p:last-child {
  margin-bottom: 0;
}
.card .card-block-big,
.card.card-block-big {
  padding: 40px;
  padding: 4rem;
}
.card .card-header {
  padding: 15px 20px;
  padding: 1.5rem 2rem;
  background-color: #f5f5f5;
}
.card .card-header i {
  margin-right: 10px;
}
.card .card-header h1,
.card .card-header h2,
.card .card-header h3,
.card .card-header h4,
.card .card-header h5,
.card .card-header h6 {
  margin: 0;
  font-weight: 400;
}
.card .card-header h1 {
  font-size: 20px;
  font-size: 2rem;
}
.card .card-header h2 {
  font-size: 18px;
  font-size: 1.8rem;
}
.card .card-header h3 {
  font-size: 16px;
  font-size: 1.6rem;
}
.card .card-header h4 {
  font-size: 13px;
  font-size: 1.3rem;
}
.card.card-light {
  border-bottom: solid 3px #f5f5f5;
  border-radius: 3px;
}
.card.card-light .card-header,
.card.card-light .card-footer {
  background-color: #f5f5f5;
  color: #f5f5f5;
}
.card.card-light-inverse {
  background-color: #f5f5f5;
  border-bottom: solid 3px #f5f5f5;
  color: #fff;
}
.card.card-light-inverse a.link {
  color: #fff;
}
.card.card-light-inverse a.link:hover,
.card.card-light-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-light-inverse .card-header,
.card.card-light-inverse .card-footer {
  background-color: #e3e3e3;
  color: #f5f5f5;
}
.card.card-dark {
  border-bottom: solid 3px #424242;
  border-radius: 3px;
}
.card.card-dark .card-header,
.card.card-dark .card-footer {
  background-color: #424242;
  color: #f5f5f5;
}
.card.card-dark-inverse {
  background-color: #424242;
  border-bottom: solid 3px #424242;
  color: #fff;
}
.card.card-dark-inverse a.link {
  color: #fff;
}
.card.card-dark-inverse a.link:hover,
.card.card-dark-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-dark-inverse .card-header,
.card.card-dark-inverse .card-footer {
  background-color: #303030;
  color: #f5f5f5;
}
.card.card-primary {
  border-bottom: solid 3px #8bc34a;
  border-radius: 3px;
}
.card.card-primary .card-header,
.card.card-primary .card-footer {
  background-color: #8bc34a;
  color: #f5f5f5;
}
.card.card-primary-inverse {
  background-color: #8bc34a;
  border-bottom: solid 3px #8bc34a;
  color: #fff;
}
.card.card-primary-inverse a.link {
  color: #fff;
}
.card.card-primary-inverse a.link:hover,
.card.card-primary-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-primary-inverse .card-header,
.card.card-primary-inverse .card-footer {
  background-color: #79af3a;
  color: #f5f5f5;
}
.card.card-info {
  border-bottom: solid 3px #00bcd4;
  border-radius: 3px;
}
.card.card-info .card-header,
.card.card-info .card-footer {
  background-color: #00bcd4;
  color: #f5f5f5;
}
.card.card-info-inverse {
  background-color: #00bcd4;
  border-bottom: solid 3px #00bcd4;
  color: #fff;
}
.card.card-info-inverse a.link {
  color: #fff;
}
.card.card-info-inverse a.link:hover,
.card.card-info-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-info-inverse .card-header,
.card.card-info-inverse .card-footer {
  background-color: #009cb0;
  color: #f5f5f5;
}
.card.card-royal {
  border-bottom: solid 3px #9c27b0;
  border-radius: 3px;
}
.card.card-royal .card-header,
.card.card-royal .card-footer {
  background-color: #9c27b0;
  color: #f5f5f5;
}
.card.card-royal-inverse {
  background-color: #9c27b0;
  border-bottom: solid 3px #9c27b0;
  color: #fff;
}
.card.card-royal-inverse a.link {
  color: #fff;
}
.card.card-royal-inverse a.link:hover,
.card.card-royal-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-royal-inverse .card-header,
.card.card-royal-inverse .card-footer {
  background-color: #822193;
  color: #f5f5f5;
}
.card.card-warning {
  border-bottom: solid 3px #ff9800;
  border-radius: 3px;
}
.card.card-warning .card-header,
.card.card-warning .card-footer {
  background-color: #ff9800;
  color: #f5f5f5;
}
.card.card-warning-inverse {
  background-color: #ff9800;
  border-bottom: solid 3px #ff9800;
  color: #fff;
}
.card.card-warning-inverse a.link {
  color: #fff;
}
.card.card-warning-inverse a.link:hover,
.card.card-warning-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-warning-inverse .card-header,
.card.card-warning-inverse .card-footer {
  background-color: #db8300;
  color: #f5f5f5;
}
.card.card-danger {
  border-bottom: solid 3px #f44336;
  border-radius: 3px;
}
.card.card-danger .card-header,
.card.card-danger .card-footer {
  background-color: #f44336;
  color: #f5f5f5;
}
.card.card-danger-inverse {
  background-color: #f44336;
  border-bottom: solid 3px #f44336;
  color: #fff;
}
.card.card-danger-inverse a.link {
  color: #fff;
}
.card.card-danger-inverse a.link:hover,
.card.card-danger-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-danger-inverse .card-header,
.card.card-danger-inverse .card-footer {
  background-color: #f22314;
  color: #f5f5f5;
}
.card.card-success {
  border-bottom: solid 3px #4caf50;
  border-radius: 3px;
}
.card.card-success .card-header,
.card.card-success .card-footer {
  background-color: #4caf50;
  color: #f5f5f5;
}
.card.card-success-inverse {
  background-color: #4caf50;
  border-bottom: solid 3px #4caf50;
  color: #fff;
}
.card.card-success-inverse a.link {
  color: #fff;
}
.card.card-success-inverse a.link:hover,
.card.card-success-inverse a.link:focus {
  border-bottom: solid 1px #fff;
}
.card.card-success-inverse .card-header,
.card.card-success-inverse .card-footer {
  background-color: #419645;
  color: #f5f5f5;
}
.card.card-light .card-header,
.card.card-light .card-footer {
  color: #424242;
}
.card.card-light-inverse {
  color: #424242;
}
.card.card-light-inverse .card-header,
.card.card-light-inverse .card-footer {
  color: #424242;
}
.card .card-block > .ms-icon {
  margin: 10px 0;
  margin: 1rem 0;
}
.card .list-group {
  border: none;
}
.card.card-flat {
  box-shadow: none !important;
}
.card-hero {
  position: relative;
  margin-top: -100px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.card-hero > .card-block {
  padding: 40px;
  padding: 4rem;
}
.ms-feature {
  transition: all ease 0.5s;
}
.ms-feature .ms-icon {
  transition: all ease 0.5s;
}
.ms-feature:hover,
.ms-feature:focus {
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
  background-color: #fafafa;
}
.ms-feature:hover .ms-icon,
.ms-feature:focus .ms-icon {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
}
.btn-card-float {
  position: absolute;
  -webkit-transform: translate(-50%, -50px);
  transform: translate(-50%, -50px);
  left: 50%;
  right: auto;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.btn-card-float.right {
  right: 10%;
  left: auto;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.btn-card-float.left {
  right: auto;
  left: 10%;
  -webkit-transform: translate(0, -50px);
  transform: translate(0, -50px);
}
.card-relative {
  position: relative;
}
@media (max-width: 767px) {
  .card .card-block-big {
    padding: 2rem;
  }
  .card-hero .card-block {
    padding: 2rem;
  }
}
.circle-light {
  stroke: #f5f5f5;
}
.circle-dark {
  stroke: #424242;
}
.circle-primary {
  stroke: #8bc34a;
}
.circle-info {
  stroke: #00bcd4;
}
.circle-royal {
  stroke: #9c27b0;
}
.circle-warning {
  stroke: #ff9800;
}
.circle-danger {
  stroke: #f44336;
}
.circle-success {
  stroke: #4caf50;
}
.circles-text {
  font-size: 30px !important;
  font-size: 3rem !important;
}
.price-table {
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  background-color: #fff;
  padding: 0;
  transition: all ease 0.5s;
  position: relative;
  z-index: 1;
}
.price-table:hover {
  background-color: #fafafa;
}
.price-table:hover .price-table-body:after {
  top: 0;
}
.price-table.prominent {
  -webkit-transform: translateY(-10px);
  transform: translateY(-10px);
  position: relative;
  z-index: 2;
  box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.14), 0 3px 3px -2px rgba(0, 0, 0, 0.2),
    0 1px 8px 0 rgba(0, 0, 0, 0.12);
}
.price-table.prominent .price-table-body {
  padding-bottom: 40px;
}
.price-table.prominent .price-table-body .btn {
  -webkit-transform: translateY(15px);
  transform: translateY(15px);
}
.price-table header {
  background-color: #8bc34a;
  display: block;
  text-align: center;
  color: #fff;
}
.price-table header .price-table-category {
  display: block;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  padding: 1rem 0;
}
.price-table header h3 {
  font-weight: 400;
  padding: 20px 0;
  margin: 0;
  font-size: 40px;
  font-size: 4rem;
}
.price-table header h3 sup {
  font-size: 20px;
  font-size: 2rem;
  margin-right: 5px;
  margin-right: 0.5rem;
}
.price-table header h3 sub {
  font-size: 16px;
  font-size: 1.6rem;
}
.price-table .price-table-body {
  padding: 20px;
  padding: 2rem;
  transition: all ease 0.3s;
  position: relative;
  overflow: hidden;
}
.price-table .price-table-body:after {
  transition: all ease 0.3s;
  display: block;
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}
.price-table .price-table-body .price-table-list {
  list-style-type: none;
  padding-left: 0;
  font-size: 15px;
  font-size: 1.5rem;
}
.price-table .price-table-body .price-table-list li {
  margin: 0;
  padding: 8px 0;
  border-bottom: dotted 1px #e0e0e0;
}
.price-table .price-table-body .price-table-list li i {
  margin-right: 15px;
}
.price-table.price-table-light {
  border-bottom: solid 3px #f5f5f5;
}
.price-table.price-table-light header {
  background-color: #f5f5f5;
}
.price-table.price-table-light .price-table-body:after {
  background-color: #f5f5f5;
}
.price-table.price-table-light .price-table-list i {
  color: #f5f5f5;
}
.price-table.price-table-light:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-light:hover .price-table-body .price-table-list li {
  border-color: white;
}
.price-table.price-table-light:hover .price-table-body .price-table-list li i {
  color: #fff;
}
.price-table.price-table-light:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #f5f5f5;
}
.price-table.price-table-dark {
  border-bottom: solid 3px #424242;
}
.price-table.price-table-dark header {
  background-color: #424242;
}
.price-table.price-table-dark .price-table-body:after {
  background-color: #424242;
}
.price-table.price-table-dark .price-table-list i {
  color: #424242;
}
.price-table.price-table-dark:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-dark:hover .price-table-body .price-table-list li {
  border-color: #707070;
}
.price-table.price-table-dark:hover .price-table-body .price-table-list li i {
  color: #fff;
}
.price-table.price-table-dark:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #424242;
}
.price-table.price-table-primary {
  border-bottom: solid 3px #8bc34a;
}
.price-table.price-table-primary header {
  background-color: #8bc34a;
}
.price-table.price-table-primary .price-table-body:after {
  background-color: #8bc34a;
}
.price-table.price-table-primary .price-table-list i {
  color: #8bc34a;
}
.price-table.price-table-primary:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-primary:hover .price-table-body .price-table-list li {
  border-color: #b7da8f;
}
.price-table.price-table-primary:hover
  .price-table-body
  .price-table-list
  li
  i {
  color: #fff;
}
.price-table.price-table-primary:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #8bc34a;
}
.price-table.price-table-info {
  border-bottom: solid 3px #00bcd4;
}
.price-table.price-table-info header {
  background-color: #00bcd4;
}
.price-table.price-table-info .price-table-body:after {
  background-color: #00bcd4;
}
.price-table.price-table-info .price-table-list i {
  color: #00bcd4;
}
.price-table.price-table-info:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-info:hover .price-table-body .price-table-list li {
  border-color: #31e8ff;
}
.price-table.price-table-info:hover .price-table-body .price-table-list li i {
  color: #fff;
}
.price-table.price-table-info:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #00bcd4;
}
.price-table.price-table-royal {
  border-bottom: solid 3px #9c27b0;
}
.price-table.price-table-royal header {
  background-color: #9c27b0;
}
.price-table.price-table-royal .price-table-body:after {
  background-color: #9c27b0;
}
.price-table.price-table-royal .price-table-list i {
  color: #9c27b0;
}
.price-table.price-table-royal:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-royal:hover .price-table-body .price-table-list li {
  border-color: #c759da;
}
.price-table.price-table-royal:hover .price-table-body .price-table-list li i {
  color: #fff;
}
.price-table.price-table-royal:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #9c27b0;
}
.price-table.price-table-warning {
  border-bottom: solid 3px #ff9800;
}
.price-table.price-table-warning header {
  background-color: #ff9800;
}
.price-table.price-table-warning .price-table-body:after {
  background-color: #ff9800;
}
.price-table.price-table-warning .price-table-list i {
  color: #ff9800;
}
.price-table.price-table-warning:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-warning:hover .price-table-body .price-table-list li {
  border-color: #ffbd5c;
}
.price-table.price-table-warning:hover
  .price-table-body
  .price-table-list
  li
  i {
  color: #fff;
}
.price-table.price-table-warning:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #ff9800;
}
.price-table.price-table-danger {
  border-bottom: solid 3px #f44336;
}
.price-table.price-table-danger header {
  background-color: #f44336;
}
.price-table.price-table-danger .price-table-body:after {
  background-color: #f44336;
}
.price-table.price-table-danger .price-table-list i {
  color: #f44336;
}
.price-table.price-table-danger:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-danger:hover .price-table-body .price-table-list li {
  border-color: #f9948d;
}
.price-table.price-table-danger:hover .price-table-body .price-table-list li i {
  color: #fff;
}
.price-table.price-table-danger:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #f44336;
}
.price-table.price-table-success {
  border-bottom: solid 3px #4caf50;
}
.price-table.price-table-success header {
  background-color: #4caf50;
}
.price-table.price-table-success .price-table-body:after {
  background-color: #4caf50;
}
.price-table.price-table-success .price-table-list i {
  color: #4caf50;
}
.price-table.price-table-success:hover .price-table-body {
  color: #fff;
}
.price-table.price-table-success:hover .price-table-body .price-table-list li {
  border-color: #8acc8d;
}
.price-table.price-table-success:hover
  .price-table-body
  .price-table-list
  li
  i {
  color: #fff;
}
.price-table.price-table-success:hover .price-table-body .btn-raised {
  background-color: #fff;
  color: #4caf50;
}
@media (max-width: 991px) {
  .price-table {
    margin-bottom: 2rem;
    max-width: 500px;
    margin: 2rem auto;
  }
  .prominent {
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
    position: static !important;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .prominent .price-table-body {
    padding-bottom: 20px !important;
  }
  .prominent .price-table-body .btn {
    -webkit-transform: translateY(0) !important;
    transform: translateY(0) !important;
  }
}
.pricing-table-container {
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.pricing-table-content {
  list-style-type: none;
  padding: 0;
  font-size: 0.9em;
  margin: 0;
}
.pricing-table-content li {
  padding: 10px;
  margin: 0;
}
.pricing-desc-title {
  font-size: 24px;
  text-align: center;
  background-color: #8bc34a;
  color: #fff;
  margin: 0;
  padding: 21px 5px 20px;
  height: 122px;
}
.pricing-table-head {
  background-color: #8bc34a;
  color: #fff;
}
.pricing-table-head h2 {
  font-size: 1.3em;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  margin: 0;
  font-weight: 400;
  height: 70px;
  padding-top: 15px;
}
.pricing-table-head h2 span {
  font-size: 12px;
  display: block;
  padding-top: 5px;
}
.pricing-table-head .price {
  margin: 0;
  font-size: 24px;
  font-weight: 400;
  height: 52px;
  line-height: 52px;
  text-align: center;
}
.pricing-table-head .price i {
  font-style: normal;
  font-size: 32px;
}
.pricing-table-content {
  text-align: center;
}
.pricing-table-description .pricing-table-content {
  text-align: left;
}
.pricing-table-content li:nth-child(2n + 1) {
  background-color: rgba(0, 0, 0, 0.03);
}
.pricing-table-description i {
  margin: 0 15px 0 5px;
  color: #8bc34a;
  font-size: 1.1em;
}
.pricing-table-content .fa-check {
  color: #4caf50;
}
.pricing-table-content .fa-times {
  color: #f44336;
}
@media (min-width: 992px) {
  .pricing-col {
    margin: 0;
    padding: 0;
    transition: all ease 0.5s;
  }
  .pricing-col:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}
@media (max-width: 991px) {
  .pricing-table-container {
    background-color: transparent;
    box-shadow: none;
  }
  .pricing-table-content span {
    display: block;
  }
  .pricing-table {
    background-color: #fff;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
      0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
    margin-bottom: 4rem;
  }
}
.ms-hero {
  margin-top: -40px;
  padding: 30px 0 0;
  padding: 3rem 0 0;
}
.ms-hero.ms-hero-black {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
}
.ms-hero.ms-hero-material {
  color: #f5f5f5;
  background-color: #223110;
  min-height: 450px;
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 0;
}
@-webkit-keyframes heroBackOne {
  0% {
    height: 0;
  }
  100% {
    height: 590%;
  }
}
@keyframes heroBackOne {
  0% {
    height: 0;
  }
  100% {
    height: 590%;
  }
}
@-webkit-keyframes heroBackTwo {
  0% {
    height: 0;
  }
  100% {
    height: 290%;
  }
}
@keyframes heroBackTwo {
  0% {
    height: 0;
  }
  100% {
    height: 290%;
  }
}
@-webkit-keyframes heroBackThree {
  0% {
    height: 0;
  }
  100% {
    height: 470%;
  }
}
@keyframes heroBackThree {
  0% {
    height: 0;
  }
  100% {
    height: 470%;
  }
}
.ms-hero.ms-hero-material:before {
  position: absolute;
  display: block;
  content: "";
  background-color: #3c571d;
  height: 590%;
  width: 75%;
  bottom: -249%;
  left: -46%;
  -webkit-transform: rotate(-48deg);
  transform: rotate(-48deg);
  z-index: 1;
  box-shadow: 29px -26px 43px -1px rgba(0, 0, 0, 0.5);
  -webkit-animation-name: heroBackOne;
  animation-name: heroBackOne;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
.ms-hero.ms-hero-material .ms-hero-bg {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}
.ms-hero.ms-hero-material .ms-hero-bg:before {
  position: absolute;
  display: block;
  content: "";
  background-color: #577d2a;
  height: 290%;
  width: 71%;
  bottom: -140%;
  left: -43%;
  -webkit-transform: rotate(-33deg);
  transform: rotate(-33deg);
  z-index: 3;
  box-shadow: 28px 10px 43px -1px rgba(0, 0, 0, 0.5);
  -webkit-animation-name: heroBackTwo;
  animation-name: heroBackTwo;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
.ms-hero.ms-hero-material .ms-hero-bg:after {
  position: absolute;
  display: block;
  content: "";
  background-color: #649130;
  height: 470%;
  width: 46%;
  bottom: -232%;
  right: 0;
  -webkit-transform: rotate(52deg);
  transform: rotate(52deg);
  z-index: 4;
  box-shadow: -33px -22px 43px -1px rgba(0, 0, 0, 0.53);
  -webkit-animation-name: heroBackThree;
  animation-name: heroBackThree;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}
.ms-hero.ms-hero-material .container {
  z-index: 5;
  position: relative;
}
.ms-hero.ms-hero-material .ms-hero-img {
  padding-left: 0;
  padding-top: 0;
  position: relative;
  margin: 40px 0 0 40px;
  margin: 4rem 0 0 4rem;
}
.ms-hero.ms-hero-material .ms-hero-img .carousel {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.ms-hero.ms-hero-material .ms-hero-img .carousel .carousel-indicators {
  position: absolute;
  bottom: 130px;
  left: auto;
  right: 40px;
}
.ms-hero.ms-hero-material .ms-hero-img .carousel .carousel-indicators li {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background-color: #424242;
}
.ms-hero.ms-hero-material
  .ms-hero-img
  .carousel
  .carousel-indicators
  li.active {
  background-color: #8bc34a;
}
.ms-hero.ms-hero-material .ms-hero-img .ms-hero-img-slider {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
.ms-hero.ms-hero-material .carousel .carousel-indicators {
  position: static;
  width: auto;
  display: inline-block;
  margin: 0;
}
.ms-hero.ms-hero-material .carousel .carousel-indicators li {
  vertical-align: top;
  display: inline-block;
  background-color: #78909c;
  border: none;
  border: 0;
  height: 3px;
  width: 14px;
  margin: 2px;
  margin-top: 10px;
  border-radius: 2px;
  cursor: pointer;
}
.ms-hero.ms-hero-material .carousel .carousel-indicators li.active {
  top: 0;
  background-color: #fff;
}
.ms-hero.ms-hero-material .carousel .carousel-controls {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 160px;
}
.ms-hero.ms-hero-material .carousel .carousel-controls a {
  display: inline-block;
  position: static;
  height: 20px;
  width: 20px;
  background: none;
  font-size: 15px;
  background-color: #fff;
  color: #616161;
  text-shadow: none;
  border-radius: 100%;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  text-align: center;
  cursor: pointer;
  transition: all ease 0.5s;
  margin-right: 10px;
  margin-right: 1rem;
}
.ms-hero.ms-hero-material .carousel .carousel-controls a:hover {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-hero.ms-hero-material .carousel .carousel-controls a i {
  vertical-align: top;
  line-height: 20px;
}
.ms-hero.ms-hero-material .carousel .carousel-controls a.right {
  margin-right: 20px;
  margin-right: 2rem;
}
.ms-hero.ms-hero-material .carousel .carousel-inner {
  overflow: visible;
}
.ms-hero.ms-hero-material .carousel .carousel-inner .item .carousel-caption {
  position: static;
  text-align: left;
}
.ms-hero.ms-hero-material .ms-hero-material-text-container {
  margin: 0 auto;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-title {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.4);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  padding: 16px;
  padding: 1.6rem;
  margin-left: -10000px;
  padding-left: 10000px;
  z-index: 6;
  margin-bottom: 40px;
  margin-bottom: 4rem;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-title
  h1 {
  margin: 0 0 18px;
  margin: 0 0 1.8rem;
  font-size: 30px;
  font-size: 3rem;
  font-weight: 200;
  text-transform: capitalize;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-title
  h1
  strong {
  font-weight: 300;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-title
  h2 {
  color: #b0d683;
  text-shadow: 2px 2px 0 black;
  margin: 0;
  font-size: 20px;
  font-size: 2rem;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-list {
  list-style-type: none;
  padding: 0;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-list
  li {
  display: table;
  margin-bottom: 40px;
  margin-bottom: 4rem;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-list
  li
  .ms-list-icon {
  display: table-cell;
  vertical-align: middle;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-list
  li
  .ms-list-icon
  .ms-icon {
  margin: 0 20px 0 0;
  margin: 0 2rem 0 0;
}
.ms-hero.ms-hero-material
  .ms-hero-material-text-container
  .ms-hero-material-list
  li
  .ms-list-text {
  display: table-cell;
  vertical-align: middle;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 30px;
  line-height: 3rem;
}
@-webkit-keyframes moveBack {
  0% {
    background-position: center center;
  }
  50% {
    background-position: center 20%;
  }
  100% {
    background-position: center center;
  }
}
@keyframes moveBack {
  0% {
    background-position: center center;
  }
  50% {
    background-position: center 20%;
  }
  100% {
    background-position: center center;
  }
}
.wrap {
  padding: 60px 0;
  padding: 6rem 0;
}
.wrap.wrap-mountain {
  background-color: #424242;
  color: #fafafa;
  background-position: center center;
  background-size: cover;
  position: relative;
  -webkit-animation: moveBack;
  animation: moveBack;
  -webkit-animation-duration: 30s;
  animation-duration: 30s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.wrap.wrap-mountain:after {
  display: block;
  content: "";
  position: absolute;
  z-index: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  background-color: rgba(65, 95, 31, 0.8);
}
.wrap.wrap-mountain .container {
  position: relative;
  z-index: 1;
}
.wrap.wrap-danger {
  background-color: #f44336;
  color: fff;
}
.wrap.wrap-danger h1,
.wrap.wrap-danger h2,
.wrap.wrap-danger h3,
.wrap.wrap-danger h4,
.wrap.wrap-danger h5,
.wrap.wrap-danger h6 {
  color: #fff;
}
@media (max-width: 767px) {
  .carousel-controls {
    position: static !important;
    margin: 0 auto;
    display: block;
    width: 100% !important;
    text-align: center;
  }
  .ms-hero-material-buttons {
    text-align: center;
  }
  .ms-hero-material-buttons a {
    margin: 10px 0 !important;
    display: block;
  }
}
@media (max-width: 1199px) {
  .ms-hero-img {
    margin: 10rem auto 0 !important;
  }
  .carousel-indicators-hero-img {
    right: 30px !important;
    bottom: 90px !important;
  }
}
@media (max-width: 991px) {
  .carousel-indicators-hero-img {
    right: 60px !important;
    bottom: 170px !important;
  }
  .ms-hero-img {
    margin: 0 auto 2rem !important;
  }
}
@media (max-width: 767px) {
  .carousel-indicators-hero-img {
    display: none !important;
  }
}
.ms-hero-page {
  margin: -40px 0 0;
  padding: 40px 0;
}
.ms-hero-page-bottom {
  margin: -40px 0 0;
  padding: 40px 0 0;
}
.ms-hero-page-override {
  margin: -40px 0 0;
  padding: 30px 0 140px;
}
.hero-img-bottom img {
  margin: 0 auto;
}
@media (min-width: 768px) {
  .hero-img-bottom {
    overflow: hidden;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .hero-img-bottom img {
    position: absolute;
    bottom: 0;
    max-width: 100%;
    max-height: 100%;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
  }
}
.hero-img-col {
  overflow: hidden;
}
.ms-hero-img-city {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-city2 {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-airplane {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-team {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-mountain {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-coffee {
  background-size: cover;
  background-position: center center;
}
.ms-hero-img-meeting {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
.ms-hero-img-keyboard {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.ms-hero-img-beach {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.ms-hero-img-road {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.ms-hero-img-wall {
  background-size: cover;
  background-position: bottom center;
  background-repeat: no-repeat;
}
.ms-hero-img-room {
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
}
.ms-bg-fixed {
  background-attachment: fixed;
}
.ms-hero-bg-light {
  position: relative;
}
.ms-hero-bg-light:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(181, 181, 181, 0.85);
  z-index: 0;
}
.ms-hero-bg-light .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-dark {
  position: relative;
}
.ms-hero-bg-dark:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(2, 2, 2, 0.85);
  z-index: 0;
}
.ms-hero-bg-dark .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-primary {
  position: relative;
}
.ms-hero-bg-primary:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(73, 106, 35, 0.85);
  z-index: 0;
}
.ms-hero-bg-primary .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-info {
  position: relative;
}
.ms-hero-bg-info:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 75, 85, 0.85);
  z-index: 0;
}
.ms-hero-bg-info .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-royal {
  position: relative;
}
.ms-hero-bg-royal:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(63, 16, 72, 0.85);
  z-index: 0;
}
.ms-hero-bg-royal .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-warning {
  position: relative;
}
.ms-hero-bg-warning:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(128, 76, 0, 0.85);
  z-index: 0;
}
.ms-hero-bg-warning .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-danger {
  position: relative;
}
.ms-hero-bg-danger:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(162, 19, 9, 0.85);
  z-index: 0;
}
.ms-hero-bg-danger .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-success {
  position: relative;
}
.ms-hero-bg-success:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(37, 86, 39, 0.85);
  z-index: 0;
}
.ms-hero-bg-success .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-dark-light {
  position: relative;
  color: #fff;
}
.ms-hero-bg-dark-light:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(68, 68, 68, 0.95);
  z-index: 0;
}
.ms-hero-bg-dark-light .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-dark-transparent {
  position: relative;
  color: #fff;
}
.ms-hero-bg-dark-transparent:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 0;
}
.ms-hero-bg-dark-transparent .container {
  position: relative;
  z-index: 1;
}
.ms-hero-bg-light-light {
  position: relative;
  color: #fff;
}
.ms-hero-bg-light-light:after {
  display: block;
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  z-index: 0;
}
.ms-hero-bg-light-light .container {
  position: relative;
  z-index: 1;
}
.card-top {
  margin-top: -100px;
}
.center-block {
  display: block;
  margin: 0 auto;
}
.ms-site-title-lg {
  font-weight: 300;
  font-size: 40px;
}
.ms-site-title-lg span {
  font-weight: 400;
}
.ms-avatar-hero {
  width: 160px;
  height: 160px;
  border-radius: 50%;
  border: solid 3px #fff;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
}
.card-header-100 {
  height: 100px;
}
.card-header-100 .item {
  color: #fff;
  text-align: center;
  font-size: 16px;
  font-size: 1.6rem;
  height: 100px;
  padding-top: 22px;
  display: block;
  font-weight: 300;
}
.card-header-100 .item span {
  font-weight: 400;
  padding-bottom: 10px;
  font-size: 20px;
  font-size: 2rem;
  display: block;
}
.back-fixed {
  overflow-y: auto;
}
.back-fixed:after {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.overflow-hidden {
  overflow: hidden;
  width: 100%;
}
.img-browser-container {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
}
.img-browser-container .img-browser {
  width: 80%;
  border-bottom: solid 1px #eee;
  box-shadow: 0 27px 24px 0 rgba(0, 0, 0, 0.2),
    0 40px 77px 0 rgba(0, 0, 0, 0.22);
}
.img-browser-container .img-browser-left,
.img-browser-container .img-browser-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.img-browser-container .img-browser-left {
  max-width: 80%;
}
.img-browser-container .img-browser-right {
  max-width: 80%;
}
.img-phone-container {
  position: relative;
  display: inline-block;
}
.img-phone-container .img-phone-left,
.img-phone-container .img-phone-right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.typed-title {
  line-height: 50px;
  line-height: 5rem;
}
.ms-hero-video {
  overflow: hidden;
}
.ms-carousel .carousel-control {
  background: none;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  text-shadow: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: auto;
  height: auto;
  position: absolute;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  transition: all ease 0.3s;
}
.ms-carousel .carousel-control.left {
  left: 30px;
}
.ms-carousel .carousel-control.right {
  right: 30px;
}
.ms-carousel .carousel-indicators {
  top: 4%;
  right: 5%;
  left: auto;
  bottom: auto;
  width: auto;
}
.ms-carousel .carousel-indicators li {
  background-color: #fff;
  height: 10px;
  width: 10px;
  opacity: 0.3;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=30)";
  transition: all ease 0.3s;
}
.ms-carousel .carousel-indicators li.active {
  border-color: #fff;
  background-color: #fff;
  margin: 1px;
}
.ms-carousel .carousel-inner .item .carousel-caption {
  background-color: rgba(0, 0, 0, 0.4);
  bottom: -100%;
  left: 0;
  right: 0;
  transition: all ease 0.5s;
}
.ms-carousel .carousel-indicators-tumbs {
  list-style-type: none;
  list-style-position: outside;
  padding: 0;
  right: 4%;
  margin: 0 0 20px;
  margin: 0 0 2rem;
  -webkit-transform: scale(0.6);
  transform: scale(0.6);
  -webkit-transform-origin: top right;
  transform-origin: top right;
  transition: all ease 0.3s;
}
.ms-carousel .carousel-indicators-tumbs li {
  width: auto;
  height: auto;
  border-radius: 0;
  background-color: transparent;
  line-height: 1;
  text-indent: 0;
  opacity: 0.4;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=40)";
  display: inline-block;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  border: solid 2px #fff;
  transition: all ease 0.3s;
  cursor: pointer;
  overflow: hidden;
}
.ms-carousel .carousel-indicators-tumbs li img {
  width: 100%;
}
.ms-carousel .carousel-indicators-tumbs li:hover {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.2);
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.ms-carousel .carousel-indicators-tumbs li.active {
  border-color: #8bc34a;
}
.ms-carousel .carousel-indicators-tumbs-outside {
  position: static;
  width: 100%;
  -webkit-transform: none;
  transform: none;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-carousel .carousel-indicators-tumbs-outside li {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-carousel:hover .carousel-control,
.ms-carousel:focus .carousel-control {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-carousel:hover .carousel-indicators li,
.ms-carousel:focus .carousel-indicators li {
  opacity: 0.5;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
}
.ms-carousel:hover .carousel-indicators li.active,
.ms-carousel:focus .carousel-indicators li.active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-carousel:hover .carousel-indicators-tumbs,
.ms-carousel:focus .carousel-indicators-tumbs {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.ms-carousel:hover .carousel-indicators-tumbs li,
.ms-carousel:focus .carousel-indicators-tumbs li {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-carousel:hover .carousel-inner .item .carousel-caption,
.ms-carousel:focus .carousel-inner .item .carousel-caption {
  bottom: 0;
}
.ms-carousel:hover .carousel-inner .item .carousel-caption:hover,
.ms-carousel:focus .carousel-inner .item .carousel-caption:hover {
  background-color: rgba(0, 0, 0, 0.6);
}
.ms-carousel .carousel-caption-blog {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  z-index: 1;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  padding: 2rem;
  transition: all ease 0.5s;
}
.ms-carousel:hover .carousel-caption-blog {
  background-color: rgba(0, 0, 0, 0.8);
}
.carousel-cards .carousel-inner {
  padding-bottom: 60px;
  padding-bottom: 6rem;
}
.carousel-cards .carousel-inner .item .carousel-caption {
  padding: 0;
  position: static;
  text-shadow: none;
  color: #424242;
  font-weight: 300;
  text-align: left;
}
.carousel-cards .carousel-indicators {
  width: auto;
  display: inline-block;
  margin: 0;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 0;
}
.carousel-cards .carousel-indicators li {
  width: 12px;
  height: 12px;
  border: solid 2px #fff;
}
.carousel-cards .carousel-indicators li.active {
  width: 14px;
  height: 14px;
}
.carousel-cards .carousel-indicators.carousel-indicators-primary li {
  border-color: #8bc34a;
}
.carousel-cards .carousel-indicators.carousel-indicators-primary li.active {
  background-color: #8bc34a;
}
.carousel-cards .carousel-control {
  background: none;
  position: absolute;
  width: 50px;
  height: 50px;
  bottom: 0;
  top: auto;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  border-radius: 100%;
  text-align: center;
  padding: 0;
  margin: 0;
}
.carousel-cards .carousel-control i {
  display: block;
  width: 50px;
  line-height: 50px;
  text-shadow: none;
  font-size: 24px;
}
.carousel-cards .carousel-control.right {
  right: 20%;
}
.carousel-cards .carousel-control.left {
  left: 20%;
}
.carousel-fade .carousel-inner .item {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition-property: opacity;
}
.carousel-fade .carousel-inner .active {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  z-index: 1;
}
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.carousel-fade .carousel-control {
  z-index: 2;
}
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.carousel-header {
  margin: 0;
  padding: 40px 0 80px;
  padding: 4rem 0 8rem;
}
.carousel-header .carousel-inner .item .carousel-caption {
  position: static;
}
.carousel-header .carousel-control {
  background: none;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  text-shadow: none;
  box-shadow: none !important;
  top: 20px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: auto;
  height: auto;
  position: absolute;
  transition: all ease 0.3s;
}
.carousel-header .carousel-control.left {
  left: auto;
  right: 80px;
}
.carousel-header .carousel-control.right {
  right: 30px;
}
.carousel-header .carousel-indicators {
  top: auto;
  bottom: 0;
}
.carousel-header .carousel-indicators li.active {
  background-color: #8bc34a;
  border-color: #8bc34a;
}
.carousel-header .carousel-caption {
  text-align: left;
  padding: 0 40px 0 0;
  padding: 0 4rem 0 0;
}
.list-hero {
  font-size: 22px;
  font-size: 2.2rem;
  font-weight: 300;
}
.list-hero li {
  margin-bottom: 30px;
  margin-bottom: 3rem;
  display: table;
  width: 100%;
  transition: all ease 1s;
}
.list-hero li span {
  display: table-cell;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 20px;
  padding: 0 2rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.8),
    inset 0 0 1px 0 rgba(255, 255, 255, 0.3);
  height: 60px;
  line-height: 40px;
  line-height: 4rem;
  transition: all ease 0.3s;
  padding-top: 10px;
  padding-top: 1rem;
}
.list-hero li i {
  display: table-cell;
  height: 60px;
  width: 60px;
  line-height: 60px;
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0 20px;
  padding: 0 2rem;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.8),
    inset 0 0 1px 0 rgba(255, 255, 255, 0.3);
  transition: all ease 0.3s;
  text-align: center;
  vertical-align: middle;
}
.list-hero li:hover span,
.list-hero li:hover i {
  background-color: rgba(255, 255, 255, 0.15);
}
@media (max-width: 767px) {
  .carousel-header .carousel-caption {
    padding-right: 0;
  }
  .carousel-header .carousel-control {
    top: 0;
  }
  .carousel-header .carousel-control.left {
    right: 50px;
  }
  .carousel-header .carousel-control.right {
    right: 10px;
  }
  .list-hero {
    font-size: 1.5rem;
    font-weight: 400;
  }
}
.ms-carousel-shop .carousel-control {
  background: none;
  text-shadow: none;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  bottom: auto;
  height: auto;
  position: absolute;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition: all ease 0.3s;
}
.ms-carousel-shop .carousel-control.left {
  left: 30px;
}
.ms-carousel-shop .carousel-control.right {
  right: 30px;
}
.ms-carousel-shop .carousel-indicators {
  display: inline-block;
  width: auto;
  top: 0;
  bottom: auto;
  left: auto;
  right: 30px;
}
.ms-carousel-shop .carousel-indicators li {
  background-color: #fff;
}
.ms-carousel-shop .carousel-indicators li.active {
  background-color: #8bc34a;
  border-color: #8bc34a;
}
.ms-carousel-shop .carousel-inner .item .carousel-caption {
  position: static;
  text-align: left;
}
@media (min-width: 768px) {
  .ms-timeline {
    list-style-type: none;
    border-left: solid 6px #e0e0e0;
    padding: 0;
    margin-left: 120px;
  }
  .ms-timeline .ms-timeline-item {
    position: relative;
    margin-bottom: 4rem;
  }
  .ms-timeline .ms-timeline-item .card {
    margin-left: 20px;
    transition: box-shadow ease 0.5s;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point {
    position: absolute;
    left: -11px;
    top: 20px;
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #8bc34a;
    border: solid 3px #e0e0e0;
    transition: all ease 0.5s;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point-img {
    width: 75px;
    height: 75px;
    left: -90px;
    top: 70px;
    border: solid 3px #e0e0e0;
    transition: all ease 0.5s;
    position: absolute;
    border-radius: 50%;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point-img img {
    width: 100%;
    border-radius: 100%;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .timeline-time {
    position: absolute;
    width: 100px;
    left: -130px;
    text-align: right;
    display: block;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .timeline-time span {
    display: block;
    font-size: 2.4rem;
  }
  .ms-timeline .ms-timeline-item:hover .ms-timeline-date .ms-timeline-point {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .ms-timeline .ms-timeline-item:hover .card {
    box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14),
      0 1px 10px 0 rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.2);
  }
}
.ms-timeline-left {
  list-style-type: none;
  margin-left: 300px;
  border-left: solid 3px #8bc34a;
  position: relative;
  padding-left: 0;
}
.ms-timeline-left .ms-timeline-left-item {
  position: relative;
  padding-left: 30px;
  margin-bottom: 50px;
  margin-bottom: 5rem;
}
.ms-timeline-left .ms-timeline-left-item:before {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  border: solid 3px #8bc34a;
  background-color: #fff;
  height: 21px;
  width: 21px;
  left: -12px;
  top: 0;
}
.ms-timeline-left .ms-timeline-left-item:after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 100%;
  background-color: #8bc34a;
  height: 9px;
  width: 9px;
  left: -6px;
  top: 6px;
  transition: all ease 0.2s;
}
.ms-timeline-left .ms-timeline-left-item:hover:after {
  background-color: #424242;
}
.ms-timeline-left .ms-timeline-left-item .ms-timeline-left-city {
  font-size: 13px;
  font-size: 1.3rem;
  color: #9e9e9e;
  position: absolute;
  right: 0;
  top: -5px;
}
.ms-timeline-left .ms-timeline-left-item .ms-timeline-left-city:before {
  font-family: Material-Design-Iconic-Font;
  content: "\f1ab";
  margin-right: 5px;
}
.ms-timeline-left .ms-timeline-left-item .ms-timeline-left-left {
  float: left;
  margin-left: -300px;
  margin-top: -30px;
}
.ms-timeline-left .ms-timeline-left-item .ms-timeline-left-left h4 {
  margin: 0;
}
.ms-timeline-left
  .ms-timeline-left-item
  .ms-timeline-left-left
  .ms-timeline-left-time {
  font-size: 12px;
  font-size: 1.2rem;
}
@media (max-width: 767px) {
  .ms-timeline-left {
    margin-left: 0;
  }
  .ms-timeline-left .ms-timeline-left-item .ms-timeline-left-left {
    float: none;
    margin-left: 0;
    margin-top: 0;
  }
  .ms-timeline {
    list-style-type: none;
    border-left: solid 6px #e0e0e0;
    padding: 0 0 0 20px;
  }
  .ms-timeline .ms-timeline-item {
    margin-bottom: 4rem;
    position: relative;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date {
    margin-bottom: 1rem;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point {
    position: absolute;
    left: -30px;
    top: 7px;
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #8bc34a;
    border: solid 3px #e0e0e0;
    transition: all ease 0.5s;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point-img {
    width: 50px;
    height: 50px;
    left: 0;
    top: 70px;
    border: solid 3px #e0e0e0;
    transition: all ease 0.5s;
    border-radius: 50%;
    margin-top: -10px;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .ms-timeline-point-img img {
    width: 100%;
    border-radius: 100%;
  }
  .ms-timeline .ms-timeline-item .ms-timeline-date .timeline-time {
    font-size: 2rem;
  }
}
@media (min-width: 768px) {
  .timeline-center {
    position: relative;
    overflow: hidden;
  }
  .timeline-center:after {
    display: block;
    content: "";
    height: 100%;
    width: 6px;
    z-index: -1;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    top: 0;
    bottom: 0;
    background-color: #e0e0e0;
    position: absolute;
  }
  .timeline-center > [class*="col-"] .left,
  .timeline-center > [class*="col-"] .right {
    position: relative;
    margin-bottom: 4rem;
  }
  .timeline-center > [class*="col-"] .left:before,
  .timeline-center > [class*="col-"] .right:before {
    content: "";
    position: absolute;
    right: -48px;
    z-index: 2;
    top: 15px;
    border-radius: 50%;
    display: inline-block;
    height: 16px;
    width: 16px;
    background-color: #8bc34a;
    border: solid 3px #e0e0e0;
    transition: all ease 0.5s;
  }
  .timeline-center > [class*="col-"] .left:hover:before,
  .timeline-center > [class*="col-"] .right:hover:before {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
  }
  .timeline-center > [class*="col-"] .left {
    margin-right: 25px;
  }
  .timeline-center > [class*="col-"] .left:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 10px;
    right: -35px;
    border-left: solid 15px #8bc34a;
    border-right: solid 20px transparent;
    border-top: solid 13px transparent;
    border-bottom: solid 13px transparent;
  }
  .timeline-center > [class*="col-"] .left.card-light:after {
    border-left-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .left.card-light:before {
    background-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .left.card-dark:after {
    border-left-color: #424242;
  }
  .timeline-center > [class*="col-"] .left.card-dark:before {
    background-color: #424242;
  }
  .timeline-center > [class*="col-"] .left.card-primary:after {
    border-left-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .left.card-primary:before {
    background-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .left.card-info:after {
    border-left-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .left.card-info:before {
    background-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .left.card-royal:after {
    border-left-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .left.card-royal:before {
    background-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .left.card-warning:after {
    border-left-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .left.card-warning:before {
    background-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .left.card-danger:after {
    border-left-color: #f44336;
  }
  .timeline-center > [class*="col-"] .left.card-danger:before {
    background-color: #f44336;
  }
  .timeline-center > [class*="col-"] .left.card-success:after {
    border-left-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .left.card-success:before {
    background-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .left.card-light-inverse:after {
    border-left-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .left.card-light-inverse:before {
    background-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .left.card-dark-inverse:after {
    border-left-color: #424242;
  }
  .timeline-center > [class*="col-"] .left.card-dark-inverse:before {
    background-color: #424242;
  }
  .timeline-center > [class*="col-"] .left.card-primary-inverse:after {
    border-left-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .left.card-primary-inverse:before {
    background-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .left.card-info-inverse:after {
    border-left-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .left.card-info-inverse:before {
    background-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .left.card-royal-inverse:after {
    border-left-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .left.card-royal-inverse:before {
    background-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .left.card-warning-inverse:after {
    border-left-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .left.card-warning-inverse:before {
    background-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .left.card-danger-inverse:after {
    border-left-color: #f44336;
  }
  .timeline-center > [class*="col-"] .left.card-danger-inverse:before {
    background-color: #f44336;
  }
  .timeline-center > [class*="col-"] .left.card-success-inverse:after {
    border-left-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .left.card-success-inverse:before {
    background-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .right {
    margin-left: 25px;
  }
  .timeline-center > [class*="col-"] .right:first-child {
    margin-top: 6rem;
  }
  .timeline-center > [class*="col-"] .right:before {
    left: -48px;
    right: auto;
  }
  .timeline-center > [class*="col-"] .right:after {
    display: block;
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    top: 10px;
    left: -35px;
    border-right: solid 15px #8bc34a;
    border-left: solid 20px transparent;
    border-top: solid 13px transparent;
    border-bottom: solid 13px transparent;
  }
  .timeline-center > [class*="col-"] .right.card-light:after {
    border-right-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .right.card-light:before {
    background-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .right.card-dark:after {
    border-right-color: #424242;
  }
  .timeline-center > [class*="col-"] .right.card-dark:before {
    background-color: #424242;
  }
  .timeline-center > [class*="col-"] .right.card-primary:after {
    border-right-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .right.card-primary:before {
    background-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .right.card-info:after {
    border-right-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .right.card-info:before {
    background-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .right.card-royal:after {
    border-right-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .right.card-royal:before {
    background-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .right.card-warning:after {
    border-right-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .right.card-warning:before {
    background-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .right.card-danger:after {
    border-right-color: #f44336;
  }
  .timeline-center > [class*="col-"] .right.card-danger:before {
    background-color: #f44336;
  }
  .timeline-center > [class*="col-"] .right.card-success:after {
    border-right-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .right.card-success:before {
    background-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .right.card-light-inverse:after {
    border-right-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .right.card-light-inverse:before {
    background-color: #f5f5f5;
  }
  .timeline-center > [class*="col-"] .right.card-dark-inverse:after {
    border-right-color: #424242;
  }
  .timeline-center > [class*="col-"] .right.card-dark-inverse:before {
    background-color: #424242;
  }
  .timeline-center > [class*="col-"] .right.card-primary-inverse:after {
    border-right-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .right.card-primary-inverse:before {
    background-color: #8bc34a;
  }
  .timeline-center > [class*="col-"] .right.card-info-inverse:after {
    border-right-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .right.card-info-inverse:before {
    background-color: #00bcd4;
  }
  .timeline-center > [class*="col-"] .right.card-royal-inverse:after {
    border-right-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .right.card-royal-inverse:before {
    background-color: #9c27b0;
  }
  .timeline-center > [class*="col-"] .right.card-warning-inverse:after {
    border-right-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .right.card-warning-inverse:before {
    background-color: #ff9800;
  }
  .timeline-center > [class*="col-"] .right.card-danger-inverse:after {
    border-right-color: #f44336;
  }
  .timeline-center > [class*="col-"] .right.card-danger-inverse:before {
    background-color: #f44336;
  }
  .timeline-center > [class*="col-"] .right.card-success-inverse:after {
    border-right-color: #4caf50;
  }
  .timeline-center > [class*="col-"] .right.card-success-inverse:before {
    background-color: #4caf50;
  }
}
.owl-carousel .owl-video-play-icon {
}
.owl-carousel .owl-stage-outer .owl-stage .owl-item.active .card {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel.owl-grab .owl-stage-outer .owl-stage .owl-item.active .card {
  -webkit-animation: none;
  animation: none;
}
.owl-dots {
  text-align: center;
  margin: 10px 0;
  margin: 1rem 0;
}
.owl-dots .owl-dot {
  display: inline-block;
  cursor: pointer;
}
.owl-dots .owl-dot span {
  border-radius: 50%;
  margin: 0 5px;
  display: block;
  background-color: transparent;
  border: solid 2px #8bc34a;
  width: 12px;
  height: 12px;
}
.owl-dots .owl-dot:hover span {
  background-color: transparent;
}
.owl-dots .owl-dot.active span {
  background-color: #8bc34a;
}
.owl-dots.owl-dots-white .owl-dot span {
  border: solid 2px #fff;
}
.owl-dots.owl-dots-white .owl-dot.active span {
  background-color: #fff;
}
@media (max-width: 767px) {
  .owl-carousel .owl-stage-outer .owl-stage .owl-item.active .card {
    -webkit-animation-name: none;
    animation-name: none;
  }
}
.ms-collapse .panel {
  border-radius: 0;
}
.ms-collapse .panel .panel-heading {
  padding: 0;
  border-radius: 0;
}
.ms-collapse .panel .panel-heading .panel-title a {
  display: block;
  color: #424242;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 10px;
  line-height: 1rem;
  padding: 14px 15px;
  padding: 1.4rem 1.5rem;
  position: relative;
}
.ms-collapse .panel .panel-heading .panel-title a i {
  font-size: 16px;
  font-size: 1.6rem;
  margin-right: 10px;
  margin-right: 1rem;
  transition: all ease 0.4s;
}
.ms-collapse .panel .panel-heading .panel-title a:hover,
.ms-collapse .panel .panel-heading .panel-title a:focus {
  text-decoration: none;
}
.ms-collapse .panel .panel-heading .panel-title a:after {
  position: absolute;
  right: 25px;
  right: 2.5rem;
  top: 17px;
  top: 1.7rem;
  font-family: FontAwesome;
  content: "\f107";
  -webkit-transform: rotate(0);
  transform: rotate(0);
  transition: all ease 0.4s;
}
.ms-collapse .panel .panel-heading .panel-title a.collapsed:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}
.ms-collapse .panel .panel-heading .panel-title.ms-rotate-icon a.collapsed i {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ms-collapse .panel-light .panel-heading {
  padding: 0;
  background-color: #f5f5f5;
}
.ms-collapse .panel-light .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-light .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-dark .panel-heading {
  padding: 0;
  background-color: #424242;
}
.ms-collapse .panel-dark .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-dark .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-primary .panel-heading {
  padding: 0;
  background-color: #8bc34a;
}
.ms-collapse .panel-primary .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-primary .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-info .panel-heading {
  padding: 0;
  background-color: #00bcd4;
}
.ms-collapse .panel-info .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-info .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-royal .panel-heading {
  padding: 0;
  background-color: #9c27b0;
}
.ms-collapse .panel-royal .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-royal .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-warning .panel-heading {
  padding: 0;
  background-color: #ff9800;
}
.ms-collapse .panel-warning .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-warning .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-danger .panel-heading {
  padding: 0;
  background-color: #f44336;
}
.ms-collapse .panel-danger .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-danger .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse .panel-success .panel-heading {
  padding: 0;
  background-color: #4caf50;
}
.ms-collapse .panel-success .panel-heading .panel-title a {
  display: block;
  color: #fff !important;
}
.ms-collapse .panel-success .panel-collapse .panel-body {
  border: 0;
}
.ms-collapse.no-margin .panel {
  border-radius: 2px;
  margin: 0 !important;
}
.card .ms-collapse {
  margin: 0;
}
.card .ms-collapse .panel {
  box-shadow: none;
}
.thumbnail-card {
  padding: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 2px 0 rgba(0, 0, 0, 0.24);
  border: 0;
  border-radius: 2px;
}
.thumbnail-card .thumbnail-img-container {
  overflow: hidden;
}
.thumbnail-card .thumbnail-img-container img {
  transition: all ease 0.5s;
}
.thumbnail-card .thumbnail-img-container:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.thumbnail-card .caption {
  padding: 20px;
  padding: 2rem;
}
.thumbnail-card .caption p {
  line-height: 24px;
  line-height: 2.4rem;
}
.thumbnail-card .caption p:last-child {
  margin-bottom: 0;
}
.thumbnail-card.thumbnail-card-light .caption {
  background-color: #f5f5f5;
  color: #fff;
}
.thumbnail-card.thumbnail-card-dark .caption {
  background-color: #424242;
  color: #fff;
}
.thumbnail-card.thumbnail-card-primary .caption {
  background-color: #8bc34a;
  color: #fff;
}
.thumbnail-card.thumbnail-card-info .caption {
  background-color: #00bcd4;
  color: #fff;
}
.thumbnail-card.thumbnail-card-royal .caption {
  background-color: #9c27b0;
  color: #fff;
}
.thumbnail-card.thumbnail-card-warning .caption {
  background-color: #ff9800;
  color: #fff;
}
.thumbnail-card.thumbnail-card-danger .caption {
  background-color: #f44336;
  color: #fff;
}
.thumbnail-card.thumbnail-card-success .caption {
  background-color: #4caf50;
  color: #fff;
}
.thumbnail {
  border-radius: 0;
}
a.thumbnail {
  border-radius: 0;
  color: #8bc34a;
}
a.thumbnail .thumbnail-container {
  overflow: hidden;
}
a.thumbnail .thumbnail-container img {
  transition: all ease 0.7s;
}
a.thumbnail .thumbnail-container img:hover {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
a.thumbnail:hover,
a.thumbnail:focus {
  border-color: #8bc34a;
}
.ms-thumbnail-container {
  border: solid 1px #eee;
  padding: 5px;
  background-color: #fff;
}
.ms-thumbnail {
  position: relative;
  overflow: hidden;
}
.ms-thumbnail img {
  position: relative;
  z-index: 0;
  transition: all ease 1s;
  width: 100%;
}
.ms-thumbnail .ms-thumbnail-caption {
  position: absolute;
  z-index: 2;
  left: 0;
  right: 0;
  top: -100%;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(85, 123, 45, 0.6);
  padding: 0;
  color: #fff;
  transition: all ease 0.5s;
  -webkit-backface-visibility: hidden;
}
.ms-thumbnail .ms-thumbnail-caption:before {
  content: "";
  display: block;
  background-color: rgba(85, 123, 45, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 200%;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  transition: all ease 0.5s;
  -webkit-backface-visibility: hidden;
}
.ms-thumbnail .ms-thumbnail-caption .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-100%);
  transform: translateY(-100%);
  transition: all ease 0.6s;
  -webkit-backface-visibility: hidden;
  padding: 20px;
  padding: 2rem;
  position: absolute;
  top: 50%;
  width: 100%;
}
.ms-thumbnail
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content
  .ms-thumbnail-caption-title {
  margin: 0 0 10px;
  margin: 0 0 1rem;
}
.ms-thumbnail:hover img,
.ms-thumbnail:focus img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}
.ms-thumbnail:hover .ms-thumbnail-caption,
.ms-thumbnail:focus .ms-thumbnail-caption {
  top: 0;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-thumbnail:hover .ms-thumbnail-caption:before,
.ms-thumbnail:focus .ms-thumbnail-caption:before {
  top: 0;
}
.ms-thumbnail:hover .ms-thumbnail-caption .ms-thumbnail-caption-content,
.ms-thumbnail:focus .ms-thumbnail-caption .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-horizontal .ms-thumbnail-caption {
  top: 0;
  right: 100%;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-horizontal .ms-thumbnail-caption:before {
  top: 0;
  right: -200%;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-horizontal
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%) translateX(-100%);
  transform: translateY(-50%) translateX(-100%);
}
.ms-thumbnail.ms-thumbnail-horizontal:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-horizontal:focus .ms-thumbnail-caption {
  top: 0;
  right: 0;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-horizontal:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-horizontal:focus .ms-thumbnail-caption:before {
  top: 0;
  right: 0;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-horizontal:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-horizontal:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-bottom .ms-thumbnail-caption {
  top: 100%;
}
.ms-thumbnail.ms-thumbnail-bottom .ms-thumbnail-caption:before {
  top: 100%;
}
.ms-thumbnail.ms-thumbnail-bottom
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(50%);
  transform: translateY(50%);
}
.ms-thumbnail.ms-thumbnail-bottom:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-bottom:focus .ms-thumbnail-caption {
  top: 0;
}
.ms-thumbnail.ms-thumbnail-bottom:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-bottom:focus .ms-thumbnail-caption:before {
  transition-delay: 0.25s;
  top: 0;
}
.ms-thumbnail.ms-thumbnail-bottom:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-bottom:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-top .ms-thumbnail-caption {
  bottom: 100%;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-top .ms-thumbnail-caption:before {
  bottom: 100%;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-top
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-top:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-top:focus .ms-thumbnail-caption {
  bottom: 0;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-top:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-top:focus .ms-thumbnail-caption:before {
  transition-delay: 0.25s;
  bottom: 0;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-top:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-top:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-right .ms-thumbnail-caption {
  left: 100%;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-right .ms-thumbnail-caption:before {
  left: 100%;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-right
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-right:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-right:focus .ms-thumbnail-caption {
  left: 0;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-right:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-right:focus .ms-thumbnail-caption:before {
  transition-delay: 0.25s;
  left: 0;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-right:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-right:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-left .ms-thumbnail-caption {
  right: 100%;
  left: auto;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-left .ms-thumbnail-caption:before {
  right: 100%;
  left: auto;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-left
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-left:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-left:focus .ms-thumbnail-caption {
  right: 0;
  left: auto;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-left:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-left:focus .ms-thumbnail-caption:before {
  transition-delay: 0.25s;
  right: 0;
  left: auto;
  top: auto;
}
.ms-thumbnail.ms-thumbnail-left:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-left:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-diagonal .ms-thumbnail-caption {
  right: 100%;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-diagonal .ms-thumbnail-caption:before {
  right: -200%;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-diagonal
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-diagonal:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-diagonal:focus .ms-thumbnail-caption {
  right: 0;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-diagonal:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-diagonal:focus .ms-thumbnail-caption:before {
  right: 0;
  left: auto;
}
.ms-thumbnail.ms-thumbnail-diagonal:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-diagonal:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-center .ms-thumbnail-caption {
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  width: 0;
  height: 0;
  overflow: hidden;
  transition-delay: 0.3s;
}
.ms-thumbnail.ms-thumbnail-center .ms-thumbnail-caption:before {
  left: 50%;
  right: auto;
  top: 50%;
  bottom: auto;
  width: 0;
  height: 0;
  overflow: hidden;
  transition-delay: 0.2s;
}
.ms-thumbnail.ms-thumbnail-center
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}
.ms-thumbnail.ms-thumbnail-center:hover .ms-thumbnail-caption,
.ms-thumbnail.ms-thumbnail-center:focus .ms-thumbnail-caption {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition-delay: 0s;
}
.ms-thumbnail.ms-thumbnail-center:hover .ms-thumbnail-caption:before,
.ms-thumbnail.ms-thumbnail-center:focus .ms-thumbnail-caption:before {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.ms-thumbnail.ms-thumbnail-center:hover
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content,
.ms-thumbnail.ms-thumbnail-center:focus
  .ms-thumbnail-caption
  .ms-thumbnail-caption-content {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  transition-delay: 0.5s;
}
.ms-thumbnail.ms-thumbnail-danger .ms-thumbnail-caption {
  background-color: rgba(182, 27, 23, 0.6);
}
.ms-thumbnail.ms-thumbnail-danger .ms-thumbnail-caption:before {
  background-color: rgba(182, 27, 23, 0.6);
}
.ms-thumbnail.ms-thumbnail-danger .ms-thumbnail-caption {
  background-color: rgba(182, 27, 23, 0.6);
}
.ms-thumbnail.ms-thumbnail-danger .ms-thumbnail-caption:before {
  background-color: rgba(182, 27, 23, 0.6);
}
.ms-thumbnail.ms-thumbnail-success .ms-thumbnail-caption {
  background-color: rgba(44, 106, 47, 0.6);
}
.ms-thumbnail.ms-thumbnail-success .ms-thumbnail-caption:before {
  background-color: rgba(44, 106, 47, 0.6);
}
.ms-thumbnail.ms-thumbnail-warning .ms-thumbnail-caption {
  background-color: rgba(175, 97, 0, 0.6);
}
.ms-thumbnail.ms-thumbnail-warning .ms-thumbnail-caption:before {
  background-color: rgba(175, 97, 0, 0.6);
}
.ms-thumbnail.ms-thumbnail-royal .ms-thumbnail-caption {
  background-color: rgba(89, 23, 107, 0.6);
}
.ms-thumbnail.ms-thumbnail-royal .ms-thumbnail-caption:before {
  background-color: rgba(89, 23, 107, 0.6);
}
.ms-thumbnail.ms-thumbnail-info .ms-thumbnail-caption {
  background-color: rgba(0, 104, 117, 0.6);
}
.ms-thumbnail.ms-thumbnail-info .ms-thumbnail-caption:before {
  background-color: rgba(0, 104, 117, 0.6);
}
.ms-thumbnail.ms-thumbnail-dark .ms-thumbnail-caption {
  background-color: rgba(28, 28, 28, 0.6);
}
.ms-thumbnail.ms-thumbnail-dark .ms-thumbnail-caption:before {
  background-color: rgba(28, 28, 28, 0.6);
}
.ms-thumbnail.ms-thumbnail-light .ms-thumbnail-caption {
  color: #424242;
  background-color: rgba(255, 255, 255, 0.6);
}
.ms-thumbnail.ms-thumbnail-light .ms-thumbnail-caption:before {
  background-color: rgba(255, 255, 255, 0.6);
}
.ms-hero-rev {
  margin-top: -40px !important;
}
.ms-hero-rev .tp-caption {
  font-family: Roboto, sans-serif;
}
.ms-hero-rev .WebProduct-Title strong {
  font-weight: 300;
}
body:after {
  display: none;
}
body.lb-disable-scrolling {
  overflow: hidden;
}
.lightboxOverlay {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  background-color: black;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  display: none;
}
.lightbox {
  position: absolute;
  left: 0;
  width: 100%;
  z-index: 10000;
  text-align: center;
  line-height: 0;
  font-weight: 400;
}
.lightbox .lb-image {
  display: block;
  height: auto;
  max-width: inherit;
  max-height: none;
  border-radius: 3px;
  border: 4px solid white;
}
.lightbox a img {
  border: none;
}
.lb-outerContainer {
  position: relative;
  *zoom: 1;
  width: 250px;
  height: 250px;
  margin: 0 auto;
  border-radius: 4px;
  background-color: white;
}
.lb-outerContainer:after {
  content: "";
  display: table;
  clear: both;
}
.lb-loader {
  position: absolute;
  top: 43%;
  left: 0;
  height: 25%;
  width: 100%;
  text-align: center;
  line-height: 0;
}
.lb-cancel {
  display: block;
  width: 32px;
  height: 32px;
  margin: 0 auto;
}
.lb-nav {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;
}
.lb-container > .nav {
  left: 0;
}
.lb-nav a {
  outline: none;
  background-image: url(data:image/gif;base64,R0lGODlhAQABAPAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==);
}
.lb-prev,
.lb-next {
  height: 100%;
  cursor: pointer;
  display: block;
}
.lb-nav a.lb-prev {
  width: 34%;
  left: 0;
  float: left;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: opacity 0.6s;
}
.lb-nav a.lb-prev:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.lb-nav a.lb-next {
  width: 64%;
  right: 0;
  float: right;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  transition: opacity 0.6s;
}
.lb-nav a.lb-next:hover {
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.lb-dataContainer {
  margin: 0 auto;
  padding-top: 5px;
  *zoom: 1;
  width: 100%;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
.lb-dataContainer:after {
  content: "";
  display: table;
  clear: both;
}
.lb-data {
  padding: 0 4px;
  color: #ccc;
}
.lb-data .lb-details {
  width: 85%;
  float: left;
  text-align: left;
  line-height: 1.1em;
}
.lb-data .lb-caption {
  font-size: 13px;
  font-weight: 700;
  line-height: 1em;
}
.lb-data .lb-caption a {
  color: #4ae;
}
.lb-data .lb-number {
  display: block;
  clear: left;
  padding-bottom: 1em;
  font-size: 12px;
  color: #999;
}
.lb-data .lb-close {
  display: block;
  float: right;
  width: 30px;
  height: 30px;
  text-align: right;
  outline: none;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=70);
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  transition: opacity 0.2s;
}
.lb-data .lb-close:hover {
  cursor: pointer;
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
}
.ms-configurator-btn {
  position: fixed;
  z-index: 3000;
  right: 20px;
  top: 200px;
  -webkit-animation-delay: 5s;
  animation-delay: 5s;
}
.ms-configurator {
  -webkit-animation-name: material;
  animation-name: material;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  color: #424242;
  background-color: #8bc34a;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 3px 5px -1px rgba(0, 0, 0, 0.2);
  width: 300px;
  position: fixed;
  top: 0;
  bottom: 0;
  right: -310px;
  z-index: 3000;
  overflow: hidden;
}
.ms-configurator h5,
.ms-configurator h6 {
  line-height: 1;
  font-size: 17px;
  font-size: 1.7rem;
  font-weight: 400;
  margin: 10px 0 20px;
}
.ms-configurator h5 {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  font-size: 1.6rem;
}
.ms-configurator h6 {
  font-size: 15px;
  font-size: 1.5rem;
  color: #eee;
}
.ms-configurator .ms-configurator-title {
  line-height: 1;
  margin: 0;
  padding: 0;
  color: #fff;
  background-color: #8bc34a;
  height: 50px;
}
.ms-configurator .ms-configurator-title a {
  color: #fff;
  display: inline-block;
  float: right;
  height: 50px;
  width: 50px;
  text-align: center;
  line-height: 50px;
}
.ms-configurator .ms-configurator-title h3 {
  display: inline-block;
  margin: 0;
  font-size: 18px;
  font-size: 1.8rem;
  margin-top: 17px;
  margin-left: 15px;
}
.ms-configurator .ms-configurator-title h3 i {
  margin-right: 10px;
  margin-right: 1rem;
}
.ms-configurator .panel-group .panel {
  border-radius: 0;
  margin: 0;
  box-shadow: none;
}
.ms-configurator .panel-group .panel .panel-heading {
  margin: 0;
  padding: 0;
}
.ms-configurator .panel-group .panel .panel-heading .panel-title {
  margin: 0;
  padding: 0;
}
.ms-configurator .panel-group .panel .panel-heading .panel-title a {
  display: block;
  background-color: #8bc34a;
  color: #fff;
  text-transform: uppercase;
  height: 50px;
  padding: 17px 0 0 15px;
}
.ms-configurator .panel-group .panel .panel-heading .panel-title a:after {
  font-family: Material-Design-Iconic-Font;
  content: "\f273";
  float: right;
  margin-right: 21px;
}
.ms-configurator
  .panel-group
  .panel
  .panel-heading
  .panel-title
  a.collapsed:after {
  content: "\f278";
}
.ms-configurator
  .panel-group
  .panel
  .panel-heading
  .panel-title
  a.collapsed:hover {
  color: #fff;
}
.ms-configurator .panel-group .panel .panel-collapse .panel-body {
  margin: 0;
  border: 0;
  box-shadow: inset 0 1px 3px 1px rgba(0, 0, 0, 0.6) !important;
  background-color: #212121;
  color: #f5f5f5;
  padding: 15px;
  padding: 1.5rem;
}
.ms-color-box {
  text-indent: -120000px;
  display: inline-block;
  height: 30px;
  width: 30px;
  background-color: #fff;
  margin: 8px 10px;
  border: solid 2px;
  border-radius: 2000px;
  transition: border-color ease 0.5s;
}
.ms-color-box.red {
  background-color: #f44336;
  border-color: #f44336;
}
.ms-color-box.red.c300 {
  background-color: #e57373;
  border-color: #e57373;
}
.ms-color-box.red.c400 {
  background-color: #ef5350;
  border-color: #ef5350;
}
.ms-color-box.red.c600 {
  background-color: #e53935;
  border-color: #e53935;
}
.ms-color-box.red.c700 {
  background-color: #d32f2f;
  border-color: #d32f2f;
}
.ms-color-box.red.c800 {
  background-color: #c62828;
  border-color: #c62828;
}
.ms-color-box.pink {
  background-color: #e91e63;
  border-color: #e91e63;
}
.ms-color-box.pink.c300 {
  background-color: #f06292;
  border-color: #f06292;
}
.ms-color-box.pink.c400 {
  background-color: #ec407a;
  border-color: #ec407a;
}
.ms-color-box.pink.c600 {
  background-color: #d81b60;
  border-color: #d81b60;
}
.ms-color-box.pink.c700 {
  background-color: #c2185b;
  border-color: #c2185b;
}
.ms-color-box.pink.c800 {
  background-color: #ad1457;
  border-color: #ad1457;
}
.ms-color-box.purple {
  background-color: #9c27b0;
  border-color: #9c27b0;
}
.ms-color-box.purple.c300 {
  background-color: #ba68c8;
  border-color: #ba68c8;
}
.ms-color-box.purple.c400 {
  background-color: #ab47bc;
  border-color: #ab47bc;
}
.ms-color-box.purple.c600 {
  background-color: #8e24aa;
  border-color: #8e24aa;
}
.ms-color-box.purple.c700 {
  background-color: #7b1fa2;
  border-color: #7b1fa2;
}
.ms-color-box.purple.c800 {
  background-color: #6a1b9a;
  border-color: #6a1b9a;
}
.ms-color-box.deep-purple {
  background-color: #673ab7;
  border-color: #673ab7;
}
.ms-color-box.deep-purple.c300 {
  background-color: #9575cd;
  border-color: #9575cd;
}
.ms-color-box.deep-purple.c400 {
  background-color: #7e57c2;
  border-color: #7e57c2;
}
.ms-color-box.deep-purple.c600 {
  background-color: #5e35b1;
  border-color: #5e35b1;
}
.ms-color-box.deep-purple.c700 {
  background-color: #512da8;
  border-color: #512da8;
}
.ms-color-box.deep-purple.c800 {
  background-color: #4527a0;
  border-color: #4527a0;
}
.ms-color-box.indigo {
  background-color: #3f51b5;
  border-color: #3f51b5;
}
.ms-color-box.indigo.c300 {
  background-color: #7986cb;
  border-color: #7986cb;
}
.ms-color-box.indigo.c400 {
  background-color: #5c6bc0;
  border-color: #5c6bc0;
}
.ms-color-box.indigo.c600 {
  background-color: #3949ab;
  border-color: #3949ab;
}
.ms-color-box.indigo.c700 {
  background-color: #303f9f;
  border-color: #303f9f;
}
.ms-color-box.indigo.c800 {
  background-color: #283593;
  border-color: #283593;
}
.ms-color-box.blue {
  background-color: #2196f3;
  border-color: #2196f3;
}
.ms-color-box.blue.c300 {
  background-color: #64b5f6;
  border-color: #64b5f6;
}
.ms-color-box.blue.c400 {
  background-color: #42a5f5;
  border-color: #42a5f5;
}
.ms-color-box.blue.c600 {
  background-color: #1e88e5;
  border-color: #1e88e5;
}
.ms-color-box.blue.c700 {
  background-color: #1976d2;
  border-color: #1976d2;
}
.ms-color-box.blue.c800 {
  background-color: #1565c0;
  border-color: #1565c0;
}
.ms-color-box.light-blue {
  background-color: #03a9f4;
  border-color: #03a9f4;
}
.ms-color-box.light-blue.c300 {
  background-color: #4fc3f7;
  border-color: #4fc3f7;
}
.ms-color-box.light-blue.c400 {
  background-color: #29b6f6;
  border-color: #29b6f6;
}
.ms-color-box.light-blue.c600 {
  background-color: #039be5;
  border-color: #039be5;
}
.ms-color-box.light-blue.c700 {
  background-color: #0288d1;
  border-color: #0288d1;
}
.ms-color-box.light-blue.c800 {
  background-color: #0277bd;
  border-color: #0277bd;
}
.ms-color-box.cyan {
  background-color: #00bcd4;
  border-color: #00bcd4;
}
.ms-color-box.cyan.c300 {
  background-color: #4dd0e1;
  border-color: #4dd0e1;
}
.ms-color-box.cyan.c400 {
  background-color: #26c6da;
  border-color: #26c6da;
}
.ms-color-box.cyan.c600 {
  background-color: #00acc1;
  border-color: #00acc1;
}
.ms-color-box.cyan.c700 {
  background-color: #0097a7;
  border-color: #0097a7;
}
.ms-color-box.cyan.c800 {
  background-color: #00838f;
  border-color: #00838f;
}
.ms-color-box.teal {
  background-color: #009688;
  border-color: #009688;
}
.ms-color-box.teal.c300 {
  background-color: #4db6ac;
  border-color: #4db6ac;
}
.ms-color-box.teal.c400 {
  background-color: #26a69a;
  border-color: #26a69a;
}
.ms-color-box.teal.c600 {
  background-color: #00897b;
  border-color: #00897b;
}
.ms-color-box.teal.c700 {
  background-color: #00796b;
  border-color: #00796b;
}
.ms-color-box.teal.c800 {
  background-color: #00695c;
  border-color: #00695c;
}
.ms-color-box.green {
  background-color: #4caf50;
  border-color: #4caf50;
}
.ms-color-box.green.c300 {
  background-color: #81c784;
  border-color: #81c784;
}
.ms-color-box.green.c400 {
  background-color: #66bb6a;
  border-color: #66bb6a;
}
.ms-color-box.green.c600 {
  background-color: #43a047;
  border-color: #43a047;
}
.ms-color-box.green.c700 {
  background-color: #388e3c;
  border-color: #388e3c;
}
.ms-color-box.green.c800 {
  background-color: #2e7d32;
  border-color: #2e7d32;
}
.ms-color-box.light-green {
  background-color: #8bc34a;
  border-color: #8bc34a;
}
.ms-color-box.light-green.c300 {
  background-color: #aed581;
  border-color: #aed581;
}
.ms-color-box.light-green.c400 {
  background-color: #9ccc65;
  border-color: #9ccc65;
}
.ms-color-box.light-green.c600 {
  background-color: #7cb342;
  border-color: #7cb342;
}
.ms-color-box.light-green.c700 {
  background-color: #689f38;
  border-color: #689f38;
}
.ms-color-box.light-green.c800 {
  background-color: #558b2f;
  border-color: #558b2f;
}
.ms-color-box.lime {
  background-color: #cddc39;
  border-color: #cddc39;
}
.ms-color-box.lime.c300 {
  background-color: #dce775;
  border-color: #dce775;
}
.ms-color-box.lime.c400 {
  background-color: #d4e157;
  border-color: #d4e157;
}
.ms-color-box.lime.c600 {
  background-color: #c0ca33;
  border-color: #c0ca33;
}
.ms-color-box.lime.c700 {
  background-color: #afb42b;
  border-color: #afb42b;
}
.ms-color-box.lime.c800 {
  background-color: #9e9d24;
  border-color: #9e9d24;
}
.ms-color-box.yellow {
  background-color: #ffeb3b;
  border-color: #ffeb3b;
}
.ms-color-box.yellow.c300 {
  background-color: #fff176;
  border-color: #fff176;
}
.ms-color-box.yellow.c400 {
  background-color: #ffee58;
  border-color: #ffee58;
}
.ms-color-box.yellow.c600 {
  background-color: #fdd835;
  border-color: #fdd835;
}
.ms-color-box.yellow.c700 {
  background-color: #fbc02d;
  border-color: #fbc02d;
}
.ms-color-box.yellow.c800 {
  background-color: #f9a825;
  border-color: #f9a825;
}
.ms-color-box.amber {
  background-color: #ffc107;
  border-color: #ffc107;
}
.ms-color-box.amber.c300 {
  background-color: #ffd54f;
  border-color: #ffd54f;
}
.ms-color-box.amber.c400 {
  background-color: #ffca28;
  border-color: #ffca28;
}
.ms-color-box.amber.c600 {
  background-color: #ffb300;
  border-color: #ffb300;
}
.ms-color-box.amber.c700 {
  background-color: #ffa000;
  border-color: #ffa000;
}
.ms-color-box.amber.c800 {
  background-color: #ff8f00;
  border-color: #ff8f00;
}
.ms-color-box.orange {
  background-color: #ff9800;
  border-color: #ff9800;
}
.ms-color-box.orange.c300 {
  background-color: #ffb74d;
  border-color: #ffb74d;
}
.ms-color-box.orange.c400 {
  background-color: #ffa726;
  border-color: #ffa726;
}
.ms-color-box.orange.c600 {
  background-color: #fb8c00;
  border-color: #fb8c00;
}
.ms-color-box.orange.c700 {
  background-color: #f57c00;
  border-color: #f57c00;
}
.ms-color-box.orange.c800 {
  background-color: #ef6c00;
  border-color: #ef6c00;
}
.ms-color-box.deep-orange {
  background-color: #ff5722;
  border-color: #ff5722;
}
.ms-color-box.deep-orange.c300 {
  background-color: #ff8a65;
  border-color: #ff8a65;
}
.ms-color-box.deep-orange.c400 {
  background-color: #ff7043;
  border-color: #ff7043;
}
.ms-color-box.deep-orange.c600 {
  background-color: #f4511e;
  border-color: #f4511e;
}
.ms-color-box.deep-orange.c700 {
  background-color: #e64a19;
  border-color: #e64a19;
}
.ms-color-box.deep-orange.c800 {
  background-color: #d84315;
  border-color: #d84315;
}
.ms-color-box.brown {
  background-color: #795548;
  border-color: #795548;
}
.ms-color-box.brown.c300 {
  background-color: #a1887f;
  border-color: #a1887f;
}
.ms-color-box.brown.c400 {
  background-color: #8d6e63;
  border-color: #8d6e63;
}
.ms-color-box.brown.c600 {
  background-color: #6d4c41;
  border-color: #6d4c41;
}
.ms-color-box.brown.c700 {
  background-color: #5d4037;
  border-color: #5d4037;
}
.ms-color-box.brown.c800 {
  background-color: #4e342e;
  border-color: #4e342e;
}
.ms-color-box.grey {
  background-color: #9e9e9e;
  border-color: #9e9e9e;
}
.ms-color-box.grey.c300 {
  background-color: #e0e0e0;
  border-color: #e0e0e0;
}
.ms-color-box.grey.c400 {
  background-color: #bdbdbd;
  border-color: #bdbdbd;
}
.ms-color-box.grey.c600 {
  background-color: #757575;
  border-color: #757575;
}
.ms-color-box.grey.c700 {
  background-color: #616161;
  border-color: #616161;
}
.ms-color-box.grey.c800 {
  background-color: #424242;
  border-color: #424242;
}
.ms-color-box.blue-grey {
  background-color: #607d8b;
  border-color: #607d8b;
}
.ms-color-box.blue-grey.c300 {
  background-color: #90a4ae;
  border-color: #90a4ae;
}
.ms-color-box.blue-grey.c400 {
  background-color: #78909c;
  border-color: #78909c;
}
.ms-color-box.blue-grey.c600 {
  background-color: #546e7a;
  border-color: #546e7a;
}
.ms-color-box.blue-grey.c700 {
  background-color: #455a64;
  border-color: #455a64;
}
.ms-color-box.blue-grey.c800 {
  background-color: #37474f;
  border-color: #37474f;
}
.ms-color-box.active {
  border-color: #fff !important;
}
.ms-color-box.grad {
  margin: 8px 6px;
  height: 28px;
  width: 28px;
}
.ms-color-shine span {
  width: 28px;
  display: inline-block;
  text-align: center;
  margin: 8px 10px 0 3px;
  font-size: 10px;
}
.ms-conf-radio .form-group {
  margin-top: 0;
}
.ms-conf-radio .form-group .radio label:hover,
.ms-conf-radio .form-group .radio label:focus {
  color: #bdbdbd !important;
}
.ms-footer {
  padding: 12px 0;
  padding: 1.2rem 0;
  text-align: center;
  background-color: #3b4c55;
  color: #bdbdbd;
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    inset 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    inset 0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ms-footer p:last-child {
  margin-bottom: 0;
}
.ms-footbar {
  background-color: #607d8b;
  color: #eee;
  position: relative;
  margin-top: 40px;
  margin-top: 4rem;
}
.ms-footbar:after {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  top: -10px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  height: 10px;
  z-index: 1;
}
.ms-footbar:before {
  content: "";
  display: block;
  position: absolute;
  width: 100%;
  top: -15px;
  height: 15px;
  z-index: 2;
  background-color: #f5f5f5;
}
.ms-footbar a {
  color: #fff;
  transition: all ease 0.3s;
}
.ms-footbar a:hover,
.ms-footbar a:focus {
  color: #ffa726;
}
.ms-footbar .ms-footbar-block {
  margin: 20px 0;
  margin: 2rem 0;
}
.ms-footbar .ms-footbar-block .ms-footbar-title {
  margin: 0 0 15px;
  margin: 0 0 1.5rem;
}
.three_cols {
  width: 100%;
  overflow: hidden;
}
.three_cols li {
  float: left;
  width: 33.333%;
}
.three_cols li a {
  display: block;
  padding: 2px 0;
}
.three_cols li a i {
  margin-right: 10px;
  width: 15px;
  text-align: center;
  color: #26c6da;
}
.three_cols li a:hover i {
  transition: all ease 0.5s;
  color: #ffa726;
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}
.ms-footer-alt-color {
  background-color: #455a64;
}
.ms-input-subscribe {
  width: 100%;
}
.ms-input-subscribe input {
  color: #fff;
  background-image: linear-gradient(#fff, #fff),
    linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 0.7)) !important;
}
.ms-input-subscribe label {
  color: rgba(255, 255, 255, 0.7) !important;
}
.ms-input-subscribe label i {
  margin: 0 5px;
}
.is-focused .ms-input-subscribe label {
  color: white !important;
}
.ms-subscribre-btn {
  background-color: rgba(0, 0, 0, 0.2);
  border: none;
  display: block;
  width: 100%;
  border-radius: 50px;
  padding: 10px 20px;
  padding: 1rem 2rem;
  transition: all ease 0.3s;
  text-transform: uppercase;
}
.ms-subscribre-btn:after {
  font-family: Material-Design-Iconic-Font;
  content: "\f15a";
  margin-left: 10px;
  transition: all ease 0.3s;
}
.ms-subscribre-btn:hover,
.ms-subscribre-btn:focus {
  background-color: rgba(0, 0, 0, 0.3);
}
.ms-subscribre-btn:hover:after,
.ms-subscribre-btn:focus:after {
  content: "\f159";
  display: inline-block;
  margin-left: 20px;
}
.ms-subscribre-btn:active {
  box-shadow: inset 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    inset 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    inset 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: rgba(0, 0, 0, 0.4);
}
.ms-footer-media .media {
  margin-bottom: 45px;
  margin-bottom: 4.5rem;
}
.ms-footer-media .media:last-child {
  margin-bottom: 0;
}
.ms-footer-media .media img {
  border-radius: 50%;
  margin-right: 10px;
  margin-right: 1rem;
  border: solid 1px #607d8b;
}
.ms-footer-media .media .media-body .media-heading {
  font-size: 15px;
  font-size: 1.5rem;
  line-height: 25px;
  line-height: 2.5rem;
}
.ms-footer-media .media-footer {
  font-size: 12px;
  font-size: 1.2rem;
  color: #bdbdbd;
}
.ms-footer-media .media-footer span {
  margin-right: 10px;
  margin-right: 1rem;
}
.ms-footer-media .media-footer span i {
  margin-left: 1px;
  margin-left: 0.1rem;
  margin-right: 5px;
  margin-right: 0.5rem;
}
.ms-footbar-social a {
  margin: 0 0 10px 10px;
  margin: 0 0 1rem 1rem;
  background-color: #455a64;
  color: #f5f5f5 !important;
}
.ms-footbar-social a:hover i {
  transition: all ease 0.4s;
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.color-info-light {
  color: #3cccde;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.color-warning-light {
  color: #ffb54a;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.color-danger-light {
  color: #f48a88;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.color-royal-light {
  color: #cd92d7;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.color-success-light {
  color: #78c37b;
  text-shadow: 2px 2px 1px rgba(0, 0, 0, 0.2);
}
.ms-footer-text-right {
  text-align: right;
}
@media (max-width: 991px) {
  .ms-footer-alt-color {
    background-color: transparent;
  }
}
@media (max-width: 767px) {
  .ms-footer-alt-color {
    margin: 5rem 0;
  }
  .ms-footer-text-right {
    text-align: center;
  }
  .ms-footer-text-right .ms-footbar-social a {
    margin-left: 0;
    margin-right: 1rem;
  }
}
.ms-slidebar {
  background-color: #fff;
  width: 300px;
  overflow: hidden;
}
.ms-slidebar .sb-slidebar-container {
  height: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}
.ms-slidebar .sb-slidebar-container::-webkit-scrollbar-track {
  -webkit-box-shadow: none;
  background-color: transparent;
}
.ms-slidebar .sb-slidebar-container::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background-color: transparent;
  position: absolute !important;
}
.ms-slidebar .sb-slidebar-container::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
  border-radius: 100px;
}
.ms-slidebar .ms-slidebar-block {
  padding: 0 20px;
}
.ms-slidebar .ms-slidebar-block .ms-slidebar-block-title {
  font-weight: 400;
  margin-bottom: 20px;
}
.ms-slidebar .ms-slidebar-block .ms-slidebar-social {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 20px;
}
.ms-slidebar .ms-slidebar-header {
  min-height: 150px;
  background-image: url("assets/images/bg-slidebar.png");
  position: relative;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-login {
  display: block;
  display: table;
  width: 100%;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-login a {
  color: #fff;
  text-align: center;
  display: table-cell;
  width: 50%;
  padding: 10px 20px;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-login a i {
  margin-right: 10px;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.5);
  display: block;
  bottom: 0;
  width: 100%;
  vertical-align: middle;
  height: 50px;
  overflow: hidden;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .ms-slidebar-t {
  float: left;
  margin-left: 20px;
  line-height: 50px;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title h3 {
  color: #fff;
  font-size: 20px;
  margin: 0;
  word-spacing: -4px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title h3 span {
  font-weight: 400;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .search-form {
  vertical-align: top;
  display: inline-block;
  position: relative;
  height: 50px;
  min-width: 50px;
  padding: 0;
  margin: 0;
  transition: width ease 1s;
  z-index: 0;
  float: right;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  #search-submit {
  position: relative;
  left: -5000px;
  width: 0;
  padding: 0;
  border: 0;
  height: 0;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .search-form label {
  width: 50px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  font-size: 20px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 3;
  transition: -webkit-transform ease 0.5s;
  transition: transform ease 0.5s;
  transition: transform ease 0.5s, -webkit-transform ease 0.5s;
  transition-delay: 0.2s;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  label:hover:before {
  height: 50px;
  width: 50px;
  top: 0;
  left: 0;
}
.ms-slidebar .ms-slidebar-header .ms-slidebar-title .search-form .search-input {
  height: 50px;
  border: 0;
  width: 0;
  padding: 0;
  background-color: transparent;
  display: inline-block;
  transition: width ease 0.5s;
  vertical-align: top;
  padding-left: 50px;
  border-radius: #616161;
  color: #fff;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:-moz-placeholder {
  color: rgba(0, 0, 0, 0.3);
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:-webkit-autofill {
  -webkit-text-fill-color: #fff !important;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:focus {
  width: 300px;
  border-bottom: solid 3px #8bc34a;
  border-radius: 0;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:focus
  + label {
  -webkit-transform: ScaleX(-1);
  transform: ScaleX(-1);
  color: #8bc34a;
}
.ms-slidebar
  .ms-slidebar-header
  .ms-slidebar-title
  .search-form
  .search-input:focus
  + label:before {
  transition: none;
  display: none;
}
.ms-slidebar .ms-slidebar-menu {
  list-style-type: none;
  padding: 0;
}
.ms-slidebar .ms-slidebar-menu > li {
  margin: 0;
  box-shadow: none;
}
.ms-slidebar .ms-slidebar-menu > li > a {
  display: block;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #8bc34a;
  background-color: #eee;
}
.ms-slidebar .ms-slidebar-menu > li > a:after {
  font-family: Material-Design-Iconic-Font;
  font-size: 1.2em;
  float: right;
  content: "\f273";
  transition: all ease 0.3s;
}
.ms-slidebar .ms-slidebar-menu > li > a.collapsed {
  background-color: #fff;
  color: #616161;
}
.ms-slidebar .ms-slidebar-menu > li > a.collapsed:after {
  content: "\f278";
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}
.ms-slidebar .ms-slidebar-menu > li > a.link {
  background-color: #fff;
  color: #616161;
}
.ms-slidebar .ms-slidebar-menu > li > a.link:after {
  content: "\f2fb";
}
.ms-slidebar .ms-slidebar-menu > li > a:hover {
  background-color: #f5f5f5;
  color: #8bc34a;
}
.ms-slidebar .ms-slidebar-menu > li > a > i {
  margin-right: 15px;
}
.ms-slidebar .ms-slidebar-menu > li > ul {
  list-style-type: none;
  padding: 0;
}
.ms-slidebar .ms-slidebar-menu > li > ul li > a {
  display: block;
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 400;
  color: #616161;
  background-color: #fafafa;
}
.ms-slidebar .ms-slidebar-menu > li > ul li > a:hover {
  background-color: #f5f5f5;
  color: #8bc34a;
}
.ms-slidebar .ms-slidebar-menu > li > ul li > a > i {
  margin-right: 15px;
}
.ms-slidebar .ms-slidebar-menu > li > ul li:last-child {
  border-bottom: solid 1px #eee;
}
.ms-slidebar.sb-left {
  box-shadow: 2px 0 3px 0 rgba(0, 0, 0, 0.21);
}
.ms-slidebar.sb-right {
  box-shadow: -2px 0 3px 0 rgba(0, 0, 0, 0.21);
}
.bg-full-page {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
}
.mw-500 {
  max-width: 500px !important;
}
.absolute-center {
  position: absolute;
  width: 100%;
  top: 20%;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 2;
}
@media (max-width: 767px) {
  .absolute-center {
    top: 0;
    min-height: 100%;
  }
}
.coming-date {
  list-style-type: none;
  padding: 0;
  display: block;
  overflow: hidden;
  margin: 30px auto;
  margin: 3rem auto;
  text-align: center;
}
.coming-date li {
  display: inline-block;
  text-align: center;
  font-size: 36px;
  font-weight: 300;
  margin: 5px 10px 0;
}
.coming-date li span {
  display: block;
  font-size: 18px;
  margin-top: 15px;
}
.coming-date li.colon {
  vertical-align: top;
}
@media (max-width: 500px) {
  .coming-date li {
    margin: 5px 5px 0;
  }
}
#Container .mix {
  display: none;
}
.width-auto {
  display: inline-block;
}
.intro-fixed {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
}
.intro-fixed .intro-fixed-content {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.intro-fixed.intro-fixed-static {
  position: static;
}
.intro-fixed.intro-fixed-static .intro-fixed-content {
  position: static;
  -webkit-transform: none;
  transform: none;
}
.intro-fixed.intro-fixed-static .intro-fixed-content h1 {
  margin-top: 0;
}
.intro-full {
  position: relative;
  overflow: hidden;
}
.intro-full .intro-full-content {
  padding-top: 60px;
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.intro-full .intro-full-content.intro-full-content-static {
  position: static;
  -webkit-transform: none;
  transform: none;
}
.intro-full .intro-full-content.intro-full-content-static h1 {
  margin-top: 0;
}
@media (max-width: 767px) {
  .intro-fixed .intro-fixed-content {
    padding-top: 60px;
  }
}
.ms-lead-navbar-bottom {
  height: 80px;
  position: relative;
}
.ms-lead-navbar-bottom .container {
  position: relative;
}
.ms-lead-navbar-bottom .navbar-nav {
  display: block !important;
  float: none !important;
  text-align: center;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
}
.ms-lead-navbar-bottom .navbar-nav li {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  text-align: center;
  float: none;
}
.ms-lead-navbar-bottom .navbar-nav li a {
  padding: 30px 15px 31px !important;
  display: inline-block;
  color: rgba(255, 255, 255, 0.6);
  transition: all ease 0.3s;
  position: relative;
}
.ms-lead-navbar-bottom .navbar-nav li a:hover {
  border-color: #8bc34a;
  color: #fff;
}
.ms-lead-navbar-bottom .navbar-nav li.active a {
  background-color: transparent;
  color: #fff;
}
.ms-lead-navbar-bottom .navbar-nav li.active a:after {
  height: 3px;
}
.ms-lead-navbar-bottom.navbar-scroll {
  position: fixed;
  top: 0;
  background-color: #8bc34a;
  width: 100%;
  left: 0;
  right: 0;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  z-index: 10;
  height: 50px;
}
.ms-lead-navbar-bottom.navbar-scroll .navbar-nav li a {
  padding: 15px 15px 16px !important;
}
.ms-lead-navbar {
  position: fixed !important;
  z-index: 30;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.1) !important;
  box-shadow: none;
  color: #fff;
  height: 50px;
  transition: all ease 0.3s;
}
.ms-lead-navbar .navbar-brand {
  font-weight: 300;
  word-spacing: -4px;
  font-size: 20px;
  padding-top: 9px;
  margin: 0 20px;
  color: #f5f5f5;
  display: none;
  height: 50px;
  white-space: nowrap;
}
.ms-lead-navbar .navbar-brand img {
  display: inline-block;
  margin-right: 10px;
}
.ms-lead-navbar .navbar-brand .ms-title strong {
  display: inline-block;
  font-weight: 400;
}
.ms-lead-navbar .navbar-brand .ms-logo {
  margin-top: -5px;
  margin-right: 10px;
}
.ms-lead-navbar.navbar-scroll {
  background-color: #8bc34a !important;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
.ms-lead-navbar a {
  color: #fff;
}
.ms-lead-navbar .container {
  position: relative;
}
.ms-lead-navbar .btn-navbar-menu {
  height: 50px;
  width: 50px;
  float: right;
  position: absolute;
  right: 0;
  top: 0;
  line-height: 50px;
  text-align: center;
  font-size: 21px;
  font-size: 2.1rem;
}
.ms-lead-navbar .navbar-nav {
  margin: 0;
  display: table;
  float: right;
  margin-right: 21px;
}
.ms-lead-navbar .navbar-nav li {
  display: inline-block;
  list-style-type: none;
  margin: 0;
}
.ms-lead-navbar .navbar-nav li a {
  padding: 15px 15px 16px !important;
  display: inline-block;
  color: #eee;
  transition: all ease 0.3s;
  position: relative;
}
.ms-lead-navbar .navbar-nav li a:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 0;
  bottom: 1px;
  background-color: #fff;
  transition: all ease 0.3s;
}
.ms-lead-navbar .navbar-nav li a:hover {
  border-color: #8bc34a;
  color: #fff;
}
.ms-lead-navbar .navbar-nav li a:hover:after {
  height: 3px;
}
.ms-lead-navbar .navbar-nav li.active a {
  background-color: transparent;
  color: #fff;
}
.ms-lead-navbar .navbar-nav li.active a:after {
  height: 3px;
}
@media (max-width: 991px) {
  .ms-lead-navbar .navbar-nav li a {
    padding: 15px 12px 16px !important;
  }
}
@media (max-width: 1199px) {
  .ms-navbar .navbar-brand {
    padding-right: 0;
  }
  .ms-navbar .navbar-nav li a {
    padding: 15px 18px;
  }
}
@media (max-width: 991px) {
  .ms-header .header-right .search-container {
    display: none;
  }
  .ms-navbar .navbar-brand {
    padding-right: 0;
  }
  .ms-navbar .navbar-nav li {
    font-size: 1.4rem;
  }
  .ms-navbar .navbar-nav li a {
    padding: 15px;
  }
  .ms-paper {
    -webkit-animation-name: shadow-retard, slideInUpTiny;
    animation-name: shadow-retard, slideInUpTiny;
    -webkit-animation-duration: 5s, 1.4s;
    animation-duration: 5s, 1.4s;
  }
  .ms-paper > .row {
    overflow: hidden;
  }
  .ms-paper > .row > [class*="col-"] {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
  }
  .ms-paper > .row .ms-paper-menu-left-container {
    padding-right: 15px;
    -webkit-animation-name: slideInUp;
    animation-name: slideInUp;
  }
  .ms-paper > .row .ms-paper-menu-left-container .ms-paper-menu-left {
    margin-bottom: 0;
    padding-bottom: 0;
  }
  .ms-paper
    > .row
    .ms-paper-menu-left-container
    .ms-paper-menu-left
    .ms-paper-menu-title
    a {
    display: block;
  }
  .ms-paper .ms-paper-content-container {
    padding-left: 15px !important;
  }
  .ms-paper .ms-paper-content-container .ms-paper-content {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}
@media (max-width: 767px) {
  .typed-block {
    display: block;
  }
  .typed-block:after {
    content: ".";
  }
  .typed-block + .typed-cursor {
    display: none;
  }
  .container-full {
    padding: 0;
  }
  .center-block-xs {
    display: table;
    float: none !important;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 1rem;
  }
  .btn-navbar-menu {
    display: block;
    right: 0 !important;
  }
  .ms-paper {
    margin-top: -40px;
  }
  .ms-paper > .row {
    margin: 0;
  }
  .ms-paper > .row > [class*="col-"] {
    margin-left: 0 !important;
    padding-right: 0 !important;
  }
  .ms-paper > .row .ms-paper-menu-left-container,
  .ms-paper > .row .ms-paper-content-container {
    padding-left: 0 !important;
  }
  .ms-paper
    > .row
    .ms-paper-menu-left-container
    .ms-paper-menu-left
    .ms-paper-menu-title {
    background-color: #71a436;
  }
  .ms-header {
    display: none;
  }
  .ms-navbar .container .navbar-header {
    display: inline-block;
  }
  .ms-navbar .container .navbar-header .navbar-brand {
    display: inline-block;
    margin-left: 20px;
    padding-top: 10px;
  }
  .ms-navbar .container .navbar-collapse {
    display: none !important;
  }
  .ms-navbar .container .btn-navbar-menu {
    display: inline-block;
  }
}
@media (max-width: 550px) {
  .ms-paper-menu-title {
    text-align: left !important;
    padding-left: 3rem !important;
  }
}
@media (min-width: 768px) {
  .ms-paper-menu-title a i {
    right: 3rem !important;
  }
  .navbar-brand .ms-title {
    display: none;
  }
  .ms-margin a {
    margin-right: 1rem;
    margin-left: 1rem;
  }
}
@media (min-width: 992px) {
  .panel-menu {
    display: block;
  }
  .navbar-brand .ms-title {
    display: inline;
  }
  .no-mt-md {
    margin-top: 0;
  }
}
