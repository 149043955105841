@import "../../shared/styles/index";

.formicopediaLogo {
  display: none;
  @include mobile {
    display: block;
    height: 40px;
    position: relative;
    left: 30%;
  }
}
